import React from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Typography from '@material-ui/core/Typography'
import {Box} from "@material-ui/core";


export default function ActiveRequests(props) {
  return (
    <Box>
    <Grid container spacing={3}>
      <Grid item xs={9}>
       <Typography variant="body1" color="textSecondary">
       ₹ {props.money}
       </Typography>
       <Typography variant="caption" color="textSecondary">
       Id {props.item?.trascationId}
       </Typography>
      </Grid>
      <Grid item xs={2}>
          <Typography variant="subtitle2" color="primary">Requested</Typography>
      </Grid>
    </Grid>
    <Typography variant="caption" color="textSecondary5">{`Requested ${
          moment(props?.date?.toMillis()).fromNow() || ""
        }`}</Typography>
    </Box>
  );
}
