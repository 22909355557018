import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { FieldValue, firestore } from "../../base/firebaseConfig";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';


export default function FollowButton(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [isFollowing, setisFollowing] = useState(false);
  const [following, setfollowing] = useState("Loading");

  useEffect(() => {
    if (true) {
      const followRef = firestore
        .collection("followers")
        .doc(props.userDoc?.uid + "_" + props.channelId);

      followRef
        .get()
        .then((videoData) => {
          if (videoData.exists) {
            setisFollowing(true);
            setfollowing("Following");
          } else {
            setisFollowing(false);
            setfollowing("Follow");
          }
        })
        .catch(() => {
          setisFollowing(false);
          setfollowing("Error");
        });
    }
  }, []);

  const followRef = firestore
    .collection("followers")
    .doc(props.userDoc?.uid + "_" + props.channelId);

  const follow = async () => {
    let date = new Date();
    let followData = {
      channel: props.channelId,
      follower: props.userDoc && props.userDoc.uid,
      date: FieldValue.serverTimestamp(),
    };
    followRef.set(followData).then(function () {
      setisFollowing(true);
      setfollowing("Following");
      alert("Followed")
    });
  };

  const unfollow = async () => {
    handleClose();
    followRef.delete().then(function () {
      setisFollowing(false);
      setfollowing("Follow");
      alert("Unfollwed");
    });
  };

  const handleRedirect = ()=>{
    localStorage.setItem("redirect",props.redirect||"/")
  }
  return (
    <>
      {props.userDoc !== "Anonymous" ? (
        <div>
          {props.userDoc && props.userDoc.uid == props.channelId ? (
            <Link to={`/channel/edit`}>
              <Button
                variant={"contained"}
                color="primary"
                disabled={props.source == "videos" ? true : false}
                color="primary"
                disableElevation
              >
                {props.source == "videos" ? "Follow" : "Edit Channel"}
              </Button>
            </Link>
          ) : isFollowing ? (
            <Button
              variant="contained"
              onClick={handleClickOpen}
              disableElevation
            >
              {following}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={follow}
              color="primary"
              disableElevation
            >
              {following}
            </Button>
          )}
        </div>
      ) : (
        <div>
          <Link to={`/login`} onClick={()=>handleRedirect()}>
            <Button variant="contained" color="primary" disableElevation>
              Signin to Follow
            </Button>
          </Link>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Unfollow?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure to unfollow ${props.channelName}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={unfollow} color="primary">
            Unfollow
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
