import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { firestore } from "../../base/firebaseConfig";
import Moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import NewlineText from "../utils/NewlineText";
import ClampLines from "react-clamp-lines";
import Chip from "@material-ui/core/Chip";
import LazyLoad from "react-lazy-load";
import Tooltip from "@material-ui/core/Tooltip";
import { VerifiedUser } from "@material-ui/icons";

var shortNumber = require("short-number");
var format = require("format-duration");
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    marginTop: 4,
    // textAlign: "center",
    color: theme.palette.text.secondary,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
  },
}));
export default function VideoCardWide(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={5}>
        <Link to={`/watch/${props.item?.id}`}>
          <LazyLoad height={matches ? 110 : 200} offsetVertical={300}>
            <div
              style={{
                width: "100%",
                height: matches ? 110 : 200,
                backgroundColor: "gray",
                backgroundImage: "url(" + props.item?.thumbnail + ")",
              }}
              className={classes.bg}
            ></div>
          </LazyLoad>
        </Link>
      </Grid>
      <Grid item xs={12} sm={7} style={{ paddingTop: 0, width: "10%" }}>
        <Link to={`/watch/${props.item?.id}`}>
          <Typography variant="body2" color="textPrimary">
            <ClampLines
              id="really-unique-id"
              lines={2}
              ellipsis="..."
              moreText="Expand"
              lessText="Collapse"
              className="body2"
              innerElement="p"
              buttons={false}
              text={props.item?.title}
            />
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {`${format(props.item?.duration * 1000 || 0)}`}
          </Typography>
          <Typography display="block" variant="caption" color="textSecondary">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box>{props.item?.channelName}</Box>
              <Box>
                {props.item?.isVerified && (
                  <span>
                    &nbsp;
                    <Tooltip title="Verified Channel">
                      <VerifiedUser style={{ width: "12px" }} />
                    </Tooltip>
                  </span>
                )}
              </Box>
            </Box>
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {`${shortNumber(props.item?.views)} • ${Moment(
              props.item?.date.toMillis()
            ).fromNow()}`}
          </Typography>
        </Link>
      </Grid>
    </>
  );
}
