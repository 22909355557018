import React from "react";

export default function PlayStore() {
  return (
   
      <svg
        width="96"
        height="32"
        viewBox="0 0 108 32"
        aria-labelledby="googlePlaySvg"
        role="img"
      >
        <title id="googlePlaySvg">Get it on Google play</title>
        <g fill="none" fill-rule="evenodd">
          <g fill-rule="nonzero">
            <rect fill="#FFF" width="108" height="32" rx="5"></rect>
            <path
              d="M104 .64A3.36 3.36 0 01107.36 4v24a3.36 3.36 0 01-3.36 3.36H4A3.36 3.36 0 01.64 28V4A3.36 3.36 0 014 .64h100zm0-.64H4a4 4 0 00-4 4v24a4 4 0 004 4h100a4 4 0 004-4V4a4 4 0 00-4-4z"
              fill="#2A2A33"
            ></path>
            <path
              d="M54.512 17.4a3.4 3.4 0 103.416 3.4 3.352 3.352 0 00-3.416-3.4zm0 5.464a2.064 2.064 0 111.92-2.064 1.968 1.968 0 01-1.92 2.064zM47.064 17.4a3.4 3.4 0 103.408 3.4 3.352 3.352 0 00-3.416-3.4h.008zm0 5.464a2.064 2.064 0 111.912-2.064 1.968 1.968 0 01-1.92 2.064h.008zm-8.872-4.416v1.44h3.456a3.016 3.016 0 01-.8 1.816 3.536 3.536 0 01-2.664 1.056 3.84 3.84 0 010-7.68 3.68 3.68 0 012.616 1.032l1.016-1.016a5.032 5.032 0 00-3.624-1.496 5.288 5.288 0 100 10.568 4.8 4.8 0 003.688-1.48 4.8 4.8 0 001.248-3.376 4.696 4.696 0 00-.08-.904l-4.856.04zm36.248 1.12a3.2 3.2 0 00-2.912-2.168 3.2 3.2 0 00-3.2 3.4 3.328 3.328 0 003.376 3.4 3.384 3.384 0 002.832-1.504l-1.16-.8a1.944 1.944 0 01-1.672.944 1.728 1.728 0 01-1.648-1.032l4.552-1.88-.168-.36zm-4.64 1.136a1.864 1.864 0 011.776-1.984 1.32 1.32 0 011.264.72l-3.04 1.264zM66.104 24H67.6V14h-1.496v10zm-2.448-5.84H63.6a2.4 2.4 0 00-1.792-.8 3.408 3.408 0 000 6.808 2.32 2.32 0 001.792-.8h.048v.488c0 1.304-.696 2-1.816 2a1.88 1.88 0 01-1.712-1.208l-1.304.544a3.24 3.24 0 003.016 2.008c1.752 0 3.2-1.032 3.2-3.544V17.6h-1.376v.56zm-1.712 4.704a2.072 2.072 0 010-4.128A1.92 1.92 0 0163.76 20.8a1.904 1.904 0 01-1.824 2.064h.008zM81.448 14h-3.576v10h1.496v-3.792h2.088a3.112 3.112 0 100-6.208h-.008zm0 4.8H79.36v-3.408h2.12a1.716 1.716 0 010 3.432l-.032-.024zm9.224-1.44a2.8 2.8 0 00-2.664 1.528l1.328.552c.268-.493.8-.782 1.36-.736a1.44 1.44 0 011.6 1.288v.104a3.304 3.304 0 00-1.56-.384c-1.432 0-2.88.8-2.88 2.248a2.312 2.312 0 002.48 2.2 2.104 2.104 0 001.92-.96h.048v.8h1.44v-3.848c0-1.752-1.328-2.768-3.032-2.768l-.04-.024zm-.184 5.48c-.488 0-1.168-.248-1.168-.848 0-.8.848-1.064 1.6-1.064.475-.012.945.104 1.36.336a1.808 1.808 0 01-1.752 1.6l-.04-.024zm8.504-5.24l-1.712 4.336h-.048L95.456 17.6h-1.6l2.664 6.064L95 27.032h1.56l4.096-9.432h-1.664zM85.544 24h1.496V14h-1.496v10z"
              fill="#2A2A33"
            ></path>
            <path
              d="M37.936 8.192a2.168 2.168 0 01-.6 1.6 2.328 2.328 0 01-1.76.712 2.52 2.52 0 01-1.768-4.296 2.4 2.4 0 011.768-.72 2.48 2.48 0 01.984.2c.29.117.547.3.752.536l-.424.424a1.6 1.6 0 00-1.312-.568A1.856 1.856 0 0033.712 8a1.888 1.888 0 003.2 1.384c.246-.267.388-.613.4-.976h-1.736v-.576h2.328c.021.119.032.24.032.36h0zm3.664-2h-2.16v1.52h1.968v.576H39.44v1.52h2.16v.592h-2.8V5.6h2.8v.592zm2.624 4.208h-.616V6.192h-1.344V5.6H45.6v.592h-1.376V10.4zm3.728 0V5.6h.616v4.8h-.616zm3.352 0h-.616V6.192h-1.344V5.6h3.296v.592h-1.336V10.4zm7.584-.624a2.496 2.496 0 01-3.52 0 2.592 2.592 0 010-3.56 2.48 2.48 0 013.52 0 2.584 2.584 0 010 3.56h0zm-3.064-.4a1.848 1.848 0 002.608 0 2.048 2.048 0 000-2.752 1.848 1.848 0 00-2.608 0 2.048 2.048 0 000 2.752h0zm4.64 1.024V5.6h.752l2.336 3.736V5.6h.616v4.8h-.64l-2.44-3.912V10.4h-.624z"
              stroke="#FFF"
              stroke-width="0.2"
              fill="#2A2A33"
            ></path>
            <path
              d="M8.352 6.032a1.6 1.6 0 00-.368 1.12v17.696a1.6 1.6 0 00.368 1.12l.056.056 9.912-9.904v-.232L8.408 5.976l-.056.056zM21.6 19.424l-3.28-3.304v-.232l3.28-3.312.072.04 3.928 2.232c1.12.632 1.12 1.672 0 2.312l-3.912 2.224-.088.04z"
              fill="#2A2A33"
            ></path>
            <path
              d="M21.696 19.376L18.32 16l-9.968 9.968c.471.414 1.17.434 1.664.048l11.688-6.64M21.696 12.624l-11.688-6.64a1.304 1.304 0 00-1.664.048L18.32 16l3.376-3.376z"
              fill="#2A2A33"
            ></path>
            <path
              d="M21.6 19.304l-11.592 6.6a1.336 1.336 0 01-1.6 0l-.056.056.056.056a1.328 1.328 0 001.6 0l11.688-6.64-.096-.072z"
              fill="#000"
              opacity="0.2"
            ></path>
            <path
              d="M8.352 25.856a1.6 1.6 0 01-.368-1.12v.12a1.6 1.6 0 00.368 1.12l.056-.056-.056-.064zM25.6 17.04l-4 2.264.072.072 3.928-2.224A1.4 1.4 0 0026.4 16a1.488 1.488 0 01-.8 1.04z"
              fill="#000"
              opacity="0.12"
            ></path>
            <path
              d="M10.008 6.096L25.6 14.96c.413.204.709.588.8 1.04a1.4 1.4 0 00-.8-1.152L10.008 5.984c-1.12-.632-2.032-.104-2.032 1.176v.12c.024-1.288.92-1.816 2.032-1.184z"
              fill="#FFF"
              opacity="0.25"
            ></path>
          </g>
          <path
            stroke="#FFF"
            stroke-width="0.5"
            stroke-linecap="square"
            d="M8.4 6l14.4 14.4M8.4 26L22 12.4"
          ></path>
        </g>
      </svg>
   
  );
}
