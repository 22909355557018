import React, { useState, useEffect } from "react";
import { auth, FieldValue, firestore } from "../../base/firebaseConfig";
import { DataGrid, isEnterKey } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import moment from "moment";
import { Chip, IconButton, Typography } from "@material-ui/core";
import { ArrowForward, Edit, PhoneAndroid } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Avatar, Box, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ToastContainer, toast } from "material-react-toastify";
import ActiveRequests from "./ActiveRequests";
import Divider from "@material-ui/core/Divider";
import WalletHistory from "./WalletHistory";

const useStyles = makeStyles((theme) => ({
  card: {
    flexGrow: 1,
    // backgroundColor: "#E8E8E8",
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function MyWallet(props) {
  const [exist, setExist] = useState(false);
  const classes = useStyles();
  const [isRequested, setisRequested] = useState(false);
  let [startHistory, setstartHistory] = useState(0);
  let [startRequest, setstartRequest] = useState(0);

  const [myWallet, getMyWalletData] = useState([]);
  const [withDrawHistory, setWithDrawHistory] = useState([]);
  const [activeRequests, setActiveRequests] = useState([]);

  const [start, setstart] = useState(0);
  const [ended, setended] = useState(false);
  let docRef = firestore.collection("wallets").doc(props.channelDoc?.id);
  const getMyWallet = async () => {
    await docRef
      .get()
      .then((myWallet) => {
        if (myWallet.exists) {
          getMyWalletData(myWallet.data());
          getActiveRequests();
          setExist(true);
        } else {
          setExist(false);
        }
      })
      .catch(() => {
        setExist(false);
      });
  };
  useEffect(() => {
    props.channelDoc && getMyWallet();
  }, [props.channelDoc]);

  useEffect(() => {
    props.channelDoc && getWithDrawHistory();;
  }, [props.channelDoc]);
  let withdrawRef = firestore.collection("wallet-requests");

  
  const getActiveRequests = async () => {

    await withdrawRef
      .where("user", "==", props.channelDoc?.id)
      .where("isRequestActive", "==", true)
      .orderBy("date", "desc")
      .limit(2)
      .onSnapshot((querySnapshot) => {
        let mylatest = [];
        let startRequest = querySnapshot.docs[querySnapshot.docs.length - 1];
        setstartRequest(startRequest);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          mylatest.push(doc.data());
        });
        setActiveRequests(mylatest);
        setisRequested(mylatest.length > 0);
        // setloading(false)
      })
  };
  let withDrawHistoryRef = firestore.collection("wallet-history");
  const getWithDrawHistory = async () => {

    await withDrawHistoryRef
      .where("user", "==", props.channelDoc?.id)
      .where("deposited", "==", true)
      .orderBy("date", "desc")
      .limit(2)
      .get()
      .then((querySnapshot) => {
        let mylatest = [];
        let startHistory = querySnapshot.docs[querySnapshot.docs.length - 1];
        setstartHistory(startHistory);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          mylatest.push(doc.data());
        });
        setWithDrawHistory(mylatest);
        // setloading(false)
      })
      .catch((error) => {
        //  setloading(false)
        console.log("Error getting documents: ", error);
      });
  };
  const getMoreWithdraw = async (start, list) => {

    (await start) &&
      withDrawHistoryRef
        .where("user", "==", props.channelDoc?.id)
        .where("deposited", " ==", true)
        .orderBy("date", "desc")
        .limit(5)
        .startAfter(start)
        .get()
        .then((querySnapshot) => {
          let mylatest = [...list];
          let nextStart = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstartHistory(nextStart);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            mylatest.push(doc.data());
          });
          setWithDrawHistory(mylatest);
        })
        .catch((error) => {
          // console.log("Error getting documents: ", error);
        });
    // console.log(mylatest);
    if (!start) {
      setended(true);
    }
  };

  const requestWithdraw = async () => {
    let doc =  firestore.collection("wallet-requests").doc();

    if (!isRequested) {
      let confirm = window.confirm(
        `Are you sure to request ${myWallet.money} from your wallet? `
      );
      if (confirm) {
        const postData = {
          date: FieldValue.serverTimestamp(),
          email: props.channelDoc.email || auth.currentUser.email,
          isRequestActive: true,
          money: myWallet.money,
          name: props.userDoc?.realName || auth.currentUser.displayName,
          paid: false,
          // phoneNumber: userData.phone,
          phoneNumber: props.userDoc?.phone ||'No Phone',
          profilePic: props.channelDoc?.profilePic || "",
          user: props.channelDoc.id || auth.currentUser.uid,
          trascationId: doc.id,
        };

      withdrawRef 
         .doc(doc.id)
          .set(postData)
          .then(() => {
            setisRequested(true);
            success();
          })
          .catch((error) => {
            alert('Error')
            console.error("Error adding document: ", error);
          });
      }
    } else {
      alert("You have already requested a withdraw");
    }

    // console.log(postData);
  };
  const success = () => toast.success("Successfully requested money.");
  const error = () => toast.error("Error occured, Please try again");

  return (
    <div style={{ minHeight: 600, width: "100%" }}>
      <Box pt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            {!myWallet?.isBlocked ? (
              <>
                <Typography variant="h6" color="initial" gutterBottom>
                  My Wallet
                </Typography>
                <Card
                  className={classes.card}
                  variant="outlined"
                  borderColor="primary.main"
                  raised={true}
                >
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Earnings
                    </Typography>
                    <Typography variant="h4" component="h2">
                      {`₹ ${myWallet?.money || 0}`}
                    </Typography>
                    <Box display="flex" alignItems="center" mt={2}>
                      <Box width="100%" mr={1}>
                        <LinearProgress
                          variant="determinate"
                          value={(myWallet?.money / 100) * 100 || 0}
                        />
                      </Box>
                      <Box minWidth={35}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          gutterBottom
                        >{`${Math.round(
                          (myWallet?.money / 100) * 100 || 0
                        )}%`}</Typography>
                      </Box>
                    </Box>
                    <Typography variant="caption" color="initial" gutterBottom>
                      You can withdraw if the total is at least ₹100.00
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="outlined"
                      color=""
                      disabled={myWallet?.money <= 100 || isRequested}
                      onClick={() => requestWithdraw()}
                    >
                      Withdraw
                    </Button>
                    <br/> <br/>
                   {isRequested && <Typography variant="caption" color="initial" gutterBottom>
                    Cant withdraw while request is active
                    </Typography>}
                  </CardActions>
                </Card>
                <Box mt={3}>
                  <Typography variant="h6" color="initial" gutterBottom>
                    Withdraw History
                  </Typography>

                  {withDrawHistory?.map((item, index) => (
                    <WalletHistory key={item.trascationId} money={item.money} date={item.date} item={item} />
                  ))}
                  {withDrawHistory.length == 0 && (
                    <Typography variant="caption" color="initial" gutterBottom>
                      History is empty
                    </Typography>
                  )}
                  <div>
                    <Box
                      display="flex"
                      justifyContent="center"
                      style={{ width: "100%" }}
                      m={1}
                      p={1}
                      bgcolor="background.paper"
                    >
                      {withDrawHistory && withDrawHistory.length > 0 && (
                        <Button
                          onClick={() =>
                            getMoreWithdraw(startHistory, withDrawHistory)
                          }
                          variant="outlined"
                          color="default"
                        >
                          Load more
                        </Button>
                      )}
                      <br />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      style={{ width: "100%" }}
                      m={1}
                      p={1}
                      bgcolor="background.paper"
                    >
                      {!startHistory && (
                        <Typography variant="subtitle2" color="initial">
                          You reached at the end of the list
                        </Typography>
                      )}
                    </Box>
                  </div>
                </Box>
              </>
            ) : (
              <Box>
                <Typography variant="h6" color="initial">
                  🚫 Wallet Blocked
                </Typography>
                <Typography variant="subtitle2" color="initial">
                  Wallet is blocked. Please try again
                  <a href="#" target="_blank">
                    {" "}
                    Learn more
                  </a>
                </Typography>
              </Box>
            )}
            {!myWallet &&( <Box>
                <Typography variant="h6" color="initial">
                  Wallet empty
                </Typography>
                <Typography variant="subtitle2" color="initial">
                 Upload video or posts or 30s to get started
                  <a href="#" target="_blank">
                    {" "}
                    Learn more
                  </a>
                </Typography>
              </Box>)}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box mb={3} mt={3}>
              <Typography variant="h6" color="initial" gutterBottom>
                Active Requests
              </Typography>

              {activeRequests && activeRequests?.map((item, index) => (
                <ActiveRequests key={item.trascationId} money={item.money} date={item.date} item={item}/>
              ))}
              {activeRequests.length == 0 && (
                <Typography variant="caption" color="initial" gutterBottom>
                  No Active Requests
                </Typography>
              )}
            </Box>
            <Divider />
            <Box>
              <Typography variant="caption" color="initial">
                Keep your mobile number up to date to ensure smooth
                communications realted to transactions.
              </Typography>
              <br />
              <Box mt={2}>
                <Link to={"/my-profile"}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    endIcon={<ArrowForward />}
                  >
                    Edit Phone
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <ToastContainer />
      {/* {JSON.stringify(myWallet)} */}
    </div>
  );
}
