import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { firestore } from "../base/firebaseConfig";
import Paper from "@material-ui/core/Paper";
import { Avatar, Box, Button, Tooltip } from "@material-ui/core";
import FollowButton from "../components/channel/FollowButton";
import ChannelVideos from "../components/channel/ChannelVideos";
import TopChannelList from "../components/home/TopChannelList";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import ChannelPosts from "../components/posts/ChannelPosts";
import { useHistory } from "react-router-dom";
import ChannelPopularVideos from "../components/channel/ChannelPopularVideos";
import { Helmet } from "react-helmet";
import AboutChannel from "../components/channel/AboutChannel";
import Footer from "../components/shared/Footer";
import { VerifiedUser } from "@material-ui/icons";
let a;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  tab: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    minHeight: "200px",
    color: theme.palette.text.secondary,
    backgroundColor:"#607D8B",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  large: {
    width: 80,
    height: 80,
  },
  overlay: {
    color: theme.palette.text.secondary,
    minHeight: "200px",
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.13) 22.25%, rgba(0, 0, 0, 0.5) 55.5%)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function ChannelHome(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let user = useContext(UserContext);
  let history = useHistory();
  const classes = useStyles();
  const [id, setId] = useState(null);
  const [channelDoc, setchannelDoc] = useState(null);
  const [exsist, setExist] = useState(true);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setId(props);
    let docRef = firestore.collection("channels").doc(props.match.params.id);
    if (true) {
      docRef
        .get()
        .then((videoData) => {
          if (videoData.exists) {
            setchannelDoc(videoData.data());
          } else {
            setExist(false);
          }
        })
        .catch(() => {
          setExist(false);
        });
    }
  }, [props.match.params.id]);
  var shortNumber = require("short-number");
  return (
    <div className="App">
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${channelDoc?.channelName || "Channel"} | Millions`} </title>
          <link
            rel="canonical"
            href={`https://millionsofficial.in/channel/${props.match.params.id}`}
          />
        </Helmet>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            <div style={{ padding: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                  {/* {JSON.stringify(channelDoc)} */}
                  {channelDoc && channelDoc?.accountStatus !== "blocked" && (
                    <>
                      <div className={classes.root}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Paper
                              style={{
                                backgroundImage:
                                  "url(" + channelDoc.channelArt + ")",
                              }}
                              className={classes.paper}
                            >
                              <Box className={classes.overlay}>
                                <div>
                                  <div>
                                    <Box
                                      alignItems="center"
                                      display="flex"
                                      flexDirection="row"
                                      style={{ width: "100%", height: 200 }}
                                      p={1}
                                      m={1}
                                    >
                                      <Box p={1}>
                                        <Avatar
                                          alt={channelDoc.channelName}
                                          src={channelDoc.profilePic}
                                          className={classes.large}
                                        />
                                      </Box>
                                      <Box p={1}>
                                        <h2
                                          className={"m2"}
                                          style={{ color: "white" }}
                                        >
                                          <Box
                                            display="flex"
                                            flexDirection="row"
                                          >
                                            <Box>
                                              {channelDoc?.channelName || ""}
                                            </Box>
                                            <Box>
                                              {channelDoc.isVerified && (
                                                <span>
                                                  &nbsp;
                                                  <Tooltip title="Verified Channel">
                                                    <VerifiedUser
                                                      style={{ width: "25px" }}
                                                    />
                                                  </Tooltip>
                                                </span>
                                              )}
                                            </Box>
                                          </Box>
                                        </h2>
                                        <span
                                          className={"m2"}
                                          style={{ color: "white" }}
                                        >{`
                                          ${shortNumber(
                                            channelDoc.subscribers || 0
                                          )} Followers`}</span>
                                        <br />
                                        <br />
                                        {props.userDoc && (
                                          <FollowButton
                                            userDoc={props.userDoc}
                                            channelId={channelDoc.id}
                                            channelName={channelDoc.channelName}
                                            redirect={`/channel/${channelDoc.id}`}
                                          />
                                        )}
                                      </Box>
                                      {/* <Box p={1}>Item 3</Box> */}
                                    </Box>
                                  </div>
                                </div>
                              </Box>
                            </Paper>
                            {channelDoc && (
                              <div
                                className={classes.tab}
                                key={props.match.params.id}
                              >
                                <AppBar position="static" color="default">
                                  <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                  >
                                    <Tab
                                      label="Latest Videos"
                                      {...a11yProps(0)}
                                    />
                                    <Tab
                                      label="Popular Videos"
                                      {...a11yProps(1)}
                                    />
                                    <Tab label="Posts" {...a11yProps(2)} />
                                    <Tab label="About" {...a11yProps(3)} />
                                    {/* <Tab label="Item Five" {...a11yProps(4)} />
                                  <Tab label="Item Six" {...a11yProps(5)} />
                                  <Tab label="Item Seven" {...a11yProps(6)} /> */}
                                  </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0}>
                                  <Typography
                                    variant="h5"
                                    color="initial"
                                    gutterBottom
                                  >
                                    Latest Videos
                                  </Typography>
                                  <ChannelVideos
                                    id={props.match.params.id}
                                    channelId={channelDoc.id}
                                  />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                  <Typography
                                    variant="h5"
                                    color="initial"
                                    gutterBottom
                                  >
                                    Popular Videos
                                  </Typography>
                                  <ChannelPopularVideos
                                    channelId={channelDoc.id}
                                  />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                  <Typography
                                    variant="h5"
                                    color="initial"
                                    gutterBottom
                                  >
                                    Latest Posts
                                  </Typography>
                                  <ChannelPosts channelId={channelDoc.id} />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                  <Typography
                                    variant="h5"
                                    color="initial"
                                    gutterBottom
                                  >
                                    About Channel
                                  </Typography>
                                  <AboutChannel channelDoc={channelDoc} />
                                </TabPanel>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                  {channelDoc?.accountStatus === "blocked" && (
                    <div>
                      <Box
                        style={{
                          textAlign: "left",
                          paddingTop: "170px",
                          padding: "10%",
                        }}
                      >
                        <Typography variant="h3" color="initial">
                          Channel Blocked
                        </Typography>
                        <br />
                        <Typography variant="body1" color="initial">
                          This channel is blocked due to the violation of
                          guidelines. Kindly reached us if you think this is a
                          mistake.
                        </Typography>
                        <Box pt={3}>
                          <Button
                            variant="contained"
                            onClick={() => history.goBack()}
                            color="primary"
                            disableElevation
                          >
                            Go Back
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  )}
                  {!exsist && (
                    <div>
                      <Box
                        style={{
                          textAlign: "left",
                          paddingTop: "170px",
                          padding: "10%",
                        }}
                      >
                        <Typography variant="h3" color="initial">
                          Channel Not Found
                        </Typography>
                        <br />
                        <Typography variant="body1" color="initial">
                          This channel is not available or not created or maybe
                          removed. Check URL spelling. Upload a video to create
                          a channel
                        </Typography>
                        <Box pt={3}>
                          <Button
                            variant="contained"
                            onClick={() => history.goBack()}
                            color="primary"
                            disableElevation
                          >
                            Go Back
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  )}
                  <br />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TopChannelList />
                  <Footer />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
