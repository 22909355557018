import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { firestore } from "../base/firebaseConfig";
import Typography from "@material-ui/core/Typography";
import { Avatar, Box, Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../components/shared/Footer";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function MyNotifications(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let user = useContext(UserContext);
  const classes = useStyles();
  let history = useHistory();
  const [id, setId] = useState([]);
  const [videoDoc, setVideoDoc] = useState([]);
  const [exsist, setExist] = useState(true);
  let [start, setstart] = useState(0);
  const [ended, setended] = useState(false);
const [loading, setloading] = useState(true)

  useEffect(() => {
    setId(props);
    let docRef = firestore.collection("notifications");
    if (props.userDoc?.uid) {
      docRef
        .where("receiver", "==", props.userDoc?.uid)
        .orderBy("timeStamp", "desc")
        .limit(5)
        .get()

        .then((querySnapshot) => {
          let mylatest = [];
          let start = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(start);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            doc.ref.update({
              read: true,
            });

            mylatest.push(doc.data());
          });
          setVideoDoc(mylatest);
          setloading(false)
        })
        .catch((error) => {
          setloading(false)
        //console.log("Error getting documents: ", error);
        });
      // console.log(mylatest);
    }
  }, [props.userDoc]);

  const getMoreNotifications = async (start, list) => {
    let docRef = firestore.collection("notifications");
    (await start) &&
      docRef
        .where("receiver", "==", props.userDoc?.uid)
        .orderBy("timeStamp", "desc")
        .startAfter(start)
        .limit(4)
        .get()
        .then((querySnapshot) => {
          let mylatest = [...list];
          let end = start;
          let nextStart = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(nextStart);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            doc.ref.update({
              read: true,
            });

            mylatest.push(doc.data());
          });
          setVideoDoc(mylatest);
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
          alert("No More Videos");
        });

    if (!start) {
      setended(true);
    }
  };
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Notifications | Millions</title>
        <link
          rel="canonical"
          href={`https://millionsofficial.in/notifications`}
        />
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            <div style={{ padding: "10px" }}>
              {videoDoc.length > 0 && (
                <>
                 <Typography variant="h5" color="initial" gutterBottom>
                   My Notifications
                 </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Your notifications will appear here
                  </Typography>
                  <br/>
                </>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  {videoDoc?.map((item, index) => (
                    <Card
                      key={index}
                      className={classes.root}
                      variant="outlined"
                    >
                      <Link to={item.link}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          p={1}
                          m={1}
                          spacing={3}
                        >
                          <Box p={1}>
                            {item.type == "new-video" ? (
                              <div
                                style={{
                                  width: "100%",
                                  height: matches ? 120 : 240,
                                  backgroundColor: "gray",
                                  backgroundImage: "url(" + item.photo + ")",
                                }}
                                className={classes.bg}
                              ></div>
                            ) : (
                              <Avatar src={item.photo} />
                            )}
                          </Box>
                          <Box p={1}>
                            <Typography variant="body1" color="initial">
                              {item.notification}
                            </Typography>
                            <br />
                            <Typography variant="caption" color="initial">
                              {`${Moment(item.timeStamp).fromNow()}`}
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Card>
                  ))}

                  <Box
                    display="flex"
                    justifyContent="center"
                    style={{ width: "100%" }}
                    m={1}
                    p={1}
                    bgcolor="background.paper"
                  >
                    {videoDoc && videoDoc.length > 3 && (
                      <Button
                        onClick={() => getMoreNotifications(start, videoDoc)}
                        variant="outlined"
                        color="default"
                      >
                        Load more
                      </Button>
                    )}
                    <br />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    style={{ width: "100%" }}
                    m={1}
                    p={1}
                    bgcolor="background.paper"
                  >
                    {ended && (
                      <Typography variant="subtitle2" color="initial">
                        You reached at the end of the list
                      </Typography>
                    )}
                  </Box>
                  {!loading && videoDoc.length === 0 && (
                    <div>
                      <Box
                        style={{
                          textAlign: "left",
                          paddingTop: "100px",
                          padding: "10%",
                        }}
                      >
                        <Typography variant="h3" color="initial">
                          Notifications Empty
                        </Typography>
                        <br />
                        <Typography variant="body1" color="initial">
                          Important notifications and updates will appear here
                        </Typography>
                        <Box pt={3}>
                          <Button
                            variant="contained"
                            onClick={() => history.goBack()}
                            color="primary"
                            disableElevation
                          >
                            Go Back
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  )}
                 <Footer/>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
