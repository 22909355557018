import { Box, Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NewComment from "./NewComment";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Comments from "./Comments";
import { firestore } from "../../base/firebaseConfig";

export default function CommentContainer(props) {
  let videoRef = firestore.collection("comments");
  const [comments, setComments] = useState([]);
  let [loading, setloading] = useState(true);
  let [start, setstart] = useState(0);
  const [ended, setended] = useState(false);

  const getLatest = async () => {
    await videoRef
      .where("videoId", "==", props.id)
      .where("type", "==", "main-comment")
      .orderBy("date", "desc")
      .limit(4)
      .onSnapshot((querySnapshot) => {
        let myComments = [];
        querySnapshot.forEach((doc) => {
          let start = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(start);
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          myComments.push(doc.data());
        });
        setComments(myComments);
      });
    //   .catch((error) => {
    //     console.log("Error getting comments: ", error);
    //   });

  };

  useEffect(() => {
    getLatest();
  }, [props]);

  const getMoreComments = async (start, list) => {
    (await start) &&
      videoRef
        .where("videoId", "==", props.id)
        .where("type", "==", "main-comment")
        .orderBy("date", "desc")
        .limit(4)
        .startAfter(start)
        .get()
        .then((querySnapshot) => {
          let mylatest = [...list];
          let end = start;
          let nextStart = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(nextStart);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
          });
          setComments(mylatest);
          setloading(false);
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
          alert("No More Videos");
        });

    if (!start) {
      setended(true);
    }
  };

  return (
    <div>
      {props.videoDoc?.isComments !== "Disabled" ? (
        <Box pt={3}>
          <Typography variant="subtitle1" color="initial">
            {`${props.videoDoc?.comments || 0} comments`}
          </Typography>
          <Box pt={3}>
            <Grid container spacing={3}>
              <Grid item xs={2} sm={1}>
                <Avatar
                  alt={props.userDoc?.name}
                  src={props.userDoc?.profilePic}
                />
              </Grid>
              <Grid item xs={10} sm={11}>
                {props.userDoc && (
                  <NewComment
                    userDoc={props.userDoc}
                    videoDoc={props.videoDoc}
                    id={props.id}
                    source={props.source}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          <Comments
            userDoc={props.userDoc}
            videoDoc={props.videoDoc}
            id={props.id}
            source={props.source}
            comments={comments}
          />
          <Box
            display="flex"
            justifyContent="center"
            style={{ width: "100%" }}
            m={1}
            p={1}
            bgcolor="background.paper"
          >
            {comments && comments.length > 3 && (
              <Button
                onClick={() => getMoreComments(start, comments)}
                variant="outlined"
                color="default"
              >
                Load more
              </Button>
            )}
            <br />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            style={{ width: "100%" }}
            m={1}
            p={1}
            bgcolor="background.paper"
          >
            {ended && (
              <Typography variant="subtitle2" color="initial">
                You reached at the end of the list
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <div
          style={{ textAlign: "center", marginTop: "30px" }}
          className="text-muted"
        >
          Comments option is turned off
        </div>
      )}
    </div>
  );
}
