import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { firestore } from "../base/firebaseConfig";
import { Box, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function NotFound(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let user = useContext(UserContext);
  const classes = useStyles();
  let history = useHistory();

  return (
    <div className="App">
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid item xs={matches ? 11 : 12} style={{ marginTop: "100px" }}>
            <div>
              <Box
                style={{
                  textAlign: "left",
                  paddingTop: "170px",
                  padding: "10%",
                }}
              >
                <Typography variant="h3" color="initial">
                  404 Not Found :-(
                </Typography>

                <Typography variant="body1" color="initial">
                  This page is not available or maybe removed. Check URL
                  spelling
                </Typography>
                <Box pt={3}>
                  <Button
                    variant="contained"
                    onClick={() => history.goBack()}
                    color="primary"
                    disableElevation
                  >
                    Go Back
                  </Button>
                </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
