import React, { useContext, useState } from "react";
import { UserContext } from "../../base/UserContext";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import { languages, states, countries, districts } from "../constants";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { FieldValue, firestore } from "../../base/firebaseConfig";
import MuiPhoneNumber from "material-ui-phone-number";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import Declaration from "../shared/Declaration";
import { Box } from "@material-ui/core";
var isPresent = require("is-present");
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles((theme) => ({
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  //   maxWidth: 300,
  // },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 12,
        fontSize: 18,
        margin: theme.spacing(2),
        width: "100%",
      },
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function CompleateProfile(props) {
  const theme = useTheme();
  const history = useHistory();
  let user = useContext(UserContext);

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    history.push("/login");
  };

  const classes = useStyles();
  const [name, setName] = useState(user?.displayName);
  const [phone, setphone] = useState(null);
  const [gender, setGender] = useState("Male");
  const [state, setState] = useState("KL");
  const [district, setDistrict] = useState(null);
  const [country, setCountry] = useState("IN");
  const [language, setLanguage] = useState(["English", "Malayalam", "Tamil"]);
  const [open, setOpen] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [place, setPlace] = useState(null);
  const [realName, setrealName] = useState(user?.displayName);

  const [errorRealname, setErrorRealName] = useState(false);
  const [errorname, setErrorName] = useState(false);
  const [errorphone, setErrorphone] = useState(false);
  const [errorgender, setErrorGender] = useState(false);
  const [errorstate, setErrorState] = useState(false);
  const [errordistrict, setErrorDistrict] = useState(false);
  const [errorcountry, setErrorCountry] = useState(false);
  const [errorlanguage, setErrorLanguage] = useState(false);
  const [errorplace, setErrorPlace] = useState(false);

  const phoneChecker = require("phone");

  const handleName = (event) => {
    setName(event.target?.value);
  };

  const handleRealName = (event) => {
    setrealName(event.target?.value);
  };
  const handlephone = (value) => {
    setErrorphone(phoneChecker(value, "", true).length !== 2);
    setphone(value);
  };
  const handleGender = (event) => {
    setGender(event.target.value);
  };
  const handleCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleState = (event) => {
    setState(event.target.value);
  };
  const handleDistrict = (event) => {
    setDistrict(event.target.value);
  };
  const handlePlace = (event) => {
    setPlace(event.target.value);
  };

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let date = Date.now();
    const userData = {
      name: name || user.displayName,
      realName: realName || user.displayName,
      googleAccountName: user.displayName,
      uid: user.uid,
      accountStatus: "active",
      phone: phone,
      email: user.email,
      gender: gender,
      country: country || "IN",
      state: state || "KL",
      district: district || "Kollam",
      place: place,
      language: language || ["English", "Malayalam"],
      profilePic: user.photoURL,
      isVerified: false,
      accountCreatedFrom: "desktop",
      timestamp: FieldValue.serverTimestamp(),
      created: FieldValue.serverTimestamp(),
    };

    let walletData ={
      name: user.displayName,
      email: user.email,
      profilePic:user.photoURL,
      isActivated:true,
      isBlocked:false,
      money:0,
      lastUpdated:date,
      user:props.uid
    }
    !name && setErrorName(true);
    // !phone && setErrorphone(true);
    !gender && setErrorGender(true);
    !country && setErrorCountry(true);
    !state && setErrorState(true);
    !district && setErrorDistrict(true);
    if (language.length < 3) {
      setErrorLanguage(true);
    }
    if (name && gender && country && language) {
      handleClick();
      firestore
        .collection(`users`)
        .doc(props.uid)
        .set(userData)
        .then(() => {
          firestore
            .collection("wallets")
            .doc(props.uid)
            .set(walletData)
            .then(() => {
              handleClick();
            });
        });
    } else error();
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  function getSteps() {
    return ["Personal Details", "Location", "Preferences"];
  }

  const handleNext = () => {
    switch (activeStep) {
      //first step
      case 0:
        let isName = isPresent(name);
        let isGender = isPresent(gender);
        let isRealName = isPresent(realName);
        !isName && setErrorName(true);
        let isPhone = phoneChecker(phone, "", true).length === 2;
        !isGender && setErrorGender(true);
        !isRealName && setErrorRealName(true);
        !isPhone && setErrorphone(true);

        if (isName && isGender && isRealName && isPhone) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 1:
        let isCountry = isPresent(country);
        let isState = isPresent(state);
        let isDistrict = isPresent(district);
        let isPlace = isPresent(place);

        !isCountry && setErrorCountry(true);
        !isState && country == "IN" && setErrorState(true);
        !isDistrict && country == "IN" && setErrorDistrict(true);
        !isPlace && country == "IN" && setErrorPlace(true);

        if (isCountry && isState && isDistrict && isPlace) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 2:
        language.length < 4 && setErrorLanguage(true);

        if (!errorlanguage) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          alert("Please Select atleast three languages");
        }

      default:
        break;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const success = () => toast.success("Account created");
  const error = () => toast.error("Error occured, Please try again");

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div style={{  zIndex: 7000,}}>
            <TextField
              required
              className={classes.formControl}
              error={errorRealname}
              helperText={errorRealname && "Required"}
              value={realName}
              id="outlined-basic"
              label="Your Name"
              variant="outlined"
              onChange={handleRealName}
            />
            <TextField
              required
              className={classes.formControl}
              error={errorname}
              helperText={errorname && "Required"}
              value={name}
              id="outlined-basic"
              label="Display Name / Channel Name"
              variant="outlined"
              onChange={handleName}
            />
            <FormControl variant="outlined" className={classes.formControl}>
              <MuiPhoneNumber
                defaultCountry={"in"}
                required
                error={errorphone}
                helperText={errorphone && "Please enter a valid phone number"}
                id="outlined-basic2"
                label="Phone Number"
                variant="outlined"
                onChange={handlephone}
                value={phone}
              />

              <FormHelperText className={classes.formControl}>
                Required For monetization related communications
              </FormHelperText>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Gender
              </InputLabel>
              <Select
                required
                error={errorgender}
                helperText={errorgender && "Required"}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={gender}
                onChange={handleGender}
                label="Age"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
                <MenuItem value={"Ohers"}>Others</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      case 1:
        return (
          <div>
            <TextField
              required
              error={errorcountry}
              className={classes.formControl}
              helperText={errorcountry && "Required"}
              id="outlined-select-currency"
              select
              label="Country"
              value={country}
              onChange={handleCountry}
              helperText="Please select your country"
              variant="outlined"
            >
              {countries.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            {country === "IN" && (
              <>
                {" "}
                <TextField
                  required
                  error={errorstate}
                  className={classes.formControl}
                  helperText={errorstate && "Required"}
                  id="outlined-select-currency"
                  select
                  label="State"
                  value={state}
                  onChange={handleState}
                  helperText="Please select your state"
                  variant="outlined"
                >
                  {states.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  select
                  error={errordistrict}
                  className={classes.formControl}
                  helperText={errordistrict && "Required"}
                  id="outlined-basic3"
                  label="District"
                  // disabled={true}
                  variant="outlined"
                  onChange={handleDistrict}
                  value={district}
                >
                  {districts
                    .find((r) => r.state == state)
                    .districts.map((option) => (
                      <MenuItem value={option} key={state}>
                        {option}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  required
                  error={errorplace}
                  helperText={errorplace && "Required"}
                  className={classes.formControl}
                  id="outlined-basic3"
                  label="Place"
                  variant="outlined"
                  onChange={handlePlace}
                  value={place}
                />
              </>
            )}
          </div>
        );
      case 2:
        return (
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-chip-label">
                Select Video Languages
              </InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                required
                variant="filled"
                value={language}
                error={true}
                helperText={"Select atleast 3"}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {languages.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, language, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select atleast 3</FormHelperText>
            </FormControl>
          </div>
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <main>
      {district}
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      activeStep === steps.length - 1
                        ? handleSubmit
                        : handleNext
                    }
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
      <ToastContainer />
      <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Account Created!, Please login again
        </Alert>
      </Snackbar>
      {/* {String(language)} */}
      <form className={classes.root}>
        {/* <TextField
          required
          error={errorphone}
          helperText={errorphone && "Required"}
          id="outlined-basic2"
          label="Phone Number"
          variant="outlined"
          onChange={handlephone}
        />
        <FormHelperText>For monetization related communications</FormHelperText> */}

        {/* <button onClick={handleSubmit}>Add item</button> */}
      </form>
      <Box>
        <Declaration/>
      </Box>
    </main>
  );
}
