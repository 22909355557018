
import { Route, Redirect } from 'react-router-dom';
import React, { useContext, useState, useEffect } from "react";


const ProtectedRoute = ({ component: Component, ...rest }) => {
  let user = localStorage.getItem('uid')
  return (
    <Route {...rest} render={
      props => {
        if (user) {
         
          return <Component {...rest} {...props} />
        } else {
        
          localStorage.setItem("redirect",props.location?.pathname||"/")
          return (


             <Redirect to={
            {
              pathname: '/login',
              state: {
                from: props.location
              }
            }
          } />
      
          )
        }
      }
    } />
  )
}

export default ProtectedRoute;