import React,{useEffect} from "react";
import Button from "@material-ui/core/Button";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Link } from "react-router-dom";
export default function LoginPrompt(props) {

    const setRedirect= ()=>{
        localStorage.setItem("redirect",props.redirect||"/")
    }
  return (
    <div>
      <Alert severity={props.severity || "info"}>
        <AlertTitle>{props.title || "Never miss an update!"}</AlertTitle>
       {props.text || " Create an account or login to join our community of creators and users"}
        <br />
        <br />
        <Link to="/login" onClick={()=>setRedirect()}>
          <Button variant="outlined" color="inherit">
            Login Now
          </Button>
        </Link>
      </Alert>
    </div>
  );
}
