import React, { useEffect, useState } from "react";
import { firestore } from "../../base/firebaseConfig";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

export default function PostAds(props) {
  const [adDoc, setadDoc] = useState(null);
  const getAds = async (user, videoDoc) => {
    if (!adDoc) {
      let adRef = firestore.collection("post-ads");
      await adRef
        .where(
          "showAdIn",
          "in",
          [
            user?.district || "Kollam",
            user?.state || "KL",
            user?.country || "IN",
            "All Videos",
          ] || ["Kollam", "KL", "IN", "All Videos"]
        )
        .where("isVisible", "==", true)
        // .orderBy("date", "desc")
        .get()
        .then((querySnapshot) => {
          let mylatest = [];
          let adArr = [];
          querySnapshot.forEach((doc) => {
            //doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " ad => ", doc.data());

            mylatest.push(doc.data());
          });
          let length = mylatest.length;
          let random = Math.floor(Math.random() * length);
          adArr.push(mylatest[random]);

          if (mylatest.length == 0) {
            // alert("no ads")
          } else {
            if (!adDoc) {
              setadDoc(adArr[0]);
            }
          }
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
        });
      // console.log(mylatest);
    }
  };
  useEffect(() => {
    props.userDoc && getAds();
  }, [props.userDoc]);
  return (
    <div>
   { adDoc && <Card variant="outlined">
      <CardContent>
        <a target="_blank" href={adDoc?.adlink} style={{ marginTop: 20 }}>
          <img src={adDoc?.photoSrc} width="100%" />
        </a>
       
        <Typography variant="body2" component="p">
        <Chip label="AD" color="primary" size="small" />  {adDoc?.description}
        </Typography>
      </CardContent>
    </Card>}
    </div>
  );
}
