import React, { Component, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Box, Button } from "@material-ui/core";
import { FieldValue, firestore } from "../../base/firebaseConfig";
import Alert from '@material-ui/lab/Alert';
import {Link} from 'react-router-dom'
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

var isPresent = require("is-present");

export default function EditComment(props) {
  const [comment, setComment] = useState(props.comment?.comment);
  const [errorcomment, setErrorComment] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleComment = (event) => {
    setComment(event.target.value.replace(/↵/g, "  \n  "));
    setVisible(true);
    let isEmpty = isPresent(event.target.value);

    setErrorComment(!isEmpty);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      editedTime: FieldValue.serverTimestamp(),
      comment: comment.replace(/↵/g, "  \n  "),
    };
    firestore
      .collection(`comments`)
      .doc(props.comment?.uniqueId)
      .update(data)
      .then(() => {
       success()
        setVisible(false)
        props.setisEditing(false)
      })
      .catch((e)=>{
        error()
      });
      ;

      //increment counter
  };
  const success = () => toast.success("Comment Published");
  const error = ()=> toast.error("Error occured, Please try again")
  return (
    props.userDoc?.name? <div>
      {/* {`${props.source=="videos"? "watch": "posts"}/${props.id}`} */}
      <TextField
        multiline
        error={errorcomment}
        helperText={errorcomment && "Required"}
        id="outlined-basic"
        label="Comment"
        placeholder={`Comment as ${props.userDoc?.name}`}
        variant="outlined"
        value={comment}
        onChange={handleComment}
        style={{ width: "100%" }}
      />
       <ToastContainer />
      {visible && !errorcomment && (
        <Box display="flex" justifyContent="flex-end" mt={1}>
             <Box p={1}>
            <Button variant="outlined" color="default" onClick={()=> props.setisEditing(false)}>
              Cancel
            </Button>
          </Box>
          <Box p={1}>
            <Button variant="contained" color="default" onClick={handleSubmit}>
              Post
            </Button>
          </Box>
        </Box>
      )}
      {/* {JSON.stringify(props.videoDoc)} */}
    </div>: <Alert severity="warning">Please login to post a comment <br/><br/>
    <Link to={'/login'}>
      <Button variant="outlined" color="default">
        Login
      </Button>
    </Link>
   
    </Alert>
  );
}
