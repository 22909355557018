import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
export default function VideoListSkelton() {
  return (
    <div style={{ width: "100%", marginTop: "30px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Skeleton variant="rect" width={"100%"} height={"120px"} />
          <Skeleton variant="text" />
          <Skeleton variant="rect" width={"100%"} height={30} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Skeleton variant="rect" width={"100%"} height={"120px"} />
          <Skeleton variant="text" />
          <Skeleton variant="rect" width={"100%"} height={30} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Skeleton variant="rect" width={"100%"} height={"120px"} />
          <Skeleton variant="text" />
          <Skeleton variant="rect" width={"100%"} height={30} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Skeleton variant="rect" width={"100%"} height={"120px"} />
          <Skeleton variant="text" />
          <Skeleton variant="rect" width={"100%"} height={30} />
        </Grid>
      </Grid>
    </div>
  );
}
