import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { firestore } from "../base/firebaseConfig";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import Moment from "moment";
import ClampLines from "react-clamp-lines";
import VideoListSkelton from "../components/home/VideoListSkelton";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import VideoCard from "../components/video/VideoCard";
import { Helmet } from "react-helmet";
import { languages } from "../components/constants";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import PopularPosts from "../components/posts/PopularPosts";
import Footer from "../components/shared/Footer";
import LoginPrompt from "../components/shared/LoginPrompt";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  tab: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function ExploreVideos(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let user = useContext(UserContext);
  const classes = useStyles();
  const [id, setId] = useState(null);
  const [followDoc, setFollowDoc] = useState(null);
  const [latest, setLatest] = useState([]);
  const [isAnonymous, setAnonymous] = useState("");
  const [loading, setloading] = useState(true);
  const [value, setValue] = useState(0);

  let history = useHistory();
  const getTrending = async (list) => {
    let videoRef = firestore.collection("videos");
    props.userDoc &&
      (await videoRef
        .where("isVisible", "==", "Public")
        .where(
          "language",
          "in",
          props.userDoc !== "Anonymous"
            ? props.userDoc?.language || ["English", "Malayalam"]
            : languages.slice(0, 9)
        )
        // .where("videoScore", ">", 1)
        .orderBy("date", "desc")
        .limit(12)
        .get()
        .then((querySnapshot) => {
          let mylatest = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
          });
          setloading(false);
          setLatest(mylatest);
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
        }));
    // console.log(mylatest);
  };

  useEffect(() => {
    getTrending();
  }, [props]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Explore Videos | Millions</title>
        <link rel="canonical" href={`https://millionsofficial.in/explore`} />
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            {latest && (
              <Box overflow="hidden">
                <Typography variant="h5" color="initial" gutterBottom>
                  Latest
                </Typography>

                <>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="Videos" {...a11yProps(0)} />
                    <Tab label="Posts" {...a11yProps(1)} />
                    {/* <Tab label="Posts" {...a11yProps(2)} />
                    <Tab label="About" {...a11yProps(3)} /> */}
                    {/* <Tab label="Item Five" {...a11yProps(4)} />
                                  <Tab label="Item Six" {...a11yProps(5)} />
                                  <Tab label="Item Seven" {...a11yProps(6)} /> */}
                  </Tabs>
                </>

                <Box mt={3}>
                  {!props.userDoc?.uid && <LoginPrompt redirect="/explore" />}
                </Box>
                <Grid xs={12} sm={3}></Grid>
                {true && (
                  <Grid item xs={12}>
                    <div style={{ padding: "0px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <TabPanel value={value} index={0}>
                            <Grid container spacing={3}>
                              {latest?.map((item, index) => (
                                <Grid key={index} item xs={12} md={3} sm={6}>
                                  <VideoCard item={item} />
                                </Grid>
                              ))}
                              {latest.length == 0 && !loading && (
                                <div>
                                  <Box
                                    style={{
                                      textAlign: "left",
                                      paddingTop: "170px",
                                      padding: "10%",
                                    }}
                                  >
                                    <Typography variant="h3" color="initial">
                                      List Empty
                                    </Typography>
                                    <br />

                                    <Typography variant="body1" color="initial">
                                      This list is not available now. Please
                                      check later
                                    </Typography>
                                    <Box pt={3}>
                                      <Button
                                        variant="contained"
                                        onClick={() => history.goBack()}
                                        color="primary"
                                        disableElevation
                                      >
                                        Go Back
                                      </Button>
                                    </Box>
                                  </Box>
                                </div>
                              )}
                              {loading && (
                                <>
                                  <VideoListSkelton />
                                  <VideoListSkelton />
                                  <VideoListSkelton />
                                </>
                              )}
                            </Grid>
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <PopularPosts userDoc={props.userDoc} />
                          </TabPanel>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                )}
                <Footer />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
