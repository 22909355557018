import { Box, Chip, Typography, Button, Tooltip } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import NewlineText from "../utils/NewlineText";
import moment from "moment";
import CommentActions from "./CommentActions";
import { Link } from "react-router-dom";
import ClampLines from "react-clamp-lines";
import EditComment from "./EditComment";
import { VerifiedUser } from "@material-ui/icons";

export default function OneComment(props) {
  const [more, setmore] = useState(false);
  const [hide, sethide] = useState(false);
  const [isEditing, setisEditing] = useState(false);
  let showMoreLess = () => {
    setmore(!more);
  };
  let handleEdit = (bool) => {
    setisEditing(bool);
  };
  useEffect(() => {
    if (props.comment?.comment.length < 20) {
      sethide(true);
    }
  }, [props.comment?.comment]);
  return (
    <Box pt={1}>
      <Grid container spacing={1}>
        <Grid item xs={3} sm={1}>
          <Avatar alt={props.userDoc?.name} src={props.comment.profilePic} />
        </Grid>
        <Grid item xs={9} sm={11}>
          <Link to={`/channel/${props.comment.userId}`}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              style={{ fontWeight: 500 }}
            >
              <Box display="flex" flexDirection="row">
                <Box>
                  {" "}
                  {props.comment?.isOwner ? (
                    <Chip label={props.comment?.name} />
                  ) : (
                    props.comment?.name
                  )}
                </Box>
                <Box>
                  {props.comment?.isVerified && (
                    <span>
                      &nbsp;
                      <Tooltip title="Verified Channel">
                        <VerifiedUser style={{ width: "15px" }} />
                      </Tooltip>
                      
                    </span>
                  )}
                </Box>
                <Box pl={1}>
                <span style={{ fontWeight: 200 }} className="text-muted">{`  ${
                moment(props.comment?.date?.toMillis()).fromNow() || ""
              }`}</span>
                </Box>
              </Box>
             
            </Typography>
          </Link>
          {isEditing ? (
            <Box pt={3}>
              <EditComment
                comment={props.comment}
                userDoc={props.userDoc}
                setisEditing={setisEditing}
              />
            </Box>
          ) : (
            <Box pt={2}>
              {more ? (
                <NewlineText text={props.comment?.comment} />
              ) : (
                <ClampLines
                  text={props.comment?.comment || ""}
                  id="really-unique-id"
                  lines={3}
                  ellipsis="..."
                  moreText="Expand"
                  lessText="Collapse"
                  className="body2"
                  innerElement="p"
                  buttons={false}
                />
              )}
              {!hide && (
                <Button variant="text" color="default" onClick={showMoreLess}>
                  {more ? "LESS" : "MORE"}
                </Button>
              )}
            </Box>
          )}

          <CommentActions
            userDoc={props.userDoc}
            videoDoc={props.videoDoc}
            id={props.id}
            source={props.source}
            comment={props.comment}
            isReplyEnabled={props.isReplyEnabled}
            setisEditing={setisEditing}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
