import React, { useState, useEffect } from "react";
import { firestore } from "../../base/firebaseConfig";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { IconButton } from "@material-ui/core";
import {
  Edit,
  Link as LinkIcon,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function MyUploads(props) {
  const history = useHistory();
  const [myVideos, setmyVideos] = useState([]);
  const [start, setstart] = useState(0);
  const [ended, setended] = useState(false);
  let videoRef = firestore.collection("videos");
  const classes = useStyles();

  const getMyVideos = async () => {
    await videoRef

      .where("channelId", "==", props.channelDoc?.id)
      .orderBy("date", "desc")
      .limit(5)
      .get()
      .then((querySnapshot) => {
        let mylatest = [];
        let start = querySnapshot.docs[querySnapshot.docs.length - 1];
        setstart(start);

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          mylatest.push(doc.data());
        });
        setmyVideos(mylatest);
      })
      .catch((error) => {
        // console.log("Error getting documents: ", error);
      });
    // console.log(mylatest);
  };
  useEffect(() => {
    props.channelDoc && getMyVideos();
  }, [props.channelDoc]);
  const getMore = async (start, list) => {
    (await start) &&
      videoRef
        .where("channelId", "==", props.channelDoc?.id)
        .orderBy("date", "desc")
        .limit(5)
        .startAfter(start)
        .get()
        .then((querySnapshot) => {
          let mylatest = [...list];
          let nextStart = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(nextStart);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
          });
          setmyVideos(mylatest);
        })
        .catch((error) => {
          // console.log("Error getting documents: ", error);
        });
    // console.log(mylatest);
    if (!start) {
      setended(true);
    }
  };
  const columns = [
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            width: "90%",
            height: "90%",
            backgroundColor: "gray",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: "url(" + params.value + ")",
          }}
          className={classes.bg}
        ></div>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      width: 250,
      renderCell: (params) => <span>{params.value}</span>,
    },
    {
      field: "isVisible",
      headerName: "Visibiltiy",
      width: 150,
      renderCell: (params) => (
        <IconButton aria-label="edit" size="small">
          {params.value == "Blocked" && (
            <Chip
              size="small"
              icon={<VisibilityOff />}
              label="Private"
              color="primary"
            />
          )}
          {params.value == "Processing" && (
            <Chip
              size="small"
              icon={<LinkIcon />}
              label="Processing"
              color="yellow"
            />
          )}
          {params.value == "Public" && (
            <Chip
              size="small"
              icon={<Visibility />}
              label="Public"
              color="success"
            />
          )}
        </IconButton>
      ),
    },
    {
      field: "id",
      headerName: "Edit",
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <Link to={`/channel/edit-video/${params.value}`}>
          <IconButton aria-label="edit" size="small">
            <Chip
              size="small"
              icon={<Edit />}
              label="Edit"
              clickable
              color=""
            />
          </IconButton>
        </Link>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderCell: (params) => moment(params.value.toMillis()).fromNow(),
    },
    { field: "views", headerName: "Views", width: 120 },
    { field: "likes", headerName: "Likes", width: 120 },
    { field: "comments", headerName: "Comments", width: 150 },
  ];

  const handleRowClick = (param, event) => {
    history.push(`/channel/edit-video/${param.id}`);

    // window.location;
  };

  return (
    <div style={{ minHeight: 600, width: "100%" }}>
      <DataGrid
        autoHeight
        {...myVideos}
        rows={myVideos}
        columns={columns}
        pageSize={50}
        rowHeight={100}
        onRowClick={handleRowClick}
      />
      <div>
        <Box
          display="flex"
          justifyContent="center"
          style={{ width: "100%" }}
          m={1}
          p={1}
          bgcolor="background.paper"
        >
          {myVideos && myVideos.length > 0 && (
            <Button
              onClick={() => getMore(start, myVideos)}
              variant="outlined"
              color="default"
            >
              Load more
            </Button>
          )}
          <br />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          style={{ width: "100%" }}
          m={1}
          p={1}
          bgcolor="background.paper"
        >
          {ended && (
            <Typography variant="subtitle2" color="initial">
              You reached at the end of the list
            </Typography>
          )}
        </Box>
      </div>
    </div>
  );
}
