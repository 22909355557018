import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function BlankPage(props) {


  return (
    <div className="App">
     
    </div>
  );
}
