import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { firestore } from "../../base/firebaseConfig";
import Moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import NewlineText from "../utils/NewlineText";
import ClampLines from "react-clamp-lines";
import VideoCardWide from "./VideoCardWide";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));
export default function RelatedVideos(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  let videoRef = firestore.collection("videos");
  let [isUser, setIsUser] = useState("Loading");
  let [related, setrelated] = useState([]);
  let [latest, setlatest] = useState([]);
  let [start, setstart] = useState(0);
  const [ended, setended] = useState(false);

  const getrelated = async () => {
    props.userDoc &&
      (await videoRef
        .where("isVisible", "==", "Public")
        .where(
          "language",
          "in",
          props.userDoc !== "Anonymous"
            ? props.userDoc?.language || ["Malayalam", "English"]
            : ["Malayalam"]
        )
        .where("category", "==", props.videoDoc?.category || "Vlog")
        .orderBy("date", "desc")
        .limit(5)
        .get()
        .then((querySnapshot) => {
          let myrelated = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            myrelated.push(doc.data());
          });
          setrelated(myrelated);
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
        }));

    props.userDoc &&
      (await videoRef
        .where("isVisible", "==", "Public")
        .where(
          "language",
          "in",
          props.userDoc !== "Anonymous"
            ? props.userDoc?.language || ["Malayalam", "English"]
            : ["Malayalam", "English"]
        )
        .orderBy("date", "desc")
        .limit(7)
        .get()
        .then((querySnapshot) => {
          let mylatest = [];
          let start = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(start);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
          });
          setlatest(mylatest);
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
        }));
    // console.log(myrelated);
  };

  const getMoreVideos = async (start, list) => {
    (await start) &&
      videoRef
        .where("isVisible", "==", "Public")
        .where(
          "language",
          "in",
          props.userDoc !== "Anonymous"
            ? props.userDoc?.language || ["Malayalam", "English"]
            : ["Malayalam", "English"]
        )
        .orderBy("date", "desc")
        .startAfter(start)
        .limit(4)
        .get()
        .then((querySnapshot) => {
          let mylatest = [...list];
          let end = start;
          let nextStart = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(nextStart);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
          });
          setlatest(mylatest);
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
          alert("No More Videos");
        });

    if (!start) {
      setended(true);
    }
  };
  useEffect(() => {
    getrelated();
  }, [props.userDoc]);
  return (
    <div>
      {`Showing videos related to ${props.videoDoc?.category}`}
      <Grid container spacing={1}>
        {related?.map((item, index) => (
          <Grid key={index} container spacing={1} style={{ marginTop: 20 }}>
            <VideoCardWide item={item} />
          </Grid>
        ))}
        {latest?.slice(3, 500).map((item, index) => (
          <Grid key={index} container spacing={1} style={{ marginTop: 20 }}>
            <VideoCardWide item={item} />
          </Grid>
        ))}
      </Grid>
      <Box
        display="flex"
        justifyContent="center"
        style={{ width: "100%" }}
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        {latest && latest.length > 4 &&(
          <Button
            onClick={() => getMoreVideos(start, latest)}
            variant="outlined"
            color="default"
          >
            Load more
          </Button>
        )}
        <br />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        style={{ width: "100%" }}
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        {ended && (
          <Typography variant="subtitle2" color="initial">
            You reached at the end of the list
          </Typography>
        )}
      </Box>
      {/* Related {JSON.stringify(related)} */}
    </div>
  );
}
