import React, { useState, useEffect } from "react";
import { firestore } from "../../base/firebaseConfig";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { Avatar, IconButton } from "@material-ui/core";
import { ChatBubble, Delete, Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

//sheduled for deletions
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function MyChannelComments(props) {
  const [myVideos, setmyVideos] = useState([]);
  const classes = useStyles();

  const getMyVideos = async () => {
    let videoRef = firestore.collection("comments");
    await videoRef

      .where("channel", "==", props.channelDoc?.id)
      .orderBy("date", "desc")
      .limit(20)
      .get()
      .then((querySnapshot) => {
        let mylatest = [];
        let counter = 0;
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          let get = doc.data();
          let data = { id: counter, ...get };
          counter++;
          mylatest.push(data);
        });
        setmyVideos(mylatest);
      });

    // console.log(mylatest);
  };
  useEffect(() => {
    props.channelDoc && getMyVideos();
  }, [props]);

  const columns = [
    {
      field: "profilePic",
      headerName: "Photo",
      width: 70,
      renderCell: (params) => <Avatar src={params.value} alt={"No Name"} />,
    },
    {
      field: "name",
      headerName: "Name",
      width: 170,
      renderCell: (params) => <span>{params.value}</span>,
    },
    { field: "comment", headerName: "Comment", width: 250 },

    // {
    //   field: "commentId",
    //   headerName: "Delete",
    //   width: 120,
    //   sortable: false,
    //   renderCell: (params) => (
        
    //       <IconButton aria-label="edit" size="small">
    //         <Delete fontSize="inherit" />
    //       </IconButton>
       
    //   ),
    // },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderCell: (params) => moment(params.value?.toMillis()).fromNow(),
    },
    {
      field: "videoId",
      headerName: "View",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Link to={`/watch/${params.value}#comments`}>
          <IconButton aria-label="edit" size="small">
            <ChatBubble fontSize="inherit" />
          </IconButton>
        </Link>
      ),
    },
    { field: "videoTitle", headerName: "Video", width: 200 },
    { field: "likes", headerName: "Likes", width: 130 },
    { field: "replies", headerName: "Replies", width: 130 },
    // { field: "id", headerName: "Replies", width: 150  },
  ];

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        autoHeight
        {...myVideos}
        rows={myVideos}
        columns={columns}
        pageSize={5}
        rowHeight={80}
      />
    </div>
  );
}
