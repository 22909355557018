import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { firestore } from "../../base/firebaseConfig";
import Moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import NewlineText from "../utils/NewlineText";
import ClampLines from "react-clamp-lines";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ReactLinkify from "react-linkify";
import TopChannelList from "../../components/home/TopChannelList";
import VideoActions from "../../components/video/VideoActions";
import LazyLoad from 'react-lazy-load';
import PostCard from "./PostCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function PopularPosts(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  let postsRef = firestore.collection("posts");
  let [isUser, setIsUser] = useState("Loading");
  let [latest, setLatest] = useState([]);

  const getLatest = async () => {
    await postsRef
      .where("country", "==", props.userDoc?.country || "IN")
      .where("isVisible", "==", "Public")
      .orderBy("date", "desc")
      .limit(20)
      .get()

      .then((querySnapshot) => {
        let mylatest = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          mylatest.push(doc.data());
        });
        setLatest(mylatest);

      })
      .catch((error) => {
        // console.log("Error getting documents: ", error);
      });
    // console.log(mylatest);
  };

  useEffect(() => {
    getLatest();
  }, [props.userDoc]);

  return (
    <div>
      {latest && (
        <Box overflow="hidden">
          {latest && (
            <Grid item xs={12}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <Grid container spacing={3}>

                      {latest.map((item, index) => (
                        <Grid key={index} item xs={12} sm={12} md={6}>
                          <PostCard item={item} userDoc={props.userDoc} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3}>

                    <TopChannelList />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}
        </Box>
      )}
    </div>
  );
}
