import { Box, Typography } from "@material-ui/core";
import React,{useEffect,useState} from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import OneComment from "./OneComment";
import { firestore } from "../../base/firebaseConfig";

export default function ReplyComments(props) {
    
  return (
    <div>
      {/* {`reply-${props.comment?.commentId}`} */}
      {props.replies&& props.replies.map((comment, index) => (<OneComment
       
        key={comment?.uniqueId || index}
        userDoc={props.userDoc}
        videoDoc={props.videoDoc}
        id={props.id}
        source={props.source}
        comment={comment}
        isReplyEnabled={false}
      />))}
    </div>
  );
}
