import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./player.css";
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";
import("@silaas/videojs-preroll/dist/videojs-preroll.css");
require("videojs-hls-quality-selector");
require("videojs-settings-menu");
require("videojs-seek-buttons");

require("@silaas/videojs-preroll");

export default function VideoPlayerLight(props) {
  const playerRef = useRef();
  const options = {
    muted: true,
    autoplay: true,
    aspectRatio: "16:9",
    language: "en",
    preload: "auto",
    // textTrackSettings: true,
    // fluid: true,
    html5: {
      hls: {
        overrideNative: true,
        limitRenditionByPlayerDimensions: true,
        useDevicePixelRatio: true,
        // bandwidth: 16777216,
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      useBandwidthFromLocalStorage: true,
    },
    playbackRates: [0.5, 1, 1.5, 2],
    controlBar: {
      allowfullscreen: true,
      SubtitlesButton: true,
    },
  };
  useEffect(() => {
    const player = videojs(playerRef.current, options, () => {
      player.src(props.src);
      player.poster(props.poster);
      player.hlsQualitySelector({
        displayCurrentQuality: false,
      });
      player.seekButtons({
        forward: 30,
        back: 10,
      });
      player.ready(() => {
        player.muted(JSON.parse(localStorage.getItem("muted")) || false);
      });
      // player.on("ended", () => {
      //   setended(true)
      // });
      // player.on("timeupdate", () => {
      //   sethasStarted(player.hasStarted())
      //   setcurrentTime(player.currentTime());
      //   setduration(player.duration());
      //   setpaused(player.paused());
      //   player.muted()? localStorage.setItem("muted", JSON.stringify(player.muted())): localStorage.removeItem("muted");

      // });
    });

    return () => {
      player.dispose();
    };
  }, []);

  return (
    <div>
      <div data-vjs-player>
        <video
          id="player"
          crossorigin="anonymous"
          class="video-js vjs-lime"
          controls
          ref={playerRef}
          className="video-js vjs-16-9"
        />

      </div>
    </div>
  );
}
