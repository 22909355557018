import { Box, Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ClampLines from "react-clamp-lines";
import NewlineText from "../utils/NewlineText";

export default function VideoDiscription(props) {
  const [more, setmore] = useState(false);

  let showMoreLess = () => {
    setmore(!more);
  };
  return (
    <Box pt={props.pt || 2} className="">
      {more ? (
        <Typography variant="caption" color="initial">
          <NewlineText text={props.text || ""} />
        </Typography>
      ) : (
        <Typography variant="caption" color="initial">
        <ClampLines
          text={props.text || ""}
          id="really-unique-id"
          lines={4}
          ellipsis="..."
          moreText="Expand"
          lessText="Collapse"
          className="body2"
          innerElement="p"
          buttons={false}
        />
        </Typography>
      )}
      <Button variant="text" color="default" onClick={showMoreLess}>
        {more ? "LESS" : "MORE"}
      </Button>
    </Box>
  );
}
