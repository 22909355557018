import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { FieldValue, storage } from "../../base/firebaseConfig";
import MenuItem from "@material-ui/core/MenuItem";
import { firestore } from "../../base/firebaseConfig";
import MuiAlert from "@material-ui/lab/Alert";
import { useDropzone } from "react-dropzone";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import {
  languages,
  countries,
  categories,
  permissions,
  visibitity,
} from "../constants";
import Typography from "@material-ui/core/Typography";
import { Grid, Button, Box } from "@material-ui/core";
import { Add, Save, Share } from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { RWebShare } from "react-web-share";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles((theme) => ({
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  //   maxWidth: 300,
  // },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 12,
        fontSize: 18,
        margin: theme.spacing(2),
        width: "100%",
      },
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function VideoForm(props) {
  const theme = useTheme();
  const classes = useStyles();

  let d = new Date();
  let time = d.getTime();

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    history.push(`/channel/create`);
    // window.location.href = props.location;
  };
  useEffect(() => {
    setThumbnail(props.thumbnail);
    checkChannel();

    setId(props.id);
  }, []);
  let history = useHistory();
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [category, setCategory] = useState("Comedy");
  const [language, setLanguage] = useState("Malayalam");
  const [country, setCountry] = useState("IN");
  const [isComments, allowComments] = useState("Allowed");
  const [isVisible, setVisible] = useState("Public");
  const [open, setOpen] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [errortitle, setErrorTitle] = useState(false);
  const [newThumb, setNewThumb] = useState(null);
  const [oldgeneratedThumb, setOldGeneratedThumb] = useState(null);
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [videoid, setId] = useState(null);
  const [isShortUrl, setisShortUrl] = useState(false);
  const [shortUrl, setshortUrl] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleCategory = (event) => {
    setCategory(event.target.value);
  };
  const handleLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const handleCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleComments = (event) => {
    allowComments(event.target.value);
  };

  const handleVisbibility = (event) => {
    setVisible(event.target.value);
  };

  let date = new Date();
  const videoData = {
    title: title,
    description: description.replace(/↵/g, "  \n  ") || "",
    generatedThumbnail: props.thumbnail,
    thumbnail: thumbnail || props.thumbnail,
    videoSrc: props.videoSrc,
    category: category,
    language: language,
    country: country,
    isComments: isComments,
    isVisible: "Processing",
    channelId: props.userDoc.uid,
    isVerified: props.userDoc.isVerified || false,
    // channel:firestore.doc(`/channels/${props.userDoc.uid}`) ,
    channelName: props.userDoc.name,
    profilePic: props.userDoc?.profilePic || "",
    subscribers: 0,
    comments: 0,
    views: 0,
    likes: 0,
    disLikes: 0,
    videoScore: 0,
    date: FieldValue.serverTimestamp(),
    id: props.id,
    duration: props.duration || 404,
  };

  const checkChannel = async () => {
    //check channel exists and set data from userDoc
    let date = new Date();
    let channelData = {
      accountStatus:"active",
      isVerified:false,
      channelArt:"",
      channelScore:0,
      description:"",
      channelName: props.userDoc?.name || "Untitled Channel",
      realName:props.userDoc?.realName ||"",
      id: props.userDoc?.uid,
      created: FieldValue.serverTimestamp(),
      country: props.userDoc?.country || "IN",
      profilePic: props.userDoc?.profilePic || "",
      videos: 0,
      subscribers:0,
      email:props.userDoc?.email ||"",
      linkone:'',
      linktwo:'',
    };

    const channelRef = firestore.collection("channels").doc(props.userDoc.uid);
    try {
      const res = await firestore.runTransaction(async (t) => {
        const doc = await t.get(channelRef);
        if (!doc.data()) {
          await t.set(channelRef, channelData);

          return `Channel Created`;
        } else {
          throw "Channel Exists";
        }
      });

    } catch (e) {

    }
  };

  const handleSubmit = (event) => {

    !title && setErrorTitle(true);

    if (thumbnail === "https://i.ytimg.com/vi/epL51ythcJg/maxresdefault.jpg") {
    
    alert(`Please upload a thumbnail for your video`);
    } else {
      

    if (title) {
      const batch = firestore.batch();
      let date = new Date();
      let videoRef = firestore.collection("videos").doc(props.id);
      videoRef.set(videoData).then(() => {
        handleClickOpen();
        const forShortUrl = {
          dynamicLinkInfo: {
            domainUriPrefix: "https://millionsofficial.page.link",
            link: `https://millionsofficial.in/watch/${props.id}`,
            androidInfo: {
              androidPackageName: "com.millionsmedia.millions",
              androidFallbackLink: `https://millionsofficial.in/watch/${props.id}`,
              androidMinPackageVersionCode: "1",
            },
            socialMetaTagInfo: {
              socialTitle: title,
              socialDescription:
                description || "Hey checkout my latest video on my channel",
              socialImageLink: thumbnail || props.thumbnail,
            },
          },
        };
        axios
          .post(
            "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAt2ru_YTij8dIf6EFvVHGvEEzytXmBjRA",
            forShortUrl
          )
          .then((response) => {

            setshortUrl(response.data.shortLink);
            let videoRef = firestore.collection("videos").doc(props.id);
            videoRef
              .set(
                { shortLink: response.data.shortLink || false },
                { merge: true }
              )
              .then((docRef) => {
                setisShortUrl(true);
              });
          })
          .catch((error) => {
            console.error("There was an error!", error);
            alert("Some Error occured in publishing video");
          });
      });

      // handleClick(
    } else console.log(videoData);
  }
  };

  const handleEdit = (event) => {
    history.push(`/channel/edit-video/${props.id}`);
    // event.preventDefault();
    // !title && setErrorTitle(true);
    // if (title) {
    //   firestore.collection("videos")
    //     .doc(videoid)
    //     .set(videoData)
    //     .then(() => {
    //       alert("Edited Successfully");
    //       // handleClick()
    //     })
    //     .catch((error) => {
    //       console.error("Error adding document: ", error);
    //     });
    // } else console.log(videoData);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileChange(acceptedFiles);
    },
    [props]
  );

  const { getRootProps, getInputProps, fileRejections, isDragActive } =
    useDropzone({
      onDrop,
      accept: "image/*",
      maxFiles: 1,
      maxFilesize: 10,
    });

  const onFileChange = async (input) => {
    const ffmpeg = createFFmpeg({
      log: true,
    });
    ffmpeg.setProgress(({ ratio }) => {
      if (ratio >= 0 && ratio <= 1) {
        setProgress(ratio * 100);
      }
      if (ratio === 1) {
        setTimeout(() => {
          setProgress(0);
        }, 1000);
      }
    });
    const file = input[0];

    const size = Math.round(file.size / (1024 * 1024));
    // The size of the file.
    if (size >= 100) {
      alert("File too Big, please select a file less than 100mb" + size);
    }
    //  else if (size < 1) {
    //   alert("File too small, please select a file greater than 1mb");
    // }
    else {
  // setMessage("Loading ffmpeg-core.js");
      // await ffmpeg.load();
      setMessage("Processing");
      // ffmpeg.FS("writeFile", "thumb.jpg", await fetchFile(input[0]));
      // await ffmpeg.run("-i", "thumb.jpg", "-vf", "crop=640:360", "thumb.jpg");
      // const thumb = ffmpeg.FS("readFile", "thumb.jpg");
      // let generatedThumb = URL.createObjectURL(
      //   new Blob([thumb.buffer], { type: "image/jpeg" })
      // );
      // console.log(generatedThumb);
      let d = new Date();
      let time = d.getTime();
      let uid = props.userDoc.uid || "public";
      let thumbref = storage.child(
        `video-thumb/ ${props.id } .jpg`
      );
      var imgmetadata = {
        contentType: "image/jpeg",
      };
      let thumbuploadTask = thumbref.put(file, imgmetadata);
      thumbuploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setMessage("Uploading " + Math.round(progress) + "%");
          switch (snapshot.state) {
            case "paused": // or 'paused'
              setMessage("Upload is paused");
              break;
            case "running": // or 'running'

              break;
          }
        },
        (error) => {
          setMessage("Upload Error");
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          thumbuploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setMessage("Uploaded");
            setNewThumb(downloadURL);
            setThumbnail(downloadURL);
          });
        }
      );
    }
  };

  return (
    <div>
      {/* videoId: {props.id} */}
      <form className={classes.root}>
        <TextField
          required
          error={errortitle}
          helperText={errortitle && "Required"}
          id="outlined-basic"
          label="Video Title"
          variant="outlined"
          onChange={handleTitle}
        />
        <TextField
          // required
          // error={errortitle}
          // helperText={errortitle && "Required"}
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={4}
          defaultValue=""
          variant="outlined"
          onChange={handleDescription}
        />
        {props.thumbnail && (
          <div>
            <Typography variant="subtitle1" gutterBottom>
              Choose Thumbnail
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <div className="thumb">
                  {newThumb ? (
                    <>
                      <img
                        src={newThumb}
                        className={thumbnail == newThumb ? "selected" : "d"}
                        onClick={() => setThumbnail(newThumb)}
                        width="98%"
                      />
                      <Typography
                        variant="caption"
                        display="block"
                        onClick={() => setNewThumb(null)}
                      >
                        Change?
                      </Typography>
                    </>
                  ) : (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />

                      <div>
                        <Grid
                          className="upload-zone-small"
                          container
                          spacing={0}
                          align="center"
                          justify="center"
                          direction="column"
                        >
                          <Grid item>
                            <Add color="primary" />
                            <Typography
                              variant="caption"
                              display="block"
                              className="text-muted"
                              align="center"
                              justify="center"
                            >
                              {!isDragActive ? "Drag and drop" : "Drop Here"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                      <Typography
                        variant="caption"
                        display="block"
                        className="text-muted"
                        align="center"
                        justify="center"
                      >
                        {message + " "}
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                {props.thumbnail && (
                  <img
                    className={thumbnail == props.thumbnail ? "selected" : ""}
                    onClick={() => setThumbnail(props.thumbnail)}
                    src={props.thumbnail}
                    width="98%"
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )}

        <div>
          <Typography variant="subtitle1" gutterBottom>
            {/* Upload Thumbnail */}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <TextField
                id="outlined-select-currency"
                select
                label="Video Category"
                value={category}
                onChange={handleCategory}
                helperText=" Please select an option"
                variant="outlined"
              >
                {categories.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
          </Grid>
        </div>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            {/* Upload Thumbnail */}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Video Country"
                value={country}
                onChange={handleCountry}
                helperText=" Please select an option"
                variant="outlined"
              >
                {countries.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Video Language"
                value={language}
                onChange={handleLanguage}
                helperText=" Please select an option"
                variant="outlined"
              >
                {languages.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </div>
        <div className="pt-3 pb-3">
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <TextField
                id="outlined-select-currency"
                select
                disabled
                label="Video Visibility"
                value={isVisible}
                onChange={handleVisbibility}
                helperText=" Video will be public"
                variant="outlined"
              >
                {visibitity.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Comments"
                value={isComments}
                onChange={handleComments}
                helperText=" Please select an option"
                variant="outlined"
              >
                {permissions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </div>
        <div>
          <div className="pt-3 pb-3">
            <Button
              variant="contained"
              color="primary"
              disabled={props.thumbDone && props.videoDone ? false : true}
              size="large"
              className={classes.button}
              startIcon={<Save />}
              onClick={isShortUrl ? handleEdit : handleSubmit}
            >
              {isShortUrl ? "Go Back" : "SAVE"}
            </Button>

            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {isShortUrl ? "🎉 Video Uploaded and Started Processing" : "Please Wait"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {!isShortUrl && <LinearProgress />}
                  <Box pt={3} pb={3} style={{ width: "70vh" }}>
                    {isShortUrl ? (
                      <div>
                        Video started processing. Please wait for few minutes. We will let you know when it is ready.
                      </div>
                    ) : (
                      `Please wait until we save this video. Dont close this screen. Otherwise unexpected bad things will happen`
                    )}
                  </Box>
                  <br />
                  {/* <RWebShare
                    data={{
                      text: ` ${
                        description ||
                        "Hey checkout my video on  my Millions channel"
                      } - Millions`,
                      url: shortUrl,
                      title: ` ${title} - Millions`,
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <Button
                      disabled={!isShortUrl}
                      color="primary"
                      variant="contained"
                      startIcon={<Share />}
                    >
                      Share
                    </Button>
                  </RWebShare> */}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
                {/* <a href={`${shortUrl}`}>
                  <Button
                    onClick={handleClose}
                    disabled={!isShortUrl}
                    color="primary"
                  >
                    View Video
                  </Button>
                </a> */}
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </form>
      <br />
    </div>
  );
}
