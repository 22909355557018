import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import Moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import ClampLines from "react-clamp-lines";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import VideoActions from "../../components/video/VideoActions";
import LazyLoad from "react-lazy-load";
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip'
import { VerifiedUser } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function PostCard(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box width={"100%"}>
      <Card variant="outlined">
        <Link to={`/posts/${props.item.id}`}>
          <CardContent style={{ height:  props.scope == "minimal"? "300px" :"500px" }}>
            <CardActions>
            <Avatar alt={props.item.channelName} src={props.item?.profilePic}  style={{ width: "4vh", height: "4vh"}}/>
              <Typography display="block" variant="body" color="textSecondary">
              <Box display="flex" flexDirection="row" alignItems="center">
                  <Box>{props.item.channelName}</Box>
                  <Box>
                    {props.item.isVerified && (
                      <span>
                        &nbsp;
                        <Tooltip title="Verified Channel">
                          <VerifiedUser style={{ width: "15px" }} />
                        </Tooltip>
                      </span>
                    )}
                  </Box>
                </Box>
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {` ${Moment(props.item?.date?.toMillis()).fromNow()}`}
              </Typography>
            </CardActions>
            <Typography variant="body2" component="p" gutterBottom>
              <div
                style={{
                  height: props.item.photoSrc ? "" : "160px",
                }}
              >
                <ClampLines
                  id="really-unique-id"
                  lines={props.item.photoSrc ? 1 : 7}
                  ellipsis="..."
                  moreText="Expand"
                  lessText="Collapse"
                  className="body2"
                  innerElement="p"
                  buttons={false}
                  text={props.item.description || ""}
                />
              </div>
              <br />
              {props.item.photoSrc && (
                <LazyLoad height={matches ? 120 : 240} offsetVertical={300}>
                  <div
                    style={{
                      width: "100%",
                      height: props.scope == "minimal"? "200px" :"400px",
                      backgroundColor: "gray",
                      backgroundImage: "url(" + props.item.photoSrc + ")",
                    }}
                    className={classes.bg}
                  ></div>
                </LazyLoad>
              )}
            </Typography>
          </CardContent>
        </Link>
       {props.scope !== "minimal" &&<VideoActions
          id={props.item.id}
          userDoc={props.userDoc}
          videoDoc={props.item}
          source={"posts"}
        />}
      </Card>
    </Box>
  );
}
