import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import { firestore } from "../base/firebaseConfig";
import VideoActions from "../components/video/VideoActions";
import VideoDiscription from "../components/video/VideoDiscription";
import CommentContainer from "../components/comments/CommentContainer";
import RelatedVideos from "../components/video/RelatedVideos";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet";
import { Skeleton } from "@material-ui/lab";
import PostList from "../components/posts/PostsList";
import Footer from "../components/shared/Footer";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function PostDetail(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let user = useContext(UserContext);
  const classes = useStyles();
  const [id, setId] = useState(null);
  const [videoDoc, setVideoDoc] = useState(null);
  const [exsist, setExist] = useState(true);
  const [channelDoc, setchannelDoc] = useState(null);
  const [error, seterror] = useState(false);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setId(props);
    let docRef = firestore.collection("posts").doc(props.match.params.id);
    if (true) {
      docRef
        .get()
        .then((videoData) => {
          if (videoData.exists) {
            setVideoDoc(videoData.data());
            getChannel(videoData.data().channelId);
            setloading(false);
          } else {
            setExist(false);
            setloading(false);
          }
        })
        .catch(() => {
          setExist(false);
          seterror(true);
          setloading(false);
        });
    }
  }, [props.match.params.id]);

  let getChannel = async (id) => {
    let channelRef = firestore.collection("channels").doc(id);
    if (channelRef) {
      channelRef
        .get()
        .then((channelData) => {
          if (channelData.exists) {
            setchannelDoc(channelData.data());
          } else {
            seterror(false);
          }
        })
        .catch(() => {
          seterror(true);
        });
    }
  };
  const bull = <span>•</span>;

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`${videoDoc?.title || "Post"} | Millions`}</title>
        <link
          rel="canonical"
          href={`https://millionsofficial.in/notifications`}
        />
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            <div style={{ padding: "10px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  {loading && <Skeleton height="70vh" width="100%" />}
                  {videoDoc && videoDoc?.isVisible == "Public" && (
                    <>
                      <Card className={classes.root} variant="outlined">
                        <CardContent>
                          {/* <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            Word of the Day
                          </Typography> */}
                          <Typography variant="h5" component="h2" gutterBottom>
                            Post By {videoDoc?.channelName}
                          </Typography>
                          {/* <Typography
                            className={classes.pos}
                            color="textSecondary"
                          >
                            adjective
                          </Typography> */}
                          <Typography variant="body2" component="p">
                            {videoDoc?.description && (
                              <VideoDiscription
                                pt={1}
                                text={videoDoc?.description}
                              />
                            )}
                          </Typography>
                          <br />

                          {videoDoc?.photoSrc && (
                            <img src={videoDoc?.photoSrc} width={"100%"} />
                          )}
                        </CardContent>
                        <CardActions>
                          <VideoActions
                            id={props.match.params.id}
                            userDoc={props.userDoc}
                            channelDoc={channelDoc}
                            videoDoc={videoDoc}
                            source={"posts"}
                          />
                        </CardActions>
                      </Card>

                      <CommentContainer
                        id={props.match.params.id}
                        userDoc={props.userDoc}
                        channelDoc={channelDoc}
                        videoDoc={videoDoc}
                        source={"posts"}
                      />
                    </>
                  )}
                  <br />
                  {videoDoc?.isVisible === "Blocked" && (
                    <Card className={classes.root} variant="outlined">
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Not Available
                        </Typography>
                        <Typography variant="h5" component="h2" gutterBottom>
                          ⚠️ Post Blocked
                        </Typography>

                        <Typography variant="body2" component="p">
                          This post is removed due to the violation of
                          guidelines.
                          <br />
                          If you think it is a mistake kindly reach us at
                          mail@example.com
                          <br />
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions>
                    </Card>
                  )}
                  {!exsist && (
                    <Card className={classes.root} variant="outlined">
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Not Found
                        </Typography>
                        <Typography variant="h5" component="h2" gutterBottom>
                          ⚠️ Post Not Found
                        </Typography>

                        <Typography variant="body2" component="p">
                          The link you followed may be broken, or the page may
                          have been removed
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">Go Back</Button>
                      </CardActions>
                    </Card>
                  )}

                  {error && (
                    <Card className={classes.root} variant="outlined">
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Error
                        </Typography>
                        <Typography variant="h5" component="h2" gutterBottom>
                          ⚠️Some error occured
                        </Typography>

                        <Typography variant="body2" component="p">
                          Error occured while loading the post
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">Go Back</Button>
                      </CardActions>
                    </Card>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ paddingRight: 20 }}
                  style={{ paddingLeft: 20 }}
                >
                  {videoDoc && (
                    <PostList userDoc={props.userDoc}/>
                    // <RelatedVideos
                    //   userDoc={props.userDoc}
                    //   videoDoc={videoDoc}
                    //   channelDoc={channelDoc}
                    // />
                  )}
                  <Footer/>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
