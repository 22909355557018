//recommended posts

import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { firestore } from "../../base/firebaseConfig";
import Moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import NewlineText from "../utils/NewlineText";
import ClampLines from "react-clamp-lines";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ReactLinkify from "react-linkify";
import LazyLoad from "react-lazy-load";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from '@material-ui/core/Tooltip'
import { VerifiedUser } from "@material-ui/icons";
import PostCard from "./PostCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function PostList(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  let postsRef = firestore.collection("posts");
  let [isUser, setIsUser] = useState("Loading");
  let [latest, setLatest] = useState([]);
  let [start, setstart] = useState(0);
  const [ended, setended] = useState(false);

  const getLatest = async () => {
    await postsRef
      .where("country", "==", props.userDoc?.country || "IN")
      .where("isVisible", "==", "Public")
      .orderBy("likes", "desc")
      .limit(4)
      .get()

      .then((querySnapshot) => {
        let mylatest = [];
        let start = querySnapshot.docs[querySnapshot.docs.length - 1];
        setstart(start);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          mylatest.push(doc.data());
        });
        setLatest(mylatest);
      })
      .catch((error) => {
      //console.log("Error getting documents: ", error);
      });
    // console.log(mylatest);
  };

  const getMorePosts = async (start, list) => {
    (await start) &&
      postsRef
        .where("country", "==", props.userDoc?.country || "IN")
        .where("isVisible", "==", "Public")
        .orderBy("likes", "desc")
        .startAfter(start)
        .limit(4)
        .get()
        .then((querySnapshot) => {
          let mylatest = [...list];
          let end = start;
          let nextStart = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(nextStart);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
          });
          setLatest(mylatest);
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
          alert("No More Posts");
        });

    if (!start) {
      setended(true);
    }
  };
  useEffect(() => {
    getLatest();
  }, [props.userDoc]);

  return (
    <div>
      {latest && (
        <Box overflow="hidden" pt={0}>
          {latest && (
            <Grid item xs={12}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      {latest.map((item, index) => (
                        <Grid key={index} item xs={12} sm={12}>
                            <PostCard item={item} userDoc={props.userDoc} scope={'minimal'} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}

          <Box
            display="flex"
            justifyContent="center"
            style={{ width: "100%" }}
            m={1}
            p={1}
            bgcolor="background.paper"
          >
            {latest && (
              <Button
                onClick={() => getMorePosts(start, latest)}
                variant="outlined"
                color="default"
              >
                Load more
              </Button>
            )}
            <br />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            style={{ width: "100%" }}
            m={1}
            p={1}
            bgcolor="background.paper"
          >
            {ended && (
              <Typography variant="subtitle2" color="initial">
                You reached at the end of the list
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </div>
  );
}
