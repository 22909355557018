import React, { useRef } from "react";
import Linkify from 'linkifyjs/react';

export default function NewlineText(props) {
  const link = useRef();
  const text = props.text;

  let options ={
    attributes: null,
    className: 'linkified',
    defaultProtocol: 'https',
    tagName: 'a',
    target: {
      url: '_blank'
    },
    validate: true
  }
  const newText = text.split("\n").map((str) => (
    <p key={str+ Math.random()}>
     <Linkify tagName="span" options={options}>{str}</Linkify>
    </p>
  ));

  return newText;
}
