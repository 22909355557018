import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppLogo from "./AppLogo";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import NotificationsIcon from "@material-ui/icons/Notifications";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import { auth, logoutall, firestore } from "../../base/firebaseConfig";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchBar from "material-ui-search-bar";
import { countries } from "../constants";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { Autocomplete } from "./Autocomplete";
import { ProductItem } from "./ProductItem";
import { useHistory } from "react-router-dom";
import NotificationBell from "./NotificationBell";
import { Paper } from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "fixed",
    width: "100%",
    zIndex: 1000,

    // minHeight: 70,
    //   backgroundColor:"gray"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function NavBar(props) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alertopen, setOpen] = React.useState(false);
  const [search, setSearch] = useState("");
  
  function handleNavigate(link) {
    history.push(link);
    handleClose()
  }
  
  const handleClickOpen = () => {
    setOpen(true);
    handleClose()
  };

  const logout = () => {
    logoutall()
  }
  const handleClickClose = () => {
    setOpen(false);
    forceAuth("/channel/create")
  };

  const handleClickCancel = () => {
    setOpen(false);
  };
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const forceAuth = (loc) => {
    localStorage.setItem("redirect",loc||"/")
    history.push("/login");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (search) => {
    history.push(`/search/?query=${search}`);
  };
  const matches = useMediaQuery("(min-width:600px)");
  const searchMatch = useMediaQuery("(min-width:1020px)");
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper  elevation={0}>
      <Grid container spacing={0}>
        <Grid item xs={1} style={{}}>
          <div
            style={{
              // width: matches ? "120px" : 0,
              // backgroundColor: "#F4F6F9",
              alignItems: "center",
            }}
          >
            <Box display="flex" alignItems="center" p={1}>
              <AppLogo width="70px" />
            </Box>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Box p={2.5}>
            {searchMatch && (
              <SearchBar
              placeholder={'Search for videos'}
                // dataSource={auto}
                // dataSourceConfig={{ text: "code", value: "label" }}
                value={search}
                onChange={(newValue) => setSearch(newValue)}
                onRequestSearch={() => handleSearch(search)}
                style={{
                  margin: "0 auto",
                  maxWidth: "100%",
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <div style={{ width: "100%" }}>
            <Box display="flex" flexDirection="row-reverse">
              <Box p={2}>
                {props.userDoc !== "Anonymous" && (
                  <Avatar
                  style={{cursor:"pointer"}}
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    alt={String(props.userDoc?.name)}
                    src={String(props.userDoc?.profilePic)}
                  />
                )}
                {props.userDoc  == "Anonymous" && (
                  <Avatar onClick={() => forceAuth()}   style={{cursor:"pointer"}} alt={""} src={""} />
                )}
              </Box>

              {searchMatch ? (
                <>
                <Box p={1.4}>
                {  props.userDoc !== "Anonymous"  && <NotificationBell userDoc={props.userDoc}/>}
                </Box>
                </>
              ) : (
                <>
                <Box p={2.5}>
                <Link to={'/search'}>
                  <SearchIcon style={{ fontSize: 30}} color={"secondary"}/>
                  </Link>
                </Box>
                <Box p={1}>
                {  props.userDoc !== "Anonymous"  && <NotificationBell userDoc={props.userDoc}/>}
                </Box>
                </>
              )}

              <Box p={matches ? 2 : 2.5}>
                {matches && (
                  <>
                    {props.userDoc !== "Anonymous" ? (
                      <Link to={"/channel/create"}>
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<VideoCallIcon />}
                        >
                          Upload
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleClickOpen}
                        startIcon={<VideoCallIcon />}
                      >
                        Upload
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <div>
          {props.userDoc !== "Anonymous" && (
            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={()=> handleNavigate(`/channel/${props.userDoc?.uid}`)}>
              
                  My Channel

              </MenuItem>
              <MenuItem  onClick={()=> handleNavigate(`/my-profile`)}>My Account</MenuItem>
              {/* <MenuItem  onClick={()=> handleNavigate(`settings`)}>Settings</MenuItem> */}
              <MenuItem  onClick={()=> props.handleThemeChange()}>{props.currentMode? 'Light': 'Dark'} Mode</MenuItem>
              <MenuItem onClick={() => logout()}>Logout</MenuItem>
            </Menu>
          )}
        </div>
      </div>
      <Dialog
        open={alertopen}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Signin Required"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please signin to continue to channel section
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCancel} color="primary">
            Close
          </Button>
          <Button onClick={handleClickClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      </Paper>
    </div>
  );
}
