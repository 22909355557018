import React from "react";

export default function BgImage() {
  return (
    <div
      style={{
        position: "fixed",
        height: "100vh",
        width: "40%",
        zIndex: 4000,
      }}
    >
      <div className="bg-image">
        <div className="bg-overlay "></div>
      </div>
    </div>
  );
}
