import React from "react";
import ClampLines from "react-clamp-lines";
import NewlineText from "../utils/NewlineText";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import { Box, Button } from "@material-ui/core";
import moment from "moment";
import ReportChannel from "./ReportChannel";
export default function AboutChannel(props) {
  return (
    <div>
      <Typography variant="caption" color="initial">
        <NewlineText text={props.channelDoc?.description || "No description"} />
      </Typography>
      <Divider />
      {props.channelDoc?.linkone && (
        <Box mt={3} mb={2}>
          <Typography variant="h6" color="initial">
            Links
          </Typography>
          <Typography variant="caption" color="initial">
            <a href={props.channelDoc?.linkone} target="_blank">
              {props.channelDoc?.linkone}
            </a>
          </Typography>
          <br />
          <Typography variant="caption" color="initial">
            <a href={props.channelDoc?.linktwo} target="_blank">
              {props.channelDoc?.linktwo}
            </a>
          </Typography>
          <br />
          <Typography variant="caption" color="initial">
            <a href={props.channelDoc?.linkthree} target="_blank">
              {props.channelDoc?.linkthree}
            </a>
          </Typography>
          <br />
          <Typography variant="caption" color="initial">
            <a href={props.channelDoc?.linkfour} target="_blank">
              {props.channelDoc?.linkfour}
            </a>
          </Typography>
          <br />
          <Typography variant="caption" color="initial">
            <a href={props.channelDoc?.linkfive} target="_blank">
              {props.channelDoc?.linkfive}
            </a>
          </Typography>
          <br />
        </Box>
      )}
      <Divider />
      <Box mt={3} mb={3}>
        <Typography variant="h6" color="initial">
          Details
        </Typography>
        <Typography variant="caption" color="initial">
          {`Country Code : - ${props.channelDoc?.country}`}
        </Typography>
        <br />
        <Typography variant="caption" color="initial">
          {`Created : - ${moment(props.channelDoc?.created.toMillis()).format(
            "MMMM Do YYYY"
          )}`}
        </Typography>
      </Box>
      <Divider />

      <Box mt={2}>
        <ReportChannel channelDoc={props.channelDoc} />
      </Box>
    </div>
  );
}
