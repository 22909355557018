import React, { useEffect, useState } from "react";
import { FieldValue, firestore } from "../../base/firebaseConfig";

export default function VideoAnalytics(props) {
  const [currentTime, setcurrentTime] = useState(props.player?.currentTime);
  const [percentage, setpercentage] = useState(0)

  useEffect(() => {
    setcurrentTime(props.player?.currentTime);
    localStorage.setItem("time", props.player?.currentTime)
  }, [props.player?.currentTime]);

  useEffect(() => {
    const interval = props.player?.hasStarted && setInterval(() => {
      if (
        props.player?.hasStarted &&
        !props.player?.paused &&
        !props.player?.ended
      ) {
        let ip = localStorage.getItem("ip");
        let random = Math.random() * 100;
        let upcurrentTime = localStorage.getItem("time");
        let percentage = (upcurrentTime / (props.player?.duration || 2)) * 100;
        setpercentage(percentage);
        let id = props.userDoc?.uid ? props.userDoc?.uid : random;
        // console.log(percentage);

        if (percentage > 60) {
          // console.log(true, "is view send");
       
            firestore
              .collection("views")
              .doc(id + "_" + props.id)
              .set(
                {
                  user: props.userDoc?.uid || ip,
                  itemId: props.id,
                  channel: props.channelDoc?.id,
                  time: FieldValue.serverTimestamp(),
                  percentage: percentage,
                },
                { merge: true }
              ).then(() => {  
                //  alert("is view send");
                })
        } else {
          // console.log("Less than 60%");
        }
      } else {
        // console.log("Not Playing");
      }
    }, (props.player?.duration * 1000) / 6); // update counter 6 times the duration

    return () => clearInterval(interval);
  }, [
    props.player?.duration,
    props.player?.hasStarted,
    props.player?.paused,
    props.player?.ended,
  ]);

  return (
    <div style={{ display: "none" }}>
      {JSON.stringify(percentage, props.player)}

    </div>
  );
}
