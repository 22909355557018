import React, { useEffect, useState } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { firestore } from "../../base/firebaseConfig";
import { Link } from "react-router-dom";
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Badge);

export default function NotificationBell(props) {
  const [notificationCount, setnotificationCount] = useState(0);

  useEffect(() => {
    props.userDoc && getLatest();
  }, [props.userDoc]);

  const getLatest = async () => {
    let channelRef = firestore.collection("notifications");
    await channelRef
      .where("receiver", "==", props.userDoc.uid)
      .where("read", "==", false)
      .limit(25)
      .orderBy("timeStamp", "desc")
      .onSnapshot((querySnapshot) => {
        setnotificationCount(querySnapshot.size);
      })
     
    // console.log(myChannels);
  };
  return (
    <div>
      <IconButton aria-label="cart">
          <Link to={'/notifications'}>
        <StyledBadge badgeContent={notificationCount} max={25} color="primary">
          <NotificationsIcon style={{ fontSize: 30}}  color="secondary"/>
        </StyledBadge>
        </Link>
      </IconButton>
    </div>
  );
}
