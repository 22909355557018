import React, { useState, useEffect, useCallback, useContext } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { Prompt } from "react-router-dom";
import { FieldValue, firestore, storage } from "../base/firebaseConfig";
import Paper from "@material-ui/core/Paper";
import { Avatar, Box, Button } from "@material-ui/core";
import FollowButton from "../components/channel/FollowButton";
import ChannelVideos from "../components/channel/ChannelVideos";
import TopChannelList from "../components/home/TopChannelList";
import TextField from "@material-ui/core/TextField";
import { useDropzone } from "react-dropzone";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import Typography from "@material-ui/core/Typography";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import MenuItem from "@material-ui/core/MenuItem";
import { Alert, AlertTitle } from "@material-ui/lab";
import moment from "moment";
import {
  languages,
  countries,
  categories,
  permissions,
  visibitity,
} from "../components/constants";
import NewlineText from "../components/utils/NewlineText";
import ClampLines from "react-clamp-lines";
import { Save } from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";
import Footer from "../components/shared/Footer";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function EditPost(props) {
  const matches = useMediaQuery("(min-width:850px)");
  const classes = useStyles();
  const history = useHistory();
  const [videoDoc, setVideoDoc] = useState(null);

  let user = useContext(UserContext);

  const [channelDoc, setchannelDoc] = useState(null);
  const [exsist, setExist] = useState(true);
  const [message, setMessage] = useState("");
  const [bannerMessage, setBannerMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [postText, setPostText] = useState("");
  const [oldName, setOldName] = useState("");
  const [channelArt, setChannelArt] = useState("");
  const [picture, setPicture] = useState("");
  const [edited, setEdited] = useState(false);
  const [titleEdited, setTextEdited] = useState(false);
  const [isComments, allowComments] = useState("Allowed");
  const [more, setmore] = useState(false);
  const [postid, setId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isDelete, setisDelete] = useState("");
  const [loading, setloading] = useState(true)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let postsRef = firestore.collection("posts").doc(props.match.params.id);
  let showMoreLess = () => {
    setmore(!more);
  };
  useEffect(() => {
    checkChannel();
  }, [props.userDoc]);

  const checkChannel = async () => {
    //check channel exists and set data from userDoc
    let date = new Date();
    let channelData = {
      accountStatus:"active",
      isVerified:false,
      channelArt:"",
      channelScore:0,
      description:"",
      channelName: props.userDoc?.name || "Untitled Channel",
      realName:props.userDoc?.realName ||"",
      id: props.userDoc?.uid,
      created: FieldValue.serverTimestamp(),
      country: props.userDoc?.country || "IN",
      profilePic: props.userDoc?.profilePic || "",
      videos: 0,
      subscribers:0,
      email:props.userDoc?.email ||"",
      linkone:'',
      linktwo:'',
    };
    const channelRef = firestore.collection("channels").doc(props.userDoc?.uid);

    if (props.userDoc?.uid) {
      try {
        const res = await firestore.runTransaction(async (t) => {
          const doc = await t.get(channelRef);
          if (!doc.data()) {
            await t.set(channelRef, channelData);
            alert("no channel");
            //  alert('no channel')
            return `Channel Created`;
          } else {
            //  alert('channel ond')
            throw "Channel Exists";
          }
        });
        
      } catch (e) {

      }
    }
  };

  const setText = (event) => {
    setEdited(true);
    setPostText(event.target.value);
  };
  const handleComments = (event) => {
    allowComments(event.target.value);
  };

  const handleDeleteConfirm = (event) => {
    setisDelete(event.target.value);
  };
  const onSave = () => {
    let date = new Date();
    const postData = {
      description: postText.replace(/↵/g, "  \n  ") || "",
      photoSrc: picture || "",
      isComments: isComments,
      updated: FieldValue.serverTimestamp(),
    };

    postsRef
      .set(postData, { merge: true })
      .then((docRef) => {
        alert("Successs");
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
    // handleClick()
  };
  const handlDelete = () => {

    firestore
      .collection("posts")
      .doc(props.match.params.id)
      .delete()
      .then(() => {

        alert("Post Deleted");
        history.push("/channel/create");
      })
      .catch((error) => {
        alert("Some error occured ");
      });
    setOpen(false);
  };
  useEffect(() => {
    setId(props);

    if (true) {
      postsRef
        .get()
        .then((videoData) => {
          if (videoData.exists) {
            setVideoDoc(videoData.data());

            setPostText(videoData.data().description);
            allowComments(videoData.data().isComments);
            setPicture(videoData.data().photoSrc);
            setExist(true)
            setloading(false)
          } else {
            setExist(false);
            setloading(false)
          }
        })
        .catch(() => {
          setExist(false);
          setloading(false)
        });
    }
  }, [props]);

  return (
    <div className="App">
         <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Post | Millions</title>
        <link
          rel="canonical"
          href={`https://millionsofficial.in/channel/edit-post/${props.userDoc?.uid}`}
        />
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
         { exsist && props.userDoc?.uid === videoDoc?.channelId ?  <div style={{ padding: "10px" }}>
            

              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  {props.userDoc && (
                    <>
                    <Typography variant="h5" color="initial" gutterBottom>
                      Edit Post
                    </Typography>
                    
                    </>
                  )}
                  <div style={{ width: "100%" }}>
                    {edited && (
                      <Alert severity="warning">
                        <AlertTitle>Warning</AlertTitle>
                        Please click save button to keep your edits
                        {/* <strong>check it out!</strong> */}
                      </Alert>
                    )}
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      pt={1}
                      mt={1}
                      mb={1}
                    >
                
                      <Box pt={1}>
                        {edited && (
                          <Button
                            variant="contained"
                            disabled={edited ? false : true}
                            onClick={onSave}
                            color="primary"
                          >
                            Save
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </div>
                <Box mt={3} mb={1} style={{ width: "100%" }} mb={3}>
                    <TextField
                      //   required
                      style={{ width: "100%" }}
                      multiline
                      rows={4}
                      value={postText}
                      id="outlined-basic"
                      //   defaultValue={channelName}
                      label="Write a post"
                      variant="outlined"
                      onChange={setText}
                    />
                  </Box>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Comments"
                      value={isComments}
                      onChange={handleComments}
                      helperText=" Please select an option"
                      variant="outlined"
                    >
                      {permissions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Box pt={5}>
                    <Divider light />
                    <Box pt={3}>
                      <Typography variant="subtitle2" color="initial">
                        Danger Zone
                      </Typography>
                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickOpen}
                        disableElevation
                      >
                        DELETE POST
                      </Button>
                    </Box>
                  </Box>

                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Are you absolutely sure?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        This action cannot be undone. This will permanently
                        delete this post's comments, likes, views etc <br />
                        {/* Please type DELETE to confirm. */}
                      </DialogContentText>
                      {/* <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        onChange={handleDeleteConfirm}
                        type="text"
                        fullWidth
                      /> */}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={handlDelete}
                        color="primary"
                        // disabled={isDelete !== "DELETE" ? true : false}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* <Button
              variant="contained"
              color="primary"
              disabled={postid? false : true}
              size="large"
              className={classes.button}
              startIcon={<Save />}
              onClick={onSave}
            >
              {"Publish Post"}
            </Button> */}
                  <br />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box pt={10}>
                    <h5>{postText ? "Preview" : "See Preview Here"}</h5>
                    <Grid container spacing={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={10} sm={1}>
                          {/* <Avatar
                            alt={props.userDoc?.name}
                            src={props.userDoc?.profilePic}
                          /> */}
                        </Grid>
                        <Grid item xs={2} sm={11}>
                          {/* <Typography
                            variant="subtitle1"
                            color="initial"
                            style={{ fontWeight: 500 }}
                          >
                            {" "}{" "}{" "}{props.userDoc?.name}{" "}
                          </Typography>
                          <span
                            style={{ fontWeight: 200 }}
                            className="text-muted"
                          >{`      ${moment(date).fromNow()}`}</span> */}
                        </Grid>
                      </Grid>{" "}
                    </Grid>
                    {more ? (
                      <NewlineText text={postText || ""} />
                    ) : (
                      <ClampLines
                        text={postText || ""}
                        id="really-unique-id"
                        lines={5}
                        ellipsis="..."
                        moreText="Expand"
                        lessText="Collapse"
                        className="body2"
                        innerElement="p"
                        buttons={false}
                      />
                    )}
                    {postText && (
                      <Button
                        variant="text"
                        color="default"
                        onClick={showMoreLess}
                      >
                        {more ? "LESS" : "MORE"}
                      </Button>
                    )}

                    <img src={picture} width={"90%"} />
                  </Box>
                  <Footer/>
                </Grid>
              </Grid>
            </div>
          :<div>
          <Box
            style={{
              textAlign: "left",
              paddingTop: "170px",
              padding: "10%",
            }}
          >
            <Typography variant="h3" color="initial">
             {loading?"Loading":"Not Found"}
            </Typography>

            <Typography variant="body1" color="initial">
            {loading?"Please while we load the page":" Page not found or you have no access to this page"}
            
            </Typography>
            <Box pt={3}>
             {!loading && <Button
                variant="contained"
                onClick={() => history.goBack()}
                color="primary"
                disableElevation
              >
                Go Back
              </Button>}
            </Box>
          </Box>
        </div>}</Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
