import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { deepOrange, green } from "@material-ui/core/colors";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { firestore } from "../../base/firebaseConfig";
import { VerifiedUser } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    fontSize: 10,
    marginBottom: 12,
  },
}));
export default function TopChannelList(props) {
  let [channels, setChannels] = useState([]);

  const getLatest = async () => {
    let channelRef = firestore.collection("channels");
    await channelRef
      .orderBy("channelScore", "desc")
      .limit(10)
      .get()
      .then((querySnapshot) => {
        let myChannels = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          myChannels.push(doc.data());
        });
        setChannels(myChannels);
        // console.log(myChannels);
      })
      .catch((error) => {
        // console.log("Error getting documents: ", error);
      });
    // console.log(myChannels);
  };

  useEffect(() => {
    getLatest();
  }, [props]);
  var shortNumber = require("short-number");
  const classes = useStyles();
  return (
    <div>
      <Box p={1} mb={1}>
        <h3>Top Channels</h3>

      </Box>

      {channels.map((item, index) => (
        <Box key={index} display="flex" flexDirection="row">
          <Box p={1}>
            <Avatar alt={item.channelName} src={item.profilePic} />
          </Box>
          <Box p={1}>
            <Link to={`/channel/${item.id}`}>
              <Typography
                className={classes.title}
                variant="h6"
                component="h6"
                color="textSecondary"
              >
                <Box display="flex" flexDirection="row" >
                  <Box>{item.channelName}</Box>
                  <Box>
                    {item.isVerified && (
                      <span>
                        &nbsp;
                        <Tooltip title="Verified Channel">
                          <VerifiedUser style={{ width: "15px" }} />
                        </Tooltip>
                      </span>
                    )}
                  </Box>
                </Box>
              </Typography>
              <Typography
                className={classes.pos}
                variant="h6"
                color="textSecondary"
                component="h6"
              >
                {`${shortNumber(item.subscribers)} Followers`}
              </Typography>
            </Link>
          </Box>
        </Box>
      ))}
    </div>
  );
}
