import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { FieldValue, storage } from "../../base/firebaseConfig";
import MenuItem from "@material-ui/core/MenuItem";
import { firestore } from "../../base/firebaseConfig";
import MuiAlert from "@material-ui/lab/Alert";
import { useDropzone } from "react-dropzone";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

import {
  languages,
  countries,
  categories,
  permissions,
  visibitity,
} from "../constants";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import { Add, Save } from "@material-ui/icons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles((theme) => ({
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  //   maxWidth: 300,
  // },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
      marginTop: 25,
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 12,
        fontSize: 18,
        margin: theme.spacing(2),
        width: "100%",
      },
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function VideoEditForm(props) {
  const theme = useTheme();
  const classes = useStyles();

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    // window.location.href = props.location;
  };
  useEffect(() => {
   props.userDoc && checkChannel();
  }, [props.userDoc]);

  const [title, setTitle] = useState(props.videoDoc?.title);
  const [description, setDescription] = useState(props.videoDoc?.description);
  const [thumbnail, setThumbnail] = useState(props.videoDoc?.thumbnail);
  const [oldthumbnail, setOldThumbnail] = useState(props.videoDoc?.thumbnail);
  const [category, setCategory] = useState(props.videoDoc?.category);
  const [language, setLanguage] = useState(props.videoDoc?.language);
  const [country, setCountry] = useState(props.videoDoc?.country);
  const [isComments, allowComments] = useState(props.videoDoc?.isComments);
  const [isVisible, setVisible] = useState(props.videoDoc?.isVisible);
  const [open, setOpen] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [errortitle, setErrorTitle] = useState(false);
  const [newThumb, setNewThumb] = useState(null);
  const [generatedThumbnail, setGeneratedThumb] = useState(
    props.videoDoc?.generatedThumbnail
  );
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [videoid, setId] = useState(null);
  const [edited, setedited] = useState(false);

  let videoRef = firestore.collection(`videos`).doc(props.id);

  const handleTitle = (event) => {
    setTitle(event.target.value);
    setedited(true)
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
    setedited(true)
  };
  const handleCategory = (event) => {
    setCategory(event.target.value);
    setedited(true)
  };
  const handleLanguage = (event) => {
    setLanguage(event.target.value);
    setedited(true)
  };

  const handleCountry = (event) => {
    setCountry(event.target.value);
    setedited(true)
  };

  const handleComments = (event) => {
    allowComments(event.target.value);
    setedited(true)
  };

  const handleVisbibility = (event) => {
    setVisible(event.target.value);
    setedited(true)
  };

  const handleThumbChange = (thumb) => {
    setThumbnail(thumb);
    setedited(true)
  };
  let date = new Date();
  const videoData = {
    title: title,
    description: description.replace(/↵/g, "  \n  "),
    thumbnail: thumbnail,
    category: category,
    language: language,
    country: country,
    isComments: isComments,
    updated: FieldValue.serverTimestamp(),
    duration: props.duration || 404,
  };

  const checkChannel = async () => {
    //check channel exists and set data from userDoc
    let date = new Date();
    let channelData = {
      accountStatus:"active",
      isVerified:false,
      channelArt:"",
      channelScore:0,
      description:"",
      channelName: props.userDoc?.name || "Untitled Channel",
      rrealName:props.userDoc?.realName ||"",
      id: props.userDoc?.uid,
      created: FieldValue.serverTimestamp(),
      country: props.userDoc?.country || "IN",
      profilePic: props.userDoc?.profilePic || "",
      videos: 0,
      subscribers:0,
      email:props.userDoc?.email ||"",
      linkone:'',
      linktwo:'',
    };

    const channelRef = firestore.collection("channels").doc(props.userDoc?.uid);
    try {
      const res = await firestore.runTransaction(async (t) => {
        const doc = await t.get(channelRef);
        if (!doc.data()) {
          await t.set(channelRef, channelData);
          //  alert('no channel')
          return `Channel Created`;
        } else {
          //  alert('channel ond')
          throw "Channel Exists";
        }
      });

    } catch (e) {

    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    !title && setErrorTitle(true);

    videoRef
      .set(videoData, { merge: true })
      .then((docRef) => {
        alert("Successs");
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

//   const handleEdit = (event) => {
//     event.preventDefault();
//     !title && setErrorTitle(true);
//     if (title) {
//       videoRef
//         .doc(videoid)
//         .set(videoData)
//         .then(() => {
//           alert("Document successfully written!");
//           // handleClick()
//         })
//         .catch((error) => {
//           console.error("Error adding document: ", error);
//         });
//     } else console.log(videoData);
//   };

  const onDrop = useCallback((acceptedFiles) => {
    onFileChange(acceptedFiles);
  }, [props]);

  const { getRootProps, getInputProps, fileRejections, isDragActive } =
    useDropzone({
      onDrop,
      accept: "image/*",
      maxFiles: 1,
      maxFilesize: 10,
    });

  const onFileChange = async (input) => {
    const ffmpeg = createFFmpeg({
      log: true,
    });
    ffmpeg.setProgress(({ ratio }) => {
      if (ratio >= 0 && ratio <= 1) {
        setProgress(ratio * 100);
      }
      if (ratio === 1) {
        setTimeout(() => {
          setProgress(0);
        }, 1000);
      }
    });
    const file = input[0];

    const size = Math.round(file.size / (1024 * 1024));
    // The size of the file.
    if (size >= 100) {
      alert("File too Big, please select a file less than 100mb" + size);
    }
    //  else if (size < 1) {
    //   alert("File too small, please select a file greater than 1mb");
    // }
    else {

      setMessage("Loading ffmpeg-core.js");
      await ffmpeg.load();
      setMessage("Processing");
      ffmpeg.FS("writeFile", "thumb.jpg", await fetchFile(input[0]));
      await ffmpeg.run("-i", "thumb.jpg", "-vf", "crop=640:360", "thumb.jpg");
      const thumb = ffmpeg.FS("readFile", "thumb.jpg");
      let generatedThumb = URL.createObjectURL(
        new Blob([thumb.buffer], { type: "image/jpeg" })
      );

      let d = new Date();
      let time = d.getTime();
      let uid = localStorage.getItem("uid") || "public";
      let thumbref = storage.child(
        `video-thumb/${props.id}.jpg`
      );
      var imgmetadata = {
        contentType: "image/jpeg",
      };
      let thumbuploadTask = thumbref.put(thumb, imgmetadata);
      thumbuploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setMessage("Uploading " + Math.round(progress) + "%");
          switch (snapshot.state) {
            case "paused": // or 'paused'
              setMessage("Upload is paused");
              break;
            case "running": // or 'running'

              break;
          }
        },
        (error) => {
          setMessage("Upload Error");
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          thumbuploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setMessage("Uploaded");
            setedited(true)
            setNewThumb(downloadURL);
            setThumbnail(downloadURL);
          });
        }
      );
    }
  };

  return (
    <div>
      <form className={classes.root}>
        <TextField
          required
          value={title}
          error={errortitle}
          helperText={errortitle && "Required"}
          id="outlined-basic"
          label="Video Title"
          variant="outlined"
          onChange={handleTitle}
        />
        <TextField
          // required
          // error={errortitle}
          // helperText={errortitle && "Required"}
          id="outlined-multiline-static"
          label="Description"
          multiline
          value={description}
          rows={4}
          variant="outlined"
          onChange={handleDescription}
        />
        {true && (
          <div>
            <Typography variant="subtitle1" gutterBottom>
              Change Thumbnail
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <div className="thumb">
                  {newThumb ? (
                    <>
                      <img
                        src={newThumb}
                        className={thumbnail == newThumb ? "selected" : "d"}
                        onClick={() =>  handleThumbChange(newThumb)}
                        width="98%"
                      />
                      <Typography
                        variant="caption"
                        display="block"
                        onClick={() => setNewThumb(null)}
                      >
                        Change?
                      </Typography>
                    </>
                  ) : (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />

                      <div>
                        <Grid
                          className="upload-zone-small"
                          container
                          spacing={0}
                          align="center"
                          justify="center"
                          direction="column"
                        >
                          <Grid item>
                            <Add color="primary" />
                            <Typography
                              variant="caption"
                              display="block"
                              className="text-muted"
                              align="center"
                              justify="center"
                            >
                              {!isDragActive ? "Drag and drop" : "Drop Here"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                      <Typography
                        variant="caption"
                        display="block"
                        className="text-muted"
                        align="center"
                        justify="center"
                      >
                        {message + " "}
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <img
                  className={thumbnail == oldthumbnail ? "selected" : ""}
                  onClick={() =>  handleThumbChange(oldthumbnail)}
                  src={oldthumbnail}
                  width="98%"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                {generatedThumbnail && (
                  <img
                    className={
                      thumbnail == generatedThumbnail ? "selected" : ""
                    }
                    onClick={() =>  handleThumbChange(generatedThumbnail)}
                    src={generatedThumbnail}
                    width="98%"
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )}

        <div>
          <Typography variant="subtitle1" gutterBottom>
            {/* Upload Thumbnail */}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <TextField
                id="outlined-select-currency"
                select
                label="Video Category"
                value={category}
                onChange={handleCategory}
                helperText=" Please select an option"
                variant="outlined"
              >
                {categories.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
          </Grid>
        </div>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            {/* Upload Thumbnail */}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Video Country"
                value={country}
                onChange={handleCountry}
                helperText=" Please select an option"
                variant="outlined"
              >
                {countries.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Video Language"
                value={language}
                onChange={handleLanguage}
                helperText=" Please select an option"
                variant="outlined"
              >
                {languages.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </div>
        <div className="pt-3 pb-3">
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Video Visibility"
                disabled={true}
                value={isVisible}
                onChange={handleVisbibility}
                helperText=" Please select an option"
                variant="outlined"
              >
                {visibitity.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Comments"
                value={isComments}
                onChange={handleComments}
                helperText=" Please select an option"
                variant="outlined"
              >
                {permissions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </div>
        <div>
          <div className="pt-3 pb-3">
            <Button
              variant="contained"
              color="primary"
              disabled={
               edited ? false : true
              }
              size="large"
              className={classes.button}
              startIcon={<Save />}
              onClick={handleSubmit}
            >
              { "UPDATE"}
            </Button>
          </div>
        </div>
      </form>

    </div>
  );
}
