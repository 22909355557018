import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { firestore } from "../base/firebaseConfig";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import MyUploads from "../components/channel/MyUploads";
import MyPosts from "../components/channel/MyPosts";
import MyChannelComments from "../components/channel/MyChannelComments";
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import {Helmet} from "react-helmet";
import Footer from "../components/shared/Footer";
import MyWallet from "../components/channel/MyWallet";
import MyReels from "../components/channel/MyReels";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CreateHome(props) {
  let history = useHistory();
  const useStyles = makeStyles((theme) => ({
    card: {
      flexGrow: 1,
      // backgroundColor: "#E8E8E8",
      height: 200,
    },
    paper: {
      padding: theme.spacing(1),
      margin: 10,
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }));

  const matches = useMediaQuery("(min-width:850px)");
  let user = useContext(UserContext);
  const classes = useStyles();
  const [id, setId] = useState(null);
  const [videoDoc, setVideoDoc] = useState(null);
  const [exsist, setExist] = useState(true);
  const [channelDoc, setchannelDoc] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (props.userDoc?.uid) {
      let docRef = firestore.collection("channels").doc(props.userDoc.uid);
      docRef
        .get()
        .then((videoData) => {
          if (videoData.exists) {
            setchannelDoc(videoData.data());
          } else {
            setExist(false);
          }
        })
        .catch(() => {
          setExist(false);
        });
    }
  }, [props.userDoc]);

  return (
    <div className="App">
       <Helmet>
          <meta charSet="utf-8" />
          <title>{`Create Home | Millions`} </title>
          <link rel="canonical" href="https://millionsofficial.in/channel/create" />
        </Helmet>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
         {  props.userDoc ? <div style={{ padding: "10px" }}>
              <Typography variant="h5" color="initial" gutterBottom>
                Manage Channel
              </Typography>
        

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                 { channelDoc?.accountStatus !== "blocked" && <>
                 <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="Dashboard" {...a11yProps(0)} />
                    <Tab label="Videos" {...a11yProps(1)} />
                    <Tab label="Posts" {...a11yProps(2)} />
                    <Tab label="30s" {...a11yProps(3)} />
                    {/* <Tab label="Comments" {...a11yProps(4)} /> */}
                    {/* <Tab label="Analytics" {...a11yProps(5)} /> */}
                    <Tab label="Monetization" {...a11yProps(4)} />
                  </Tabs>
                  </AppBar>
                  
                  <TabPanel value={value} index={0}>
                    {matches ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <Box pt={3}>
                            {" "}
                            <Link to={"/channel/upload"}>
                              <Card
                                className={classes.card}
                                variant="outlined"
                                borderColor="primary.main"
                              >
                                <CardContent>
                                  <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                  >
                                    Create
                                  </Typography>
                                  <Typography variant="h5" component="h2">
                                    Upload a Video
                                  </Typography>
                                </CardContent>
                                <CardActions>
                                  <Button size="small"><AddIcon/></Button>
                                </CardActions>
                              </Card>
                            </Link>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Box pt={3}>
                            <Link to={"/channel/create-post"}>
                              <Card
                                className={classes.card}
                                variant="outlined"
                                borderColor="primary.main"
                              >
                                <CardContent>
                                  <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                  >
                                    Create
                                  </Typography>
                                  <Typography variant="h5" component="h2">
                                    Create Post
                                  </Typography>
                                </CardContent>
                                <CardActions>
                                <Button size="small"><AddIcon/></Button>
                                </CardActions>
                              </Card>
                            </Link>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : (
                      <div>
                        <Box
                          style={{
                            textAlign: "left",
                            paddingTop: "170px",
                            padding: "10%",
                          }}
                        >
                          <Typography variant="h3" color="initial">
                            Use App to upload
                          </Typography>

                          <Typography variant="body1" color="initial">
                            Mobile browser unsupported. Please your our app to
                            upload video.
                          </Typography>
                          <Box pt={3}>
                            <Link to={"/getapp"}>
                              <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                              >
                                Get App
                              </Button>
                            </Link>
                          </Box>
                        </Box>
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {channelDoc && <MyUploads channelDoc={channelDoc} />}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    {channelDoc && <MyPosts channelDoc={channelDoc} />}
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                  {channelDoc && <MyReels channelDoc={channelDoc}/>}
                  </TabPanel>
                  {/* <TabPanel value={value} index={4}>
                    {channelDoc && (
                      <MyChannelComments channelDoc={channelDoc} />
                    )}
                  </TabPanel> */}
              
                  <TabPanel value={value} index={4}>
                   {channelDoc && <MyWallet channelDoc={channelDoc}  userDoc={props.userDoc}/>}
                  </TabPanel>
                 </>}
             
                 {channelDoc?.accountStatus === "blocked" && (
                    <div>
                      <Box
                        style={{
                          textAlign: "left",
                          paddingTop: "170px",
                          padding: "10%",
                        }}
                      >
                        <Typography variant="h3" color="initial">
                          Channel Blocked
                        </Typography>
                        <br />
                        <Typography variant="body1" color="initial">
                          This channel is blocked due to the violation of guidelines. Kindly reached us if you think this is a mistake.
                        </Typography>
                        <Box pt={3}>
                          <Button
                            variant="contained"
                            onClick={() => history.goBack()}
                            color="primary"
                            disableElevation
                          >
                            Go Back
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  )}
                  <Footer/>
                </Grid>
              </Grid>
            </div>
          :  <div>
          <Box
            style={{
              textAlign: "left",
              paddingTop: "170px",
              padding: "10%",
            }}
          >
            <Typography variant="h3" color="initial">
              Signin required
            </Typography>

            <Typography variant="body1" color="initial">
              Please signin to view this page
            </Typography>
            <Box pt={3}>
              <Link to={"/login"}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Login
                </Button>
              </Link>
            </Box>
          </Box>
        </div>}</Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
