import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Flag } from "@material-ui/icons";
import { Button, Grid, IconButton } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { firestore, FieldValue } from "../../base/firebaseConfig";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { report } from "../constants";
import TextField from "@material-ui/core/TextField";

var isPresent = require("is-present");
export default function ReportChannel(props) {
  const [snackopen, snacksetOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [reportopen, reportsetOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [reason, setreason] = useState("");
  const [errorReason, seterrorReason] = useState(false);
  const [formOk, setformOk] = useState(false);

  const handleReason = (event) => {
    setreason(event.target.value.replace(/↵/g, "  \n  "));

    let isEmpty = isPresent(event.target.value);
    if (isEmpty) {
      setformOk(true);
    }
    seterrorReason(!isEmpty);
  };


  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const reporthandleClickOpen = () => {
    reportsetOpen(true);
  };

  const reporthandleClose = () => {
    reportsetOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClose = () => {
    setAnchorEl(null);
  };
  const handlesnackClick = () => {
    snacksetOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    snacksetOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {}, []);

  function setreport() {
    //like
    let reportData = {
      reportType: "channel",
      reason: FieldValue.arrayUnion(value),
      reportCount: FieldValue.increment(1),
      reportStatus: "Active",
       metaData: { ...props.channelDoc},
      reasonDetail: FieldValue.arrayUnion(reason),
      lastReported: FieldValue.serverTimestamp()
    };

    const reportRef = firestore.collection("reports").doc(props.channelDoc?.id);
    reportRef
      .set(reportData, { merge: true })
      .then(function () {
        reporthandleClose();
        handleOptionClose();
        alert("Reported");
      })
      .catch((error) => {
        reporthandleClose();
        handleOptionClose();
        alert("Error occured while reported");
      });
  }

  return (
    <div>
      <IconButton aria-label="" onClick={reporthandleClickOpen}>
        <Flag />
      </IconButton>
      <Dialog
        open={reportopen}
        onClose={reporthandleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you belive this content is against our guidelines, kindly report.
            Your identity will not be revealed
          </DialogContentText>
          <FormControl component="fieldset">
            {/* <FormLabel component="legend">Gender</FormLabel> */}
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChange}
            >
              {report.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.code}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <br />
          <br />
          {value && (
            <TextField
              multiline
              error={errorReason}
              lines={3}
              helperText={errorReason && "Required"}
              id="outlined-basic"
              label="Explain the reason"
              placeholder={`Why you need to report this?`}
              variant="outlined"
              value={reason}
              onChange={handleReason}
              style={{ width: "100%" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={!value} onClick={setreport} color="primary">
            Report
          </Button>
          <Button onClick={reporthandleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackopen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity="success">
          Comment deleted
        </Alert>
      </Snackbar>
    </div>
  );
}
