import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { firestore } from "../base/firebaseConfig";
import algoliasearch from "algoliasearch/lite";
import { useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
import {Helmet} from "react-helmet";
import qs from "qs";
import {
  InstantSearch,
  Hits,
  SearchBox,
  RefinementList,
  ClearRefinements,
  Panel,
  InfiniteHits,
  Menu,
  Stats,
  Pagination,
  Configure,
  PoweredBy,
} from "react-instantsearch-dom";
import Moment from "moment";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import TopChannelList from "../components/home/TopChannelList";
import ClampLines from "react-clamp-lines";
import { Link } from "react-router-dom";
import Footer from "../components/shared/Footer";
// Import components

const DEBOUNCE_TIME = 400;
const searchClient = algoliasearch(
  "FI2RMB1BA3",
  "dc951bf237218cf7a405082de430db51"
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));
const createURL = (state) => `${qs.stringify(state)}`;

const searchStateToUrl = (searchState) =>
  `${"/search/?query="}${createURL(searchState)}`;

const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

export default function SearchPage(props) {
  const matches = useMediaQuery("(min-width:850px)");
  const searchMatch = useMediaQuery("(min-width:1020px)");
  let user = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();
  const [id, setId] = useState(null);
  const [videoDoc, setVideoDoc] = useState(null);
  const [exsist, setExist] = useState(true);
  const [searchState, setSearchState] = useState(
    urlToSearchState(props.location)
  );
  const [debouncedSetState, setDebouncedSetState] = useState(null);

  const onSearchStateChange = (updatedSearchState) => {
    clearTimeout(debouncedSetState);
    let { query } = updatedSearchState;
    setDebouncedSetState(
      setTimeout(() => {
        history.push(searchStateToUrl(query));
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };

  useEffect(() => {
    searchMatch && setSearchState(urlToSearchState(props.location));
  }, [props.location, searchMatch]);
  const Hit = ({ hit }) =>
    hit.isVisible == "Public" ? (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Link to={`/watch/${hit.id}`}>
            <div
              style={{
                width: "100%",
                height: matches ? 120 : 240,
                backgroundColor: "gray",
                backgroundImage: "url(" + hit.thumbnail + ")",
              }}
              className={classes.bg}
            ></div>
          </Link>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Link to={`/watch/${hit.id}`}>
            <Typography variant="body1" color="textPrimary">
              <ClampLines
                id="rec-title"
                lines={2}
                ellipsis="..."
                moreText="Expand"
                lessText="Collapse"
                className="body2"
                innerElement="p"
                buttons={false}
                text={hit.title}
              />
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                {hit.channelName}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {`${hit.views || 0} Views • ${Moment(hit.date).fromNow()}`}
              </Typography>
            </Typography>
          </Link>
        </Grid>
      </Grid>
    ) : (
      "Video Hidden"
    );

  return (
    <div className="App">
        <Helmet>
        <meta charSet="utf-8" />
        <title> {`Search | Millions`}</title>
        <link
          rel="canonical"
          href={`https://millionsofficial.in/search`}
        />
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            <div style={{ padding: "10px" }}>
              <Typography variant="h5" color="initial" gutterBottom>
                Search
              </Typography>
              
              <InstantSearch
                searchClient={searchClient}
                indexName="videos"
                searchState={searchState}
                onSearchStateChange={onSearchStateChange}
                createURL={createURL}
              >
                <Configure hitsPerPage={15} filters="isVisible:Public" />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <div className="ais-InstantSearch">
                      {/* <h1>React InstantSearch e-commerce demo</h1>
                    location {JSON.stringify(props.location)}<br/>
                   searchstate {JSON.stringify(searchState)} 
                   */}
                      {/* searchstate {JSON.stringify(searchState.query)} */}
                      <Box pt={1} pb={1}>
                        <div
                          style={{ display: !searchMatch ? "block" : "none" }}
                        >
                          <SearchBox />
                        </div>
                      </Box>
                      <Typography
                        variant="body"
                        color="initial"
                      >{`Showing results for ${
                        searchState.query || "All Videos"
                      }`}</Typography>
                      <Stats /> <br />
                      <InfiniteHits hitComponent={Hit} />
                      <Pagination />
                      <br />
                      {/* <PoweredBy /> */}
                    </div>
                    <br />
                    {/* {JSON.stringify(videoDoc)} */}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TopChannelList />
                   <Footer/>
                  </Grid>
                </Grid>
              </InstantSearch>
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
