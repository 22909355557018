import React, { useContext, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AppLogo from "../components/shared/svgs/millions-text.svg";
import { UserContext } from "../base/UserContext";
import Grid from "@material-ui/core/Grid";
import BgImage from "../components/home/BgImage";
import CompleateProfile from "../components/auth/CompleateProfile";
import { firestore, auth } from "../base/firebaseConfig";
import Backdrop from "@material-ui/core/Backdrop";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import useMediaQuery from "@material-ui/core/useMediaQuery";

let qs = require("qs");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function EditProfile(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = React.useState(false);
  // let location = qs.parse(props.location.search, { ignoreQueryPrefix: true }).redirect;
  let user = useContext(UserContext);

  if (props.userDoc?.uid) {
    let redirct = localStorage.getItem("redirect");
    return (
      <Redirect
        to={{
          pathname: redirct || "/",
          state: {
            from: props.location,
          },
        }}
      />
    );
  } else {
    return (
      <div>
        <Grid container spacing={0}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Edit Profile | Millions</title>
            <link
              rel="canonical"
              href={`https://millionsofficial.in/edit-profile`}
            />
          </Helmet>
          <Grid item xs={0} sm={5}>
          {matches && <BgImage />}
          </Grid>

          <Grid item xs={12} sm={7}>
            <>
              {!props.loading ? (
                <div className="sign-in">
                  <img src={AppLogo} width="20%" />

                  <Typography variant="h5" color="initial" gutterBottom>
                    Log in to your account
                  </Typography>
                  <Typography variant="subtitle2" color="initial" gutterBottom>
                    New to Millions? Create an account to get started. It will
                    only take a few minutes.
                  </Typography>
                  {/* {user && user.phoneNumber || "No"} */}
                  <div className="profile-form">
                    {user ? (
                      <CompleateProfile
                        user={user || null}
                        uid={user.uid}
                        location={""}
                      />
                    ) : (
                      "Signing In"
                    )}
                  </div>
                </div>
              ) : (
                <Box pt={10} pl={5}>
                  <Typography variant="h5" color="initial" gutterBottom>
                    Redirecting.....
                  </Typography>
                  <LinearProgress color="secondary" />
                </Box>
              )}
            </>
          </Grid>
        </Grid>
      </div>
    );
  }
}
