import React, { useState, useEffect, useCallback, useContext } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Prompt } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { FieldValue, firestore, storage } from "../base/firebaseConfig";
import Paper from "@material-ui/core/Paper";
import { Avatar, Box, Button } from "@material-ui/core";
import FollowButton from "../components/channel/FollowButton";
import ChannelVideos from "../components/channel/ChannelVideos";
import TopChannelList from "../components/home/TopChannelList";
import TextField from "@material-ui/core/TextField";
import { useDropzone } from "react-dropzone";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import Typography from "@material-ui/core/Typography";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import MenuItem from "@material-ui/core/MenuItem";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  languages,
  countries,
  categories,
  permissions,
  visibitity,
} from "../components/constants";
import NewlineText from "../components/utils/NewlineText";
import ClampLines from "react-clamp-lines";
import { Save } from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../components/shared/Footer";
import Declaration from "../components/shared/Declaration";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function CreatePost(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let history = useHistory();
  let user = useContext(UserContext);
  const classes = useStyles();
  const [channelDoc, setchannelDoc] = useState(null);
  const [exsist, setExist] = useState(true);
  const [message, setMessage] = useState("");
  const [bannerMessage, setBannerMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [postText, setPostText] = useState("");
  const [oldName, setOldName] = useState("");
  const [channelArt, setChannelArt] = useState("");
  const [picture, setPicture] = useState("");
  const [edited, setEdited] = useState(false);
  const [titleEdited, setTextEdited] = useState(false);
  const [isComments, allowComments] = useState("Allowed");
  const [more, setmore] = useState(false);
  const [postid, setId] = useState("");
  const [open, setOpen] = React.useState(false);



  let postsRef = firestore.collection(`posts`);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    history.push(`/channel/create`);
  };
  let showMoreLess = () => {
    setmore(!more);
  };
  useEffect(() => {
    checkChannel();
    let getRef = firestore.collection(`posts`).doc();
    setId(getRef.id);
    if (props.userDoc?.uid) {
      let docRef = firestore.collection("channels").doc(props.userDoc.uid);
      docRef
        .get()
        .then((videoData) => {
          if (videoData.exists) {
            setchannelDoc(videoData.data());
          } else {
            setExist(false);
          }
        })
        .catch(() => {
          setExist(false);
        });
    }
  }, [props.userDoc]);

  const checkChannel = async () => {
    //check channel exists and set data from userDoc
    let date = new Date();
    let channelData = {
      accountStatus: "active",
      isVerified: false,
      channelArt: "",
      channelScore: 0,
      description: "",
      channelName: props.userDoc?.name || "Untitled Channel",
      realName: props.userDoc?.realName || "",
      id: props.userDoc?.uid,
      created: FieldValue.serverTimestamp(),
      country: props.userDoc?.country || "IN",
      profilePic: props.userDoc?.profilePic || "",
      videos: 0,
      subscribers: 0,
      email: props.userDoc?.email || "",
      linkone: '',
      linktwo: '',
    };
    const channelRef = firestore.collection("channels").doc(props.userDoc?.uid);
    if (props.userDoc?.uid) {
      try {
        const res = await firestore.runTransaction(async (t) => {
          const doc = await t.get(channelRef);
          if (!doc.data()) {
            await t.set(channelRef, channelData);
            //  alert('no channel')
            return `Channel Created`;
          } else {
            //  alert('channel ond')
            throw "Channel Exists";
          }
        });

      } catch (e) {

      }
    }
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileChange(acceptedFiles);
    },
    [props]
  );
  const { isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
    maxFilesize: 10,
  });

  const onFileChange = async (input) => {
    const ffmpeg = createFFmpeg({
      log: true,
    });
    ffmpeg.setProgress(({ ratio }) => {
      if (ratio >= 0 && ratio <= 1) {
        setProgress(ratio * 100);
      }
      if (ratio === 1) {
        setTimeout(() => {
          setProgress(0);
        }, 1000);
      }
    });
    const file = input[0];

    const size = Math.round(file.size / (1024 * 1024));
    // The size of the file.
    if (size >= 5) {
      alert("File too Big, please select a file less than 100mb" + size);
    }
    //  else if (size < 1) {
    //   alert("File too small, please select a file greater than 1mb");
    // }
    else {
      // setMessage("Loading ffmpeg-core.js");
      // await ffmpeg.load();
      // setMessage("Processing");
      // ffmpeg.FS("writeFile", "thumb.jpg", await fetchFile(input[0]));
      // await ffmpeg.run("-i", "thumb.jpg", "-vf", "crop=360:360", "thumb.jpg");
      // const thumb = ffmpeg.FS("readFile", "thumb.jpg");
      // let generatedThumb = URL.createObjectURL(
      //   new Blob([thumb.buffer], { type: "image/jpeg" })
      // );

      let d = new Date();
      let time = d.getTime();
      let uid = localStorage.getItem("uid") || "public";
      let thumbref = storage.child(
        `posts/${postid}.jpg`
      );
      var imgmetadata = {
        contentType: "image/jpeg",
      };
      let thumbuploadTask = thumbref.put(file, imgmetadata);
      thumbuploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setMessage("Uploading " + Math.round(progress) + "%");
          switch (snapshot.state) {
            case "paused": // or 'paused'
              setMessage("Upload is paused");
              break;
            case "running": // or 'running'

              break;
          }
        },
        () => {
          setMessage("Upload Error");
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          thumbuploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setMessage("Uploaded");
            setEdited(true);
            setPicture(downloadURL);
          });
        }
      );
    }
  };
  const setText = (event) => {
    setEdited(true);
    setPostText(event.target.value);
  };
  const handleComments = (event) => {
    allowComments(event.target.value);
  };
  const onSave = () => {

    let date = new Date();
    const postData = {
      title: `Post by ${props.userDoc?.name}`,
      description: postText.replace(/↵/g, "  \n  ") || "",
      photoSrc: picture || "",
      category: "post",
      language: "English",
      country: props.userDoc?.country,
      isComments: isComments,
      isVisible: "Public",
      channelId: props.userDoc.uid,
      isVerified: props.userDoc.isVerified || false,
      // channel:firestore.doc(`/channels/${props.userDoc.uid}`) ,
      profilePic: props.userDoc?.profilePic || "",
      channelName: props.userDoc.name,
      subscribers: 0,
      comments: 0,
      views: 0,
      likes: 0,
      disLikes: 0,
      postScore: 0,
      date: FieldValue.serverTimestamp(),
      id: postid,
    };

    postsRef
      .doc(postid)
      .set(postData)
      .then(() => {
        handleClickOpen();
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });


  };
  let date = new Date();
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create Post | Millions</title>
        <link
          rel="canonical"
          href="https://millionsofficial.in/channel/create-post"
        />
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            {matches ? (
              <div style={{ padding: "10px" }}>

                {channelDoc?.accountStatus !== "blocked" && <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    {props.userDoc && (
                      <>
                        <Typography variant="h5" color="initial" gutterBottom>
                          Create Post
                        </Typography>

                      </>
                    )}
                    <div style={{ width: "100%" }}>
                      {edited && (
                        <Alert severity="warning">
                          <AlertTitle>Warning</AlertTitle>
                          Please click save button to keep your edits
                          {/* <strong>check it out!</strong> */}
                        </Alert>
                      )}
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        pt={1}
                        mt={1}
                        mb={1}
                      >
                        <Box pt={1} width={"90%"}>
                          <h5>Upload an image</h5>
                        </Box>
                        <Box pt={1}>
                          {edited && (
                            <Button
                              variant="contained"
                              disabled={edited ? false : true}
                              onClick={onSave}
                              color="primary"
                            >
                              Save
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </div>
                    <Dropzone
                      onDrop={onFileChange}
                      accept="image/*"
                      maxSize={5242880}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} style={{ color: "#000" }}>
                          <input {...getInputProps()} />

                          <div>
                            <Grid
                              className="upload-zone-small"
                              container
                              spacing={0}
                              align="center"
                              justify="center"
                              direction="column"
                            >
                              <Grid item>
                                {/* <Add color="primary" /> */}
                                <Typography
                                  variant="caption"
                                  display="block"
                                  className="text-muted"
                                  align="center"
                                  justify="center"
                                >
                                  {!isDragActive
                                    ? "Drag and drop"
                                    : "Drop Here"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                          <Typography
                            variant="caption"
                            display="block"
                            className="text-muted"
                            align="center"
                            justify="center"
                          >
                            {message + " "}
                          </Typography>
                        </div>
                      )}
                    </Dropzone>
                    <Declaration scope={"creating a post"} margin={10} />
                    <Box mt={3} mb={1} style={{ width: "100%" }} mb={3}>
                      <TextField
                        //   required
                        style={{ width: "100%" }}
                        multiline
                        rows={4}
                        id="outlined-basic"
                        //   defaultValue={channelName}
                        label="Write a post"
                        variant="outlined"
                        onChange={setText}
                      />
                    </Box>

                    <Grid item xs={6} sm={3}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Comments"
                        value={isComments}
                        onChange={handleComments}
                        helperText=" Please select an option"
                        variant="outlined"
                      >
                        {permissions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {/* <Button
              variant="contained"
              color="primary"
              disabled={postid? false : true}
              size="large"
              className={classes.button}
              startIcon={<Save />}
              onClick={onSave}
            >
              {"Publish Post"}
            </Button> */}
                    <br />

                    <Dialog
                      open={open}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        {"🎉 Post Published"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                          <Box pt={3} pb={3}>
                            <div>
                              Post Published. You can share this link{" "}
                              <a
                                href={`https://millionsofficial.in/posts/${postid}`}
                              >{`https://millionsofficial.in/posts/${postid}`}</a>{" "}
                              on social media
                            </div>
                          </Box>
                          <br />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Close
                        </Button>
                        <a href={`/posts/${postid}`}>
                          <Button
                            onClick={handleClose}
                            disabled={false}
                            color="primary"
                          >
                            View Post
                          </Button>
                        </a>
                      </DialogActions>
                    </Dialog>

                    {/* {`Published with ${postid}`} */}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box pt={10}>
                      <h5>{postText ? "Preview" : "See Preview Here"}</h5>
                      <Grid container spacing={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={10} sm={1}>
                            {/* <Avatar
                            alt={props.userDoc?.name}
                            src={props.userDoc?.profilePic}
                          /> */}
                          </Grid>
                          <Grid item xs={2} sm={11}>
                            {/* <Typography
                            variant="subtitle1"
                            color="initial"
                            style={{ fontWeight: 500 }}
                          >
                            {" "}{" "}{" "}{props.userDoc?.name}{" "}
                          </Typography>
                          <span
                            style={{ fontWeight: 200 }}
                            className="text-muted"
                          >{`      ${moment(date).fromNow()}`}</span> */}
                          </Grid>
                        </Grid>{" "}
                      </Grid>
                      {more ? (
                        <NewlineText text={postText || ""} />
                      ) : (
                        <ClampLines
                          text={postText || ""}
                          id="really-unique-id"
                          lines={5}
                          ellipsis="..."
                          moreText="Expand"
                          lessText="Collapse"
                          className="body2"
                          innerElement="p"
                          buttons={false}
                        />
                      )}
                      {postText && (
                        <Button
                          variant="text"
                          color="default"
                          onClick={showMoreLess}
                        >
                          {more ? "LESS" : "MORE"}
                        </Button>
                      )}

                      <img src={picture} width={"90%"} />
                    </Box>
                    <Footer />
                  </Grid>
                </Grid>
                }

                {channelDoc?.accountStatus === "blocked" && (
                  <div>
                    <Box
                      style={{
                        textAlign: "left",
                        paddingTop: "170px",
                        padding: "10%",
                      }}
                    >
                      <Typography variant="h3" color="initial">
                        Channel Blocked
                      </Typography>
                      <br />
                      <Typography variant="body1" color="initial">
                        This channel is blocked due to the violation of guidelines.
                        Kindly reached us if you think this is a mistake.
                      </Typography>
                      <Box pt={3}>
                        <Button
                          variant="contained"
                          onClick={() => history.goBack()}
                          color="primary"
                          disableElevation
                        >
                          Go Back
                        </Button>
                      </Box>
                    </Box>
                  </div>
                )}

              </div>
            ) : (
              <div>
                <Box
                  style={{
                    textAlign: "left",
                    paddingTop: "170px",
                    padding: "10%",
                  }}
                >
                  <Typography variant="h3" color="initial">
                    Use App to upload
                  </Typography>

                  <Typography variant="body1" color="initial">
                    Mobile browser unsupported. Please use your our app to create
                    post
                  </Typography>
                  <Box pt={3}>
                    <Link to={"/getapp"}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                      >
                        Get App
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
