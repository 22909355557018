import React from "react";
import Box from "@material-ui/core/Box";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import { Explore, Photo, VideoCall, VideoLibrary, ViewCarousel } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

export default function SideBar(props) {
 
  return (
    <>
      {props.matches && (
        <div
          style={{
            marginTop:100,
            position:"fixed",
            height:"100vh",
            paddingLeft:12,
            // width: props.matches ? "120px" : 0,
            // backgroundColor: "red",
            alignItems: "center",
            minHeight: "87vh",
          }}
        >
          <Link to={'/'}>
          <div className="sidebar-item">
            <div className="siebar-item-inner sidebar-item-selected">
              <Box
                display="flex"
                width={"100%"}
                height={20}
                alignItems="center"
                justifyContent="center"
              >
                <HomeIcon style={{ fontSize: 25,  }} color={'secondary'}/>
                <br />
              </Box>
              <Typography variant="caption" display="block"  color={'secondary'}>
                Home
              </Typography>
            </div>
          </div>
          </Link>
          <Link to={'/explore'}>
          <div className="sidebar-item">
            <div className="siebar-item-inner sidebar-item-inactive">
              <Box
                display="flex"
                width={"100%"}
                height={20}
                alignItems="center"
                justifyContent="center"
              >
                <Explore style={{ fontSize: 25,  }}  color={'secondary'}/>
                <br />
              </Box>
              <Typography variant="caption" display="block"  color={'secondary'}>
                Explore
              </Typography>
            </div>
          </div>
          </Link>
          <Link to={'/following'}>
          <div className="sidebar-item">
            <div className="siebar-item-inner sidebar-item-inactive">
              <Box
                display="flex"
                width={"100%"}
                height={20}
                alignItems="center"
                justifyContent="center"
              >
                <VideoLibrary style={{ fontSize: 25,  }}  color={'secondary'} />
                <br />
              </Box>
              <Typography variant="caption" display="block"  color={'secondary'}>
                Following
              </Typography>
            </div>
          </div>
          </Link>
          <Link to={'/posts'}>
          <div className="sidebar-item">
            <div className="siebar-item-inner sidebar-item-inactive">
              <Box
                display="flex"
                width={"100%"}
                height={20}
                alignItems="center"
                justifyContent="center"
              >
                <Photo style={{ fontSize: 25}}  color={'secondary'} />
                <br />
              </Box>
              <Typography variant="caption" display="block"  color={'secondary'}>
                Posts
              </Typography>
            </div>
          </div>
          </Link>
          <Link to={'/channel/create'}>
         <div className="sidebar-item">
            <div className="siebar-item-inner sidebar-item-inactive">
              <Box
                display="flex"
                width={"100%"}
                height={20}
                alignItems="center"
                justifyContent="center"
              >
                <VideoCall style={{ fontSize: 25,  }}  color={'secondary'} />
                <br />
              </Box>
              <Typography variant="caption" display="block"  color={'secondary'}>
                Create
              </Typography>
            </div>
          </div>
          </Link>
        </div>
      )}
     
    </>
  );
}
