import React, { useState, useEffect, useCallback, useContext } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Prompt } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import { FieldValue, firestore, storage } from "../base/firebaseConfig";
import Paper from "@material-ui/core/Paper";
import { Avatar, Box, Button } from "@material-ui/core";
import TopChannelList from "../components/home/TopChannelList";
import TextField from "@material-ui/core/TextField";
import { useDropzone } from "react-dropzone";
import { createFFmpeg } from "@ffmpeg/ffmpeg";
import Typography from "@material-ui/core/Typography";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import validUrlUtf8 from "valid-url-utf8";
import Footer from "../components/shared/Footer";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";

var isPresent = require("is-present");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    minHeight: "200px",
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  large: {
    width: 80,
    height: 80,
  },
  overlay: {
    color: theme.palette.text.secondary,
    minHeight: "200px",
    backgroundColor: "RGBA(22,48,87,0.7)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function ChannelEdit(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let history = useHistory();
  let user = useContext(UserContext);
  const classes = useStyles();
  const [channelDoc, setchannelDoc] = useState(null);
  const [exsist, setExist] = useState(true);
  const [message, setMessage] = useState("");
  const [bannerMessage, setBannerMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [channelName, setChannelName] = useState("");
  const [oldName, setOldName] = useState("");
  const [channelArt, setChannelArt] = useState("");
  const [profilePic, setProfilePicture] = useState("");
  const [edited, setEdited] = useState(false);
  const [titleEdited, setTitleEdited] = useState(false);
  const [description, setDescription] = useState("");
  const [linkone, setlinkone] = useState("");
  const [linktwo, setlinktwo] = useState("");
  const [linkthree, setlinkthree] = useState("")
  const [linkfour, setlinkfour] = useState("")
  const [linkfive, setlinkfive] = useState("")
  const [errorTitle, seterrorTitle] = useState(false);
  const [errorURLone, seterrorURLone] = useState(false);
  const [errorURLtwo, seterrorURLtwo] = useState(false);
  const [errorURLthree, seterrorURLthree] = useState(false);
  const [errorURLfour, seterrorURLfour] = useState(false);
  const [errorURLfive, seterrorURLfive] = useState(false);
  const [isUploading, setisUploading] = useState(false);
  const [isCompleated, setisCompleated] = useState(false);
  const [open, setOpen] = useState(false);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    setOpen(false);
    // alert("hi")
    history.push(`/channel/${props.userDoc.uid}`);

  };

  useEffect(() => {
    if (props.userDoc?.uid) {
      let docRef = firestore.collection("channels").doc(props.userDoc.uid);
      docRef
        .get()
        .then((videoData) => {
          if (videoData.exists) {
            setchannelDoc(videoData.data());
            setChannelName(videoData.data().channelName);
            setOldName(videoData.data().channelName);
            setProfilePicture(videoData.data().profilePic);
            setChannelArt(videoData.data().channelArt);
            setDescription(videoData.data().description);
            setlinkone(videoData.data().linkone);
            setlinktwo(videoData.data().linktwo);
            setlinkthree(videoData.data().linkthree);
            setlinkfour(videoData.data().linkfour);
            setlinkfive(videoData.data().linkfive);
          } else {
            setExist(false);
          }
        })
        .catch(() => {
          setExist(false);
        });
    }
  }, [props]);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  const handleEndConcert = () => {
    if (edited) {
      alert("Are you sure to leave without saving?");
    }
  };

  const setTitle = (event) => {
    setEdited(true);
    setChannelName(event.target.value);
    seterrorTitle(!isPresent(channelName));
  };

  const handleLinkOne = (event) => {
    setEdited(true);
    setlinkone(event.target.value);
    seterrorURLone(!validUrlUtf8(event.target.value));
  };

  const handleLinkTwo = (event) => {
    setEdited(true);
    setlinktwo(event.target.value);
    seterrorURLtwo(!validUrlUtf8(event.target.value));
  };
  const handleLinkThree = (event) => {
    setEdited(true);
    setlinkthree(event.target.value);
    seterrorURLthree(!validUrlUtf8(event.target.value));
  };
  const handleLinkFour = (event) => {
    setEdited(true);
    setlinkfour(event.target.value);
    seterrorURLfour(!validUrlUtf8(event.target.value));
  };
  const handleLinkFive = (event) => {
    setEdited(true);
    setlinkfive(event.target.value);
    seterrorURLfive(!validUrlUtf8(event.target.value));
  };

  const handleDescription = (event) => {
    setEdited(true);
    setDescription(event.target.value);
  };
  const handleArt = (input) => {
    setisUploading(true);
    setBannerMessage("Upload Started");
    // console.log(file);
    const file = input[0];

    const size = Math.round(file.size / (1024 * 1024));
    // The size of the file.
    if (size >= 5) {
      alert("File too Big, please select a file less than 100mb" + size);
    }
    //  else if (size < 1) {
    //   alert("File too small, please select a file greater than 1mb");
    // }
    else {
      // setMessage("Loading ffmpeg-core.js");
      // await ffmpeg.load();
      // setMessage("Processing");
      // ffmpeg.FS("writeFile", "thumb.jpg", await fetchFile(input[0]));
      // await ffmpeg.run("-i", "thumb.jpg", "-vf", "crop=360:360", "thumb.jpg");
      // const thumb = ffmpeg.FS("readFile", "thumb.jpg");
      // let generatedThumb = URL.createObjectURL(
      //   new Blob([thumb.buffer], { type: "image/jpeg" })
      // );
      // console.log(generatedThumb);
      let d = new Date();
      let time = d.getTime();
      let uid = props.userDoc.uid || "public";
      let thumbref = storage.child(
        `channel-banner/ ${uid} .jpg`
      );
      var imgmetadata = {
        contentType: "image/jpeg",
      };
      let thumbuploadTask = thumbref.put(file, imgmetadata);
      thumbuploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setBannerMessage("Uploading " + Math.round(progress) + "%");
          switch (snapshot.state) {
            case "paused": // or 'paused'
              setBannerMessage("Upload is paused");
              break;
            case "running": // or 'running'
              // console.log("Upload is running");
              break;
          }
        },
        (error) => {
          setBannerMessage("Upload Error");
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          thumbuploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setMessage("Uploaded");
            setChannelArt(downloadURL);
            setEdited(true);
            setisUploading(false);
          });
        }
      );
    }
    // setChannelArt(event.target.value);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileChange(acceptedFiles);
    },
    [props]
  );

  const { getRootProps, getInputProps, fileRejections, isDragActive } =
    useDropzone({
      onDrop,
      accept: "image/*",
      maxFiles: 1,
      maxFilesize: 10,
    });

  const onFileChange = async (input) => {
    setisUploading(true);
    const ffmpeg = createFFmpeg({
      log: true,
    });
    ffmpeg.setProgress(({ ratio }) => {
      if (ratio >= 0 && ratio <= 1) {
        setProgress(ratio * 100);
      }
      if (ratio === 1) {
        setTimeout(() => {
          setProgress(0);
        }, 1000);
      }
    });
    const file = input[0];

    const size = Math.round(file.size / (1024 * 1024));
    // The size of the file.
    if (size >= 5) {
      alert("File too Big, please select a file less than 100mb" + size);
    }
    //  else if (size < 1) {
    //   alert("File too small, please select a file greater than 1mb");
    // }
    else {
      // setMessage("Loading ffmpeg-core.js");
      // await ffmpeg.load();
      // setMessage("Processing");
      // ffmpeg.FS("writeFile", "thumb.jpg", await fetchFile(input[0]));
      // await ffmpeg.run("-i", "thumb.jpg", "-vf", "crop=360:360", "thumb.jpg");
      // const thumb = ffmpeg.FS("readFile", "thumb.jpg");
      // let generatedThumb = URL.createObjectURL(
      //   new Blob([thumb.buffer], { type: "image/jpeg" })
      // );
      // console.log(generatedThumb);
      let d = new Date();
      let time = d.getTime();
      let uid = props.userDoc.uid || d;
      let thumbref = storage.child(
        `channel-dp/ ${uid}.jpg`
      );
      var imgmetadata = {
        contentType: "image/jpeg",
      };
      let thumbuploadTask = thumbref.put(file, imgmetadata);
      thumbuploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setMessage("Uploading " + Math.round(progress) + "%");
          switch (snapshot.state) {
            case "paused": // or 'paused'
              setMessage("Upload is paused");
              break;
            case "running": // or 'running'
              // console.log("Upload is running");
              break;
          }
        },
        (error) => {
          setMessage("Upload Error");
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          thumbuploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setMessage("Uploaded");
            setProfilePicture(downloadURL);
            setEdited(true);
            setisUploading(false);
          });
        }
      );
    }
  };

  const onSave = async () => {
    const channelData = {
      channelName: channelName || "Untitled Channel",
      channelArt: channelArt || "",
      profilePic: profilePic || "",
      dateChanged: FieldValue.serverTimestamp(),
      description: description.replace(/↵/g, "  \n  ") || "",
      linkone: linkone || "",
      linktwo: linktwo || "",
      linkthree: linkthree || "",
      linkfour: linkfour || "",
      linkfive: linkfive || "",
    };

    let isPresentTitle = isPresent(channelName);
    if (!isPresentTitle) {
      alert("Channel Name Cant be empty");
      seterrorTitle(!isPresentTitle);
    } else {
      let promises = [];
      setisCompleated(false);
      handleClickOpen();

      const channelRef = firestore
        .collection("channels")
        .doc(props.userDoc.uid);

      await channelRef
        .set(channelData, { merge: true })
        .then((docRef) => {
          promises.push(docRef);
        })
        .catch((error) => {
          alert("Error adding document");
        });
      setisCompleated(true);
      return Promise.all(promises);
    }
  };
  var shortNumber = require("short-number");
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Channel | Millions</title>
        <link rel="canonical" href="https://millionsofficial.in/channel/edit" />
      </Helmet>
      <Prompt
        when={edited}
        message={() => "Are you sure you want to leave this page?"}
      />
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            <div style={{ padding: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                  {channelDoc && (
                    <>
                      <div className={classes.root}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              color="initial"
                              gutterBottom
                            >
                              Edit Channel
                            </Typography>

                            <Typography variant="subtitle2" color="initial">
                              Preview
                            </Typography>
                            <Paper
                              style={{
                                backgroundImage: "url(" + channelArt + ")",
                              }}
                              className={classes.paper}
                            >
                              <Box className={classes.overlay}>
                                <div>
                                  <div>
                                    <Box
                                      alignItems="center"
                                      display="flex"
                                      flexDirection="row"
                                      style={{ width: "100%", height: 200 }}
                                      p={1}
                                      m={1}
                                    >
                                      <Box p={1}>
                                        <Avatar
                                          alt={channelName}
                                          src={profilePic}
                                          className={classes.large}
                                        />
                                      </Box>
                                      <Box p={1}>
                                        <h2
                                          className={"m2"}
                                          style={{ color: "white" }}
                                        >
                                          {channelName}
                                        </h2>
                                        <span
                                          className={"m2"}
                                          style={{ color: "white" }}
                                        >{`${shortNumber(
                                          channelDoc.subscribers || 0
                                        )} Followers`}</span>
                                      </Box>
                                      {/* <Box p={1}>Item 3</Box> */}
                                    </Box>
                                  </div>
                                </div>
                              </Box>
                            </Paper>
                            <div style={{ width: "100%" }}>
                              {edited && (
                                <Alert severity="warning">
                                  <AlertTitle>Warning</AlertTitle>
                                  Please click save button to keep your edits
                                  {/* <strong>check it out!</strong> */}
                                </Alert>
                              )}
                              <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="row"
                                pt={1}
                                mt={1}
                                mb={1}
                              >
                                <Box pt={1} width={"90%"}>
                                  <h5>Change Channel Name</h5>
                                </Box>
                                <Box pt={1}>
                                  {edited && (
                                    <Button
                                      disabled={
                                        errorURLone ||
                                        errorURLtwo ||
                                        isUploading
                                      }
                                      variant="contained"
                                      onClick={onSave}
                                      color="primary"
                                    >
                                      Save
                                    </Button>
                                  )}
                                  {}
                                </Box>
                              </Box>
                            </div>

                            <Box mt={1} mb={1} style={{ width: "100%" }} mb={3}>
                              <TextField
                                required
                                style={{ width: "100%" }}
                                error={errorTitle}
                                helperText={errorTitle && "Required"}
                                id="outlined-basic"
                                defaultValue={channelName}
                                label="Channel Name"
                                variant="outlined"
                                onChange={setTitle}
                                value={channelName}
                              />
                            </Box>
                            <Typography
                              variant="caption"
                              display="block"
                              className="text-muted"
                            >
                              Choose a channel name that represents you and your
                              content.
                            </Typography>

                            <Box mt={5}>
                              <h5>Change Channel Profile Picture</h5>
                              <Grid container spacing={3}>
                                <Grid item xs={3}>
                                  <Dropzone
                                    onDrop={onFileChange}
                                    accept="image/*"
                                    maxSize={5242880}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} />

                                        <div>
                                          <Grid
                                            className="upload-zone-small"
                                            container
                                            spacing={0}
                                            align="center"
                                            justify="center"
                                            direction="column"
                                          >
                                            <Grid item>
                                              {/* <Add color="primary" /> */}
                                              <Typography
                                                variant="caption"
                                                display="block"
                                                className="text-muted"
                                                align="center"
                                                justify="center"
                                              >
                                                {!isDragActive
                                                  ? "Drag and drop"
                                                  : "Drop Here"}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </div>
                                        <Typography
                                          variant="caption"
                                          display="block"
                                          className="text-muted"
                                          align="center"
                                          justify="center"
                                          color={"primary"}
                                        >
                                          {message + " "}
                                        </Typography>
                                      </div>
                                    )}
                                  </Dropzone>
                                </Grid>
                                <Grid item xs={9}>
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    className="text-muted"
                                  >
                                    It’s recommended to use a picture that’s at
                                    least 98 x 98 pixels and 4MB or less. Use a
                                    PNG or GIF (no animations) file. Make sure
                                    your picture follows the Millions Community
                                    Guidelines.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box mt={5}>
                              <h5>Change Channel Profile Banner</h5>
                              <Grid container spacing={3}>
                                <Grid item xs={3}>
                                  <Dropzone
                                    onDrop={handleArt}
                                    accept="image/*"
                                    maxSize={5242880}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} />

                                        <div>
                                          <Grid
                                            className="upload-zone-small"
                                            container
                                            spacing={0}
                                            align="center"
                                            justify="center"
                                            direction="column"
                                          >
                                            <Grid item>
                                              {/* <Add color="primary" /> */}
                                              <Typography
                                                variant="caption"
                                                display="block"
                                                className="text-muted"
                                                align="center"
                                                justify="center"
                                              >
                                                {!isDragActive
                                                  ? "Drag and drop"
                                                  : "Drop Here"}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </div>
                                        <Typography
                                          variant="caption"
                                          display="block"
                                          className="text-muted"
                                          align="center"
                                          justify="center"
                                          color={"primary"}
                                        >
                                          {bannerMessage + " "}
                                        </Typography>
                                      </div>
                                    )}
                                  </Dropzone>
                                </Grid>
                                <Grid item xs={9}>
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    className="text-muted"
                                  >
                                    For the best results on all devices, use an
                                    image that’s at least 2048 x 1152 pixels and
                                    6MB or less.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box mt={5}>
                              <Box pt={1} width={"90%"}>
                                <h5>About Channel</h5>
                              </Box>
                              <TextField
                                // required
                                // error={errortitle}
                                // helperText={errortitle && "Required"}
                                id="outlined-multiline-static"
                                label="Description"
                                multiline
                                rows={4}
                                style={{ width: "100%" }}
                                defaultValue=""
                                variant="outlined"
                                onChange={handleDescription}
                                value={description}
                              />
                            </Box>
                            <Box mt={5}>
                              <Box pt={1} width={"90%"}>
                                <h5>Channel featured links</h5>
                              </Box>
                              <TextField
                                style={{ width: "100%" }}
                                id="outlined-basic"
                                error={errorURLone}
                                helperText={errorURLone && "Enter a valid url"}
                                placeholder={"https://instagram.com"}
                                label="Link One"
                                variant="outlined"
                                onChange={handleLinkOne}
                                value={linkone}
                              />
                              <br />
                            </Box>
                            <Box mt={2}>
                              <TextField
                                style={{ width: "100%" }}
                                id="outlined-basic"
                                error={errorURLtwo}
                                helperText={errorURLtwo && "Enter a valid url"}
                                placeholder={"https://facebook.com"}
                                label="Link Two"
                                variant="outlined"
                                onChange={handleLinkTwo}
                                value={linktwo}
                              />
                              <br />
                            </Box>
                            <Box mt={2}>
                              <TextField
                                style={{ width: "100%" }}
                                id="outlined-basic"
                                error={errorURLthree}
                                helperText={errorURLthree && "Enter a valid url"}
                                placeholder={"https://link.com"}
                                label="Link Three"
                                variant="outlined"
                                onChange={handleLinkThree}
                                value={linkthree}
                              />
                              <br />
                            </Box>
                            <Box mt={2}>
                              <TextField
                                style={{ width: "100%" }}
                                id="outlined-basic"
                                error={errorURLfour}
                                helperText={errorURLfour&& "Enter a valid url"}
                                placeholder={"https://link.com"}
                                label="Link Four"
                                variant="outlined"
                                onChange={handleLinkFour}
                                value={linkfour}
                              />
                              <br />
                            </Box>
                            <Box mt={2}>
                              <TextField
                                style={{ width: "100%" }}
                                id="outlined-basic"
                                error={errorURLfive}
                                helperText={errorURLfive && "Enter a valid url"}
                                placeholder={"https://link.com"}
                                label="Link Five"
                                variant="outlined"
                                onChange={handleLinkFive}
                                value={linkfive}
                              />
                              <br />
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                  <br />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TopChannelList />
                  <Footer />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          // keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {isCompleated ? "🎉 Channel Edited" : "Please Wait"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {!isCompleated && <LinearProgress />}
              <Box pt={3} pb={3} style={{ width: "70vh" }}>
                {isCompleated ? (
                  <div>Channel updated.</div>
                ) : (
                  `Please wait until we update channel everywhere. Dont close this screen. Otherwise unexpected bad things will happen`
                )}
              </Box>
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              disabled={!isCompleated}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <br />
    </div>
  );
}


