import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {
  Avatar,
  Typography,
  IconButton,
  Button,
  Tooltip,
} from "@material-ui/core";
import { FieldValue, firestore } from "../../base/firebaseConfig";
import {
  Favorite,
  FavoriteBorder,
  Flag,
  Share,
  VerifiedUser,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import FollowButton from "../channel/FollowButton";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { RWebShare } from "react-web-share";
import ReportVideo from "./ReportVideo";

var format = require("format-duration");
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: "yellow",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function VideoActions(props) {
  const [isLiked, setisLiked] = useState(false);
  const [likeDoc, setlikeDoc] = useState([]);
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:850px)");
  const followRef = firestore
    .collection("video-likes")
    .doc(props.userDoc?.uid + "_" + props.id);

  function disLike() {
    if (props.userDoc?.uid) {
      let likeData = {
        date: FieldValue.serverTimestamp(),
        liked: false,
      };
      followRef
        .update(likeData)
        .then(() => {
          setisLiked(false);
        })
        .catch((error) => {
          alert("Error removing document");
        });
    } else {
      alert("Please Signin");
    }
  }

  function setlike() {
    if (props.userDoc?.uid) {
      let date = new Date();
      let link = `${props.source == "videos" ? "watch" : "posts"}/${props.id}`;
      let likeData = {
        channel: props.videoDoc?.channelId,
        follower: props.userDoc?.uid,
        video: props.id,
        date: FieldValue.serverTimestamp(),
        liked: true,
        source: props.source || "",
        link: link,
      };
      followRef
        .set(likeData)
        .then(function () {
          setisLiked(true);
        })
        .catch((error) => {
          alert("Error occured");
        });
    } else {
      alert("Sign In");
    }
  }

  useEffect(() => {
    followRef
      .get()
      .then((videoData) => {
        if (videoData.exists) {
          setisLiked(videoData.data().liked || false);
        } else {
          setisLiked(false);
        }
      })
      .catch(() => {
        setisLiked(false);
      });
      console.log(props.videoDoc?.channelId);
  }, []);
  var shortNumber = require("short-number");
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Box
            display="flex"
            alignItems="center"
            pl={1}
            // m={1}
            bgcolor="background.paper"
            css={{ height: 50 }}
          >
          <Typography variant="caption" color="initial">
          <span className={"text-muted"}>
              <span>
                {props.source == "videos" &&
                  `${props.videoDoc?.views} Views • `}
              </span>
              {` ${props.videoDoc?.likes}  likes • `}
              {(
               props.source == "videos" && <span>
                  {" "}
                  {`${moment(props.videoDoc?.date.toMillis()).fromNow()} • `}
                </span>
              )}
              <span></span>
              <span>
                {props.source == "posts" &&
                  `${props.videoDoc?.comments} Comments`}
              </span>
            </span>
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            bgcolor="background.paper"
            css={{ height: 50 }}
          >
            <Box p={1}>
              {isLiked ? (
                <>
                  <IconButton aria-label="" onClick={disLike}>
                    <Favorite color="primary" />
                  </IconButton>
                </>
              ) : (
                <IconButton aria-label="" onClick={setlike}>
                  <FavoriteBorder />
                </IconButton>
              )}
            </Box>
            <Box p={1}>
              <RWebShare
                data={{
                  text: ` ${
                    props.videoDoc?.description ||
                    "Hey checkout this item on  Millions channel"
                  } - Millions`,
                  url:
                    props.videoDoc.shortLink ||
                    `https://millionsofficial.in/posts/${props.id}`,
                  title: ` ${props.videoDoc?.title} - Millions`,
                }}
                // onClick={() => console.log("shared successfully!")}
              >
                <IconButton aria-label="" disabled={props?.isAdPlaying || false}>
                  <Share />
                </IconButton>
              </RWebShare>
            </Box>
            <Box p={1}>
              <ReportVideo source={props.source} videoDoc={props.videoDoc} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {props.channelDoc && (
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Box
              display="flex"
              alignItems="center"
              // p={1}
              // m={1}
              bgcolor="background.paper"
              css={{ height: 50 }}
            >
              {props.channelDoc && (
                <Box p={1}>
                  <Avatar
                    alt={props.channelDoc?.name}
                    src={props.channelDoc?.profilePic}
                  />
                </Box>
              )}
              <Box p={1}>
                <Link to={`/channel/${props.channelDoc?.id}`}>
                  <Typography variant="subtitle1" color="textPrimary">
                    <Box display="flex" flexDirection="row">
                      <Box>{props.channelDoc?.channelName}</Box>
                      <Box>
                        {props.channelDoc?.isVerified && (
                          <span>
                            &nbsp;
                            <Tooltip title="Verified Channel">
                              <VerifiedUser style={{ width: "15px" }} />
                            </Tooltip>
                          </span>
                        )}
                      </Box>
                    </Box>
                  </Typography>
                  <Typography variant="caption" color="textPrimary">
                    {`${shortNumber(
                      props.channelDoc?.subscribers || 0
                    )} Followers`}
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" justifyContent="flex-end">
              {props.userDoc?.uid == props.videoDoc?.channelId && (
                <Box p={1}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    bgcolor="background.paper"
                    css={{ height: 50 }}
                  >
                    {props.userDoc && props.channelDoc && matches && (
                      <Link
                        to={`/channel/${
                          props.source == "videos" ? "edit-video" : "edit-post"
                        }/${props.id}`}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                        >
                          {`${
                            props.source == "videos"
                              ? "Edit Video"
                              : "Edit Post"
                          }`}
                        </Button>
                      </Link>
                    )}
                  </Box>
                </Box>
              )}
              <Box p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  bgcolor="background.paper"
                  css={{ height: 50 }}
                >
                  {props.userDoc && props.channelDoc && (
                    <FollowButton
                      userDoc={props.userDoc}
                      channelId={props.channelDoc?.id}
                      channelName={props.channelDoc?.channelName}
                      source={props.source}
                      redirect={`${props.source == "videos" ? "/watch" : "/posts"}/${props.videoDoc?.id}`}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
    // <div>
    //     {JSON.stringify(props)}
    // </div>
  );
}
