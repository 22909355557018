import React, { Component, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Box, Button } from "@material-ui/core";
import { FieldValue, firestore } from "../../base/firebaseConfig";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import LoginPrompt from "../shared/LoginPrompt";

var isPresent = require("is-present");

export default function NewComment(props) {
  const [comment, setComment] = useState("");
  const [errorcomment, setErrorComment] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleComment = (event) => {
    setComment(event.target.value.replace(/↵/g, "  \n  "));
    setVisible(true);
    let isEmpty = isPresent(event.target.value);

    setErrorComment(!isEmpty);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let date = Date.now();
    let link = `/${props.source == "videos" ? "watch" : "posts"}/${
      props.videoDoc?.id
    }`;
    let data = {
      date: FieldValue.serverTimestamp(),
      commentId: `reply-${props.id}`,
      uniqueId: props.userDoc?.uid + "-" + date,
      source: props.source,
      userId: props.userDoc?.uid,
      name: props.userDoc?.name,
      isVerified: props.userDoc?.isVerified || false,
      profilePic: props.userDoc?.profilePic,
      comment: comment.replace(/↵/g, "  \n  "),
      type: "reply-comment",
      isOwner: props.userDoc?.uid == props.videoDoc?.channelId,
      channel: props.videoDoc?.channelId,
      channelName: props.videoDoc?.channelName,
      parent: props.id,
      videoId: props.videoDoc?.id,
      videoTitle: props.videoDoc?.title,
      likes: 0,
      dislikes: 0,
      replies: 0,
      score: 0,
      link: link,
    };

    firestore
      .collection(`comments`)
      .doc(props.userDoc?.uid + "-" + date)
      .set(data)
      .then(() => {
        setComment("");
        success();
        setVisible(false);
      })
      .catch((e) => {
        error();
      });
  };
  const success = () => toast.success("Comment Published");
  const error = () => toast.error("Error occured, Please try again");
  return props.userDoc?.name ? (
    <div style={{marginTop:10, marginBottom:30}}>
      {/* {`${props.source=="videos"? "watch": "posts"}/${props.videoDoc?.id}`} */}
      <TextField
        multiline
        error={errorcomment}
        helperText={errorcomment && "Required"}
        id="outlined-basic"
        label="Comment"
        placeholder={`Comment as ${props.userDoc?.name}`}
        variant="outlined"
        value={comment}
        onChange={handleComment}
        style={{ width: "100%" }}
      />
      <ToastContainer />
      {visible && !errorcomment && (
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Box p={1}>
            <Button variant="contained" color="default" onClick={handleSubmit}>
              Post
            </Button>
          </Box>
        </Box>
      )}
      {/* {JSON.stringify(props.videoDoc)} */}
    </div>
  ) : (
    <LoginPrompt
      redirect={`${props.source == "videos" ? "/watch" : "/posts"}/${props.id}`}
      severity="warning"
      title={"Siginin to post a comment!"}
      text=" Create an account or login to join our community of creators and users"
    />
  );
}
