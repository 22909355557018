import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { firestore } from "../../base/firebaseConfig";
import Moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import NewlineText from "../utils/NewlineText";
import ClampLines from "react-clamp-lines";
import Chip from "@material-ui/core/Chip";
import LazyLoad from "react-lazy-load";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import { VerifiedUser } from "@material-ui/icons";

var format = require("format-duration");
var shortNumber = require("short-number");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
  },
}));
export default function VideoCard(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  return (
    <Link to={`/watch/${props.item?.id}`}>
      <Box width={"100%"} pt={3}>
        <LazyLoad height={matches ? 120 : 240} offsetVertical={300}>
          <div
            style={{
              width: "100%",
              height: matches ? 120 : 240,
              backgroundColor: "gray",
              backgroundImage: "url(" + props.item?.thumbnail + ")",
            }}
            className={classes.bg}
          >
            <Chip
              style={{
                marginTop: matches ? 90 : 200,
                marginLeft: matches ? "78%" : "85%",
              }}
              size="small"
              label={`${format(props.item?.duration * 1000 || 0)}`}
            />
          </div>
        </LazyLoad>
        <Box pt={1}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Avatar
                alt={props.item?.channelName}
                src={props.item?.profilePic}
                style={{marginTop: "5px" }}
              />
            </Grid>
            <Grid item xs={9}>
              <>
                <Typography variant="body2" color="textPrimary">
                  <ClampLines
                    id="really-unique-id"
                    lines={2}
                    ellipsis="..."
                    moreText="Expand"
                    lessText="Collapse"
                    className="body2"
                    innerElement="p"
                    buttons={false}
                    text={props.item?.title}
                  />
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box>{props.item?.channelName}</Box>
                    <Box>
                      {props.item?.isVerified && (
                        <span>
                          &nbsp;
                          <Tooltip title="Verified Channel">
                            <VerifiedUser style={{ width: "11px" }} />
                          </Tooltip>
                        </span>
                      )}
                    </Box>
                  </Box>
                </Typography>

                <Typography variant="caption" color="textSecondary">
                  {`${shortNumber(props.item?.views)} Views • ${Moment(
                    props.item?.date.toMillis()
                  ).fromNow()}`}
                </Typography>
              </>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Link>
  );
}
