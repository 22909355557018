import React from "react";
import { Link } from "react-router-dom";
import LogoText from "./svgs/millions-text.svg";
import Logo from "./svgs/millions-plain.svg";

export default function AppLogo(props) {
  return (
    <Link to={"/"}>
      {
        <img
          src={props.text ? LogoText : Logo}
          width={props.width || "20%"}
          style={{ paddingLeft: "5px" }}
        />
      }
    </Link>
  );
}
