import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { ExpandMore, MoreHoriz, Reply, ThumbDown, ThumbUp } from "@material-ui/icons";
import { Button, Grid, Typography } from "@material-ui/core";
import NewReplyComment from "./NewReplyComment";
import ReplyComments from "./ReplyComments";
import Avatar from "@material-ui/core/Avatar";
import { firestore, FieldValue } from "../../base/firebaseConfig";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { report } from "../constants";
import TextField from "@material-ui/core/TextField";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";

var isPresent = require("is-present");
export default function CommentActions(props) {
  const [showTextBox, setshowTextBox] = useState(false);
  const [showReplies, setshowReplies] = useState(false);
  const [liked, setliked] = useState(false);
  const [disliked, setdisliked] = useState(false);
  const [likeDoc, setlikeDoc] = useState([]);
  const [dislikeDoc, setdislikeDoc] = useState([]);
  const [replies, setComments] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [snackopen, snacksetOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [reportopen, reportsetOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [reason, setreason] = useState("");
  const [errorReason, seterrorReason] = useState(false);
  const [formOk, setformOk] = useState(false);
  let [start, setstart] = useState(0);
  const [ended, setended] = useState(false);

  let videoRef = firestore.collection("comments");
  const handleReason = (event) => {
    setreason(event.target.value.replace(/↵/g, "  \n  "));

    let isEmpty = isPresent(event.target.value);
    if (isEmpty) {
      setformOk(true);
    }
    seterrorReason(!isEmpty);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const reporthandleClickOpen = () => {
    reportsetOpen(true);
  };

  const reporthandleClose = () => {
    reportsetOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClose = () => {
    setAnchorEl(null);
  };
  const handlesnackClick = () => {
    snacksetOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    snacksetOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const replyBox = () => {
    setshowTextBox(!showTextBox);
  };

  const replyList = async () => {
    setshowReplies(!showReplies);

    if (replies.length == 0) {
      setshowReplies(true);
    }
    if (true) {
      await videoRef
        .where("commentId", "==", `reply-${props.comment?.commentId}`)
        .orderBy("date", "desc")
        .limit(3)
        .onSnapshot((querySnapshot) => {
          let myComments = [];
          querySnapshot.forEach((doc) => {
            let start = querySnapshot.docs[querySnapshot.docs.length - 1];
            setstart(start);
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            myComments.push(doc.data());
          });
          setComments(myComments);
        });
      //   .catch((error) => {
      //     console.log("Error getting comments: ", error);
      //   });
      // console.log(myComments);
    }
  };

  const getMoreComments = async (start, list) => {
    (await start) &&
      videoRef
        .where("commentId", "==", `reply-${props.comment?.commentId}`)
        .orderBy("date", "desc")
        .limit(4)
        .startAfter(start)
        .get()
        .then((querySnapshot) => {
          let mylatest = [...list];
          let end = start;
          let nextStart = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(nextStart);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
          });
          setComments(mylatest);
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
          alert("No More Videos");
        });

    if (!start) {
      setended(true);
    }
  };

  const likeRef = firestore
    .collection("comment-likes")
    .doc(props.userDoc?.uid + "_" + props.comment?.uniqueId);

  const dislikeRef = firestore
    .collection("comment-dislikes")
    .doc(props.userDoc?.uid + "_" + props.comment?.uniqueId);

  useEffect(() => {
    likeRef
      .get()
      .then((videoData) => {
        if (videoData.exists) {
          setliked(true);
        } else {
          setliked(false);
        }
      })
      .catch(() => {
        setliked(false);
      });
  }, [props]);
  useEffect(() => {
    dislikeRef
      .get()
      .then((videoData) => {
        if (videoData.exists) {
          setdisliked(true);
        } else {
          setdisliked(false);
        }
      })
      .catch(() => {
        setdisliked(false);
      });
  }, [props]);

  function setlike() {
    //like
    let date = new Date();
    let likeData = {
      channel: props.videoDoc?.channelId,
      follower: props.userDoc?.uid,
      commentId: props.comment?.uniqueId,
      likerName: props.userDoc?.name,
      commentOwner: props.comment?.userId,
      likerPhoto: props.userDoc?.profilePic,
      videoTitle: props.comment?.videoTitle,
      comment: props.comment?.comment,
      video: props.videoDoc?.id,
      type: props.source,
      date: FieldValue.serverTimestamp(),
      liked: true,
      isOwner: props.videoDoc?.channelId == props.userDoc?.uid,
    };
    if (props.userDoc?.uid) {
      likeRef
        .set(likeData)
        .then(function () {
          setliked(true);
          disliked && removedisLike();
          likedone();
        })
        .catch((error) => {
          alert("Error occured");
        });
    } else {
      alert("Please sign in");
    }
  }

  function removeLike() {
    //like
    if (props.userDoc?.uid) {
      likeRef
        .delete()
        .then(() => {
          setliked(false);
        })
        .catch((error) => {
          alert("Error removing document");
        });
    } else {
      alert("Please sign in");
    }
  }

  function removedisLike() {
    //dislike
    if (props.userDoc?.uid) {
      dislikeRef
        .delete()
        .then(() => {
          setdisliked(false);
        })
        .catch((error) => {
          alert("Error removing document");
        });
    } else {
      alert("Please sign in");
    }
  }

  function setdislike() {
    //dislike
    let date = new Date();
    let likeData = {
      channel: props.videoDoc?.channelId,
      follower: props.userDoc?.uid,
      video: props.videoDoc?.id,
      comment: props.comment?.uniqueId,
      commentOwner: props.comment?.userId,
      date: FieldValue.serverTimestamp(),
      liked: false,
    };

    if (props.userDoc?.uid) {
      dislikeRef
        .set(likeData)
        .then(function () {
          setdisliked(true);
          liked && removeLike();
          dislikedone();
        })
        .catch((error) => {
          alert("Error occured");
        });
    } else {
      alert("Please sign in");
    }
  }

  function handleDeleteComment() {
    firestore
      .collection("comments")
      .doc(props.comment?.uniqueId)
      .delete()
      .then(() => {
        deletedone();
        handlesnackClick();
      })
      .catch((error) => {
        alert("Unable to delete comment");
      });
  }

  function setreport() {
    //like
    let date = new Date();
    let reportData = {
      reason: FieldValue.arrayUnion(value),
      reportCount: FieldValue.increment(1),
      reportStatus: "Active",
      reasonDetail: FieldValue.arrayUnion(reason),
      lastReported: FieldValue.serverTimestamp(),
      reportType: "comments",
      metaData: {
        
        ...props.comment,
        video: props.videoDoc?.id,
      },
    };

    const reportRef = firestore
      .collection("reports")
      .doc(props.comment?.uniqueId);
    reportRef
      .set(reportData, { merge: true })
      .then(function () {
        reporthandleClose();
        handleOptionClose();
        alert("Reported");
      })
      .catch((error) => {
        reporthandleClose();
        handleOptionClose();
        alert("Error occured while reported");
      });
  }
  const likedone = () => toast.success("Liked");
  const dislikedone = () => toast.success("Disliked");
  const deletedone = () => toast.success("Deleted");
  const error = () => toast.error("Error occured, Please try again");
  return (
    <div>
      <ToastContainer />
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        mb={1}
        className="text-muted"
        style={{ fontSize: "14px" }}
      >
        <Box pr={1} display="flex" alignItems="center" style={{cursor:"pointer"}}>
          <ThumbUp
            style={{ fontSize: 14, color: liked && "#EC407A"}}
            onClick={liked ? removeLike : setlike}
          />
         {/* &nbsp; Like */}
        </Box>
        <Box p={1}>{props.comment?.likes || 0}</Box>
        <Box p={1} display="flex" alignItems="center" style={{cursor:"pointer"}}>
          <ThumbDown
            style={{ fontSize: 14, color: disliked && "#EC407A"}}
            onClick={disliked ? removedisLike : setdislike}
          />
         {/* &nbsp; Dislike */}
        </Box>
        <Box p={1}>{props.comment?.dislikes || 0}</Box>
        <Box p={1}>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleOptionClose}
          >
            {props.userDoc?.uid == props.videoDoc?.channelId && (
              <MenuItem onClick={handleClickOpen}>Delete as Admin</MenuItem>
            )}
          {props.userDoc?.uid == props.comment?.userId &&
              (
                <MenuItem onClick={()=>props.setisEditing(true)}>Edit Comment</MenuItem>
              )}
            {props.userDoc?.uid == props.comment?.userId &&
              !props.comment.isOwner && (
                <MenuItem onClick={handleClickOpen}>Delete</MenuItem>
              )}
            <MenuItem onClick={reporthandleClickOpen}>Report Comment</MenuItem>
          </Menu>
          <ExpandMore style={{ fontSize: 14 }} onClick={handleClick} />
         <span onClick={handleClick} style={{ fontSize: 14, cursor:"pointer"}} >Menu</span>
        </Box>
        <Box p={1}>
          {props.isReplyEnabled && (
            <Button variant="text" color="primary" onClick={replyBox}>
              reply
            </Button>
          )}
        </Box>
      </Box>
      {showTextBox && (
        <Box pt={2}>
          <Grid container spacing={3}>
            <Grid item xs={2} sm={1}>
              <Avatar
                alt={props.userDoc?.name}
                src={props.userDoc?.profilePic}
              />
            </Grid>
            <Grid item xs={10} sm={11}>
              {props.userDoc && (
                <NewReplyComment
                  userDoc={props.userDoc}
                  videoDoc={props.videoDoc}
                  id={props.comment?.commentId}
                  source={props.source}
                  comment={props.comment}
                />
              )}
            </Grid>
          </Grid>
          {/* {props.comment?.commentId} */}
        </Box>
      )}
      {props.isReplyEnabled && props.comment?.replies !== 0 && (
        <Button variant="text" color="primary" onClick={replyList} startIcon={<Reply/>}>
          {showReplies ? "Hide " : "View  "} {props.comment?.replies} Replies
        </Button>
      )}
      <br />
      {showReplies && (
        <>
          <ReplyComments
            userDoc={props.userDoc}
            videoDoc={props.videoDoc}
            id={props.comment.commentId}
            source={props.source}
            comment={props.comment}
            replies={replies}
          />
          {replies && replies.length > 2 && (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: "100%" }}
              m={1}
              p={1}
              bgcolor="background.paper"
            >
              {
                <Button
                  onClick={() => getMoreComments(start, replies)}
                  variant="outlined"
                  color="default"
                >
                  Load more
                </Button>
              }
              <br />
            </Box>
          )}
          {ended && (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: "100%" }}
              m={1}
              p={1}
              bgcolor="background.paper"
            >
              {ended && (
                <Typography variant="subtitle2" color="initial">
                  You reached at the end of the list
                </Typography>
              )}
            </Box>
          )}
        </>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete this comment?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action canot be undone. Replies associated with comment also
            may be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteComment} color="primary">
            Delete
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={reportopen}
        onClose={reporthandleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Report comment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you belive this content is against our guidelines, kindly report.
            Your identity will not be revealed
          </DialogContentText>
          <FormControl component="fieldset">
            {/* <FormLabel component="legend">Gender</FormLabel> */}
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChange}
            >
              {report.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.code}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <br />
          <br />
          {value && (
            <TextField
              multiline
              error={errorReason}
              lines={3}
              helperText={errorReason && "Required"}
              id="outlined-basic"
              label="Explain the reason"
              placeholder={`Why you need to report this?`}
              variant="outlined"
              value={reason}
              onChange={handleReason}
              style={{ width: "100%" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={!formOk} onClick={setreport} color="primary">
            Report
          </Button>
          <Button onClick={reporthandleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackopen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity="success">
          Comment deleted
        </Alert>
      </Snackbar>
    </div>
  );
}
