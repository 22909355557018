import React from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import { makeStyles } from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HomeIcon from "@material-ui/icons/Home";
import { Explore, Photo, VideoLibrary } from "@material-ui/icons";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: "0px",
    zIndex: "5000",
  },
});
export default function BottomNavBar() {
  const history = useHistory();

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const matches = useMediaQuery("(min-width:850px)");
  return (
    <div>
      {!matches && (
        <Box className={classes.root} boxShadow={2}>
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              switch (newValue) {
                case 0:
                  history.push("/");
                  break;

                case 1:
                  history.push("/explore");
                  break;

                case 2:
                  history.push("/following");
                  break;

                case 3:
                  history.push("/posts");
                  break;

                default:
                  break;
              }
            }}
            showLabels
          >
            <BottomNavigationAction label="Home" icon={<HomeIcon />} />

            <BottomNavigationAction label="Explore" icon={<Explore />} />

            <BottomNavigationAction label="Following" icon={<VideoLibrary />} />

            <BottomNavigationAction label="Posts" icon={<Photo />} />
          </BottomNavigation>
        </Box>
      )}
    </div>
  );
}
