import React from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Typography from "@material-ui/core/Typography";

export default function WalletHistory(props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
        <Typography variant="body1" color="primary">
          ₹ {props.money}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Id  {props.item?.trasactionId}
        </Typography>
      </Grid>
     
      <Grid item xs={3}>
        <Typography variant="caption" color="textSecondary" align={"right"}>{`${
          moment(props.date.toMillis()).fromNow() || ""
        }`}</Typography>
      </Grid>
    </Grid>
  );
}
