import { Box, Typography } from '@material-ui/core'
import { getDefaultNormalizer } from '@testing-library/dom'
import React from 'react'
import AppLogo from '../components/shared/AppLogo'
import LinearProgress from '@material-ui/core/LinearProgress';
export default function LoadingScreen() {
    return (
        <Box style={{textAlign:"left",width:"70%", paddingTop:"200px", paddingLeft:"20%"}}>
           <AppLogo width="90px" />
           <Typography variant="h5" color="initial" gutterBottom>
               Loading
           </Typography>
           <LinearProgress />
        </Box>
    )
}
