import React from "react";
import Typography from '@material-ui/core/Typography'

export default function Declaration(props) {
  return (
    <div style={{marginTop:props.margin ||70}}>
     <Typography variant="caption" color="initial">
        {`By ${
          props.scope || "creating an account"
        }  you acknowledge that you agree to Million's `}
        <a
          style={{ color: "red" }}
          href="https://docs.millionsofficial.in/docs/privacy/terms"
          target="_blank"
        >
          Terms of Service &nbsp;
        </a>
        and &nbsp;
        <a
          style={{ color: "red" }}
          href="https://docs.millionsofficial.in/docs/privacy/privacy-policy"
          target="_blank"
        >
          Privacy Policy
        </a>
        </Typography>
    </div>
  );
}
