import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { Box, Chip, Typography, Button } from "@material-ui/core";
import ClampLines from "react-clamp-lines";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    marginTop: 4,
    backgroundColor: "#607d8b",
    color: theme.palette.text.secondary,
  },
}));

export default function VideoAdDetail(props) {
  const classes = useStyles();
  return (
    <Box>
      <Paper className={classes.paper} variant="outlined">
        <Chip label="AD" color="primary" size="small" />
        <br />
        <Box display="flex" flexDirection="row" style={{ width: "100%" }}>
          <Box flexGrow={1}>
            <Typography variant="h6" style={{ color: "white" }}>
              {props.adDoc?.title}
            </Typography>
          </Box>
          <Box justifyContent="flex-end">
            {props.isAdStarted && props.isAdPlaying && (
              <Button
                variant="outlined"
                color="default"
                style={{ color: "white" }}
                onClick={() => props.skipAd(props.videoDoc?.videoSrc)}
                disabled={props.adCurrentTime < 5}
              >
                Skip Ad{" "}
                {props.adCurrentTime < 5 && parseInt(5 - props.adCurrentTime)}
              </Button>
            )}
          </Box>
        </Box>

        <Typography variant="caption" style={{ color: "white" }}>
          <ClampLines
            text={props.adDoc?.description || "No Description"}
            id="really-unique-id"
            lines={3}
            ellipsis="..."
            moreText="Expand"
            lessText="Collapse"
            className="body2"
            innerElement="p"
            buttons={false}
          />
        </Typography>
        <Divider />
        <br />

        <a href={props.adDoc?.adlink} target="_blank">
          <Button variant="outlined" color="default" style={{ color: "white" }}>
            Learn More
          </Button>
        </a>
      </Paper>
    </Box>
  );
}
