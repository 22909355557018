import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { firestore } from "../base/firebaseConfig";
import VideoEditForm from "../components/channel/VideoEditForm";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Helmet } from "react-helmet";
import ReelEditForm from "../components/channel/ReelEditFrom";
import VideoPlayerLight from "../components/video/VideoPlayerLight";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function EditReel(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let user = useContext(UserContext);
  const classes = useStyles();
  const [videoDoc, setVideoDoc] = useState(null);
  const [exsist, setExist] = useState(true);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [isDelete, setisDelete] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteConfirm = (event) => {
    setisDelete(event.target.value);
  };

  const handlDelete = () => {
    firestore
      .collection("reels")
      .doc(props.match.params.id)
      .delete()
      .then(() => {
        alert("Post Deleted");
        history.push("/channel/create");
      })
      .catch((error) => {
        alert("Some error occured ");
      });
    setOpen(false);
  };
  useEffect(() => {
    let docRef = firestore.collection("reels").doc(props.match.params.id);
    if (true) {
      docRef
        .get()
        .then((videoData) => {
          if (videoData.exists) {
            setVideoDoc(videoData.data());
          } else {
            setExist(false);
          }
        })
        .catch(() => {
          setExist(false);
        });
    }
  }, [props]);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit 30s | Millions</title>
        <link
          rel="canonical"
          href={`https://millionsofficial.in/channel/edit-30s`}
        />
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            {props.userDoc?.uid === videoDoc?.channelId ? (
              <div style={{ padding: "10px" }}>
                <h2>Edit 30s</h2>
                <Typography variant="caption" display="block" gutterBottom>
                  Your videos will be private until you publish them.
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    {videoDoc && (
                      <>
                        <ReelEditForm
                          videoDoc={videoDoc}
                          userDoc={props.userDoc}
                          id={props.match.params.id}
                        />
                      </>
                    )}
                    <br />
                    {/* {JSON.stringify(videoDoc)} */}
                    <Box pt={5}>
                      <Divider light />
                      <Box pt={3}>
                        <Typography variant="subtitle2" color="initial">
                          Danger Zone
                        </Typography>
                        <br />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClickOpen}
                          disableElevation
                        >
                          DELETE 30s
                        </Button>
                      </Box>
                    </Box>

                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="form-dialog-title"
                    >
                      <DialogTitle id="form-dialog-title">
                        Are you absolutely sure?
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          This action cannot be undone. This will permanently
                          delete this post's comments, likes, views etc <br />
                          Please type DELETE to confirm.
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          onChange={handleDeleteConfirm}
                          type="text"
                          fullWidth
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Cancel
                        </Button>
                        <Button
                          onClick={handlDelete}
                          color="primary"
                          disabled={isDelete !== "DELETE" ? true : false}
                        >
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <VideoPlayerLight
                      width="90%"
                      controls
                      poster={videoDoc?.thumbnail}
                      src={videoDoc?.videoSrc}
                    />
                    <Box pt={3}>
                      <Typography variant="h6" color="initial">
                        {videoDoc?.title}
                      </Typography>
                      {/* <Link to ={`/watch/${props.match.params.id}`}>
                   <Button variant="contained" color="default" disableElevation>
                     View Video
                   </Button>
                   </Link> */}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div>
                <Box
                  style={{
                    textAlign: "left",
                    paddingTop: "170px",
                    padding: "10%",
                  }}
                >
                  <Typography variant="h3" color="initial">
                    Page Not Found
                  </Typography>

                  <Typography variant="body1" color="initial">
                    Page not found or you have no access to this page
                  </Typography>
                  <Box pt={3}>
                    <Button
                      variant="contained"
                      onClick={() => history.goBack()}
                      color="primary"
                      disableElevation
                    >
                      Go Back
                    </Button>
                  </Box>
                </Box>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
