import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import NavBar from "../components/shared/NavBar";
import { auth, logoutall, firestore } from "../base/firebaseConfig";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import VideoList from "../components/home/VideoList";
import PostList from "../components/posts/PostsList";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function HomePage(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let user = useContext(UserContext);
  const classes = useStyles();

  useEffect(() => {}, []);

  return (
    <div>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Millions</title>
        <link
          rel="canonical"
          href={`https://millionsofficial.in/`}
        />
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid item xs={matches ? 11 : 12} style={{ marginTop: "100px",padding: "20px",  }}>
            <div
              // style={{
              //   margin: "10px",
              //   padding: "10px",
              //   // backgroundColor: "gray",
              // }}
            >
              {props.userDoc && (
                <VideoList userDoc={props.userDoc} loading={props.loading} />
              )}
              {/* {props.userDoc && (
                <PostList userDoc={props.userDoc} loading={props.loading} />
              )} */}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
