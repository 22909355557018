import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import { FieldValue, firestore } from "../base/firebaseConfig";
import VREPlayer from "videojs-react-enhanced";
// import "video.js/dist/video-js.css";
import VideoActions from "../components/video/VideoActions";
import VideoDiscription from "../components/video/VideoDiscription";
import CommentContainer from "../components/comments/CommentContainer";
import RelatedVideos from "../components/video/RelatedVideos";
import Divider from "@material-ui/core/Divider";
import { Box, Typography } from "@material-ui/core";
import { VideocamOff } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { Helmet } from "react-helmet";
import PostAds from "../components/shared/PostAds";
import Footer from "../components/shared/Footer";
import VideoAdDetail from "../components/video/VideoAdDetail";
import MillionsPlayer from "../components/video/MillionsPlayer";
import VideoJSPlayer from "../components/video/VideoJsPlayer";


const publicIp = require("public-ip");
require("videojs-seek-buttons");
require("videojs-mobile-ui");

export default function VideoDetail(props) {
  const matches = useMediaQuery("(min-width:850px)");
  const [id, setId] = useState(null);
  const [videoDoc, setVideoDoc] = useState(null);
  const [adDoc, setadDoc] = useState(null);

  const [currentlyPlaying, setcurrentlyPlaying] = useState("");
  const [exsist, setExist] = useState(true);
  const [channelDoc, setchannelDoc] = useState(null);
  const [error, seterror] = useState(false);
  const [duration, setduration] = useState(0);
  const [play, setplay] = useState(false);
   let [isAdPlaying, setisAdPlaying] = useState(true);
  const [errorMessage, seterrorMessage] = useState("");
  const [contdown, setcontdown] = useState(3000);
  const [currentDuration, setcurrentDuration] = useState(0);
  const [adCurrentTime, setadCurrentTime] = useState(0);
  const [isAdStarted, setisAdStarted] = useState(false);
  const [isVideoPlaying, setisVideoPlaying] = useState(false);
  const [alreadyWatched, setalreadyWatched] = useState(false);
  const [isViewSend, setisViewSend] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [allPromise, setallPromise] = useState(false);
  const [viewList, setviewList] = useState([]);

  //get video document from id
  useEffect(() => {
    setId(props);
    let docRef = firestore.collection("videos").doc(props.match.params.id);
    props.userDoc &&
      props.match.params.id &&
      docRef
        .get()
        .then((videoData) => {
          if (videoData.exists) {
            setVideoDoc(videoData.data());
            getChannel(videoData.data().channelId);
            getAds(props.userDoc, videoData.data());
            setduration(videoData.data().duration || 100);
            setisLoading(false);
            switch (videoData.data().isVisible) {
              case "Private":
                if (props.userDoc?.uid == videoData.data().channelID) {

                } else {
                  seterror(true);
                  seterrorMessage("This Video is private");
                }
                break;
              case "Blocked":
                seterror(true);
                seterrorMessage("This video is blocked");

                break;
              case "Removed":
                seterror(true);
                seterrorMessage("This video is deleted");
                break;
              case "Removed":
                seterror(true);
                seterrorMessage("This video is under processing");
                break;  
              default:
                isWatched();
                break;
            }
          } else {
            setExist(false);
            setisLoading(false);
            seterror(true);
            seterrorMessage("Video Not Found");
          }
        })
        .catch(() => {
          setExist(false);
          setisLoading(false);
          seterror(true);
          seterrorMessage("Unable to load video");
        });
  }, [props.match.params.id, props.userDoc]);

  let isWatched = async () => {
    try {
      let ip = localStorage.getItem("ip");
      let id = props.userDoc?.uid ? props.userDoc?.uid : ip;
      const channelRef = firestore
        .collection("views")
        .doc(id + "_" + props.match.params.id);
    } catch (e) {

    }
  };
  let getChannel = async (id) => {
    let channelRef = firestore.collection("channels").doc(id);
    if (channelRef) {
      channelRef
        .get()
        .then((channelData) => {
          if (channelData.exists) {
            setchannelDoc(channelData.data());
          } else {
            seterror(false);
            errorMessage("Video Not Found");
          }
        })
        .catch(() => {
          seterror(false);
        });
    }

    let ip = (await publicIp.v4()) || Math.random(4);
    localStorage.setItem("ip", ip);
  };

  const getAds = async (user, videoDoc) => {

    setadDoc(null)
    setisAdPlaying(true)
    if (true) {
      let adRef = firestore.collection("ads");
      await adRef
        .where(
          "showAdIn",
          "in",
          [
            user?.district || "Kollam",
            user?.state || "KL",
            user?.country || "IN",
            "All Videos",
          ] || ["Kollam", "KL", "IN", "All Videos"]
        )
        .where("isVisible", "==", "Public")
        .orderBy("date", "desc")
        .limit(10)
        .get()
        .then((querySnapshot) => {
          let mylatest = [];
          let adArr = [];
          querySnapshot.forEach((doc) => {
            //doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " ad => ", doc.data());

            mylatest.push(doc.data());
          })
          let length = mylatest.length;
          let random = Math.floor(Math.random() * length);
          adArr.push(mylatest[random]);

          if (mylatest.length == 0) {
            setisAdPlaying(false);
            setcurrentlyPlaying(videoDoc.videoSrc);
          } else {
            if (true) {
              setisAdPlaying(true);
              setadDoc(adArr[0]);
              // console.log(adArr);
              setcurrentlyPlaying(mylatest[random].videoSrc);
            }
          }
        })
        .catch((error) => {  
            setisAdPlaying(false);
            setcurrentlyPlaying(videoDoc.videoSrc);
       
        //console.log("Error getting documents: ", error);
        });
      // console.log(mylatest);
    }
  };

  //adEnded
  let adEnded = () => {
    setisAdPlaying(false);
    localStorage.removeItem("time");

    setcurrentlyPlaying(videoDoc?.videoSrc);

    firestore
      .collection("ads")
      .doc(adDoc.id)
      .update(
        {
          compleated: FieldValue.increment(1),
        }
      )
      // .then(() => console.log("Ad send"));
  };


  let skipAd = (link) => {
    setisAdPlaying(false);
    localStorage.removeItem("time");

    firestore
      .collection("ads")
      .doc(adDoc.id)
      .set(
        {
          skips: FieldValue.increment(1),
        },
        { merge: true }
      )
      // .then(() => console.log("Ad Skipped"));

    setcurrentlyPlaying(link);
  };

  let playAdInit = (id) => {
    setisAdStarted(true);

    firestore
      .collection("ads")
      .doc(id)
      .set(
        {
          views: FieldValue.increment(1),
        },
        { merge: true }
      )
      // .then(() => console.log("Ad send"));

    firestore
      .collection("ad-views")
      .add(
        {
          user: props.userDoc.uid || "Anonymous-user",
          timestamp: FieldValue.serverTimestamp(),
          adId: adDoc.id,
          title: adDoc.title,
          videoTitle: videoDoc.title,
        },
        { merge: true }
      )
      // .then(() => console.log("Ad View Send"));
  };

  const playerOptions = {
    src: adDoc?.videoSrc,
    controls: true,
    aspectRatio: "16:9",
    autoplay: true,
    preload: "auto",
    muted: JSON.parse(localStorage.getItem("muted")) || false,
  };

  const videojsOptions = {
    fluid: true,
    poster: videoDoc?.thumbnail,
    nativeControlsForTouch: false,
  };

  const hideList = ["progressControl"];
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`${videoDoc?.title || "Video"} | Millions`}</title>
        <link
          rel="canonical"
          href={`https://millionsofficial.in/watch/${videoDoc?.id}`}
        />
      </Helmet>
      <div>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            <div style={{ padding: "10px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  {error && (
                    <Box>
                      <div
                        style={{
                          height: "400px",
                          width: "100%",
                          backgroundColor: "black",
                          color: "white",
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          p={1}
                          m={1}
                          justifyContent="center"
                          css={{ height: "100%" }}
                        >
                          <Box p={1}>
                            <VideocamOff fontSize={"Large"} />
                          </Box>
                          <Box p={1}>
                            <Typography variant="h4" color="initial">
                              {errorMessage}
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                    </Box>
                  )}
                  {videoDoc && !error && !isLoading && (
                    <>
                      <div style={{ display: "none" }}>
                        {alreadyWatched ? "watched" : "not watched"}
                        {isAdPlaying ? "Ad Playing" : "No Ads"} <br />
                        {adDoc && `Showing ad in ${adDoc?.showAdIn}`}
                      </div>
                      {currentlyPlaying && (
                        <div className="rightclick-disable">
                          {isAdPlaying ?(
                            <>
                              <VREPlayer
                               key={adDoc?.videoSrc}
                                playerOptions={playerOptions}
                                videojsOptions={videojsOptions}
                                // onReady={() => console.log('start')}
                                onPlay={(_) => playAdInit(adDoc.id)}
                                onTimeUpdate={(e, _, second) =>
                                  setadCurrentTime(second)
                                }
                                hideList={hideList}
                                onPause={(_) => setplay(false)}
                                onEnded={(_) => adEnded()}
                              />
                            </>
                          ) : (
                            <div>
                             {videoDoc?.videoSrc&&
                             
                            <VideoJSPlayer
                             key={videoDoc?.videoSrc}
                             src={videoDoc?.videoSrc}
                             poster={videoDoc?.thumbnail}
                             videoDoc={videoDoc}
                             channelDoc={channelDoc}
                             userDoc={props.userDoc}
                             id={props.match.params.id}/>}
                            </div>
                          )}


                        </div>
                      )}

                      {adDoc && (
                        <VideoAdDetail
                          adDoc={adDoc}
                          isAdStarted={isAdStarted}
                          isAdPlaying={isAdPlaying}
                          skipAd={skipAd}
                          videoDoc={videoDoc}
                          adCurrentTime={adCurrentTime}
                        />
                      )}
                      <br />
                      <Typography variant="h6" color="initial" gutterBottom>
                        {videoDoc.title || "Untitled Video"}
                      </Typography>

                      <VideoActions
                        id={props.match.params.id}
                        userDoc={props.userDoc}
                        channelDoc={channelDoc}
                        videoDoc={videoDoc}
                        source={"videos"}
                        // isAdPlaying={isAdPlaying || false}
                      />
                      <Divider />
                      {videoDoc?.description && (
                        <VideoDiscription text={videoDoc?.description} />
                      )}
                      <Divider />
                      <div id="comments">
                        <CommentContainer
                          id={props.match.params.id}
                          userDoc={props.userDoc}
                          channelDoc={channelDoc}
                          videoDoc={videoDoc}
                          source={"videos"}
                        />
                      </div>
                    </>
                  )}
                  <br />
                  {isLoading && (
                    <>
                      <Skeleton variant="rect" width={"100%"} height={290} />
                      <br />
                      <Skeleton variant="rect" width={"100%"} height={30} />
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ paddingRight: 20 }}
                  style={{ paddingLeft: 20 }}
                >
                  {videoDoc && !error && (
                    <>
                      <PostAds userDoc={props.userDoc} />
                      <br />
                      <RelatedVideos
                        userDoc={props.userDoc}
                        videoDoc={videoDoc}
                        channelDoc={channelDoc}
                      />
                    </>
                  )}
                  <Footer />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
