import "./App.css";
import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  firestore,
  auth,
  getToken,
  onMessageListener,
  remoteConfig,
} from "./base/firebaseConfig";
import UserProvider from "./base/UserContext";
import HomePage from "./pages/HomePage";
import ProtectedRoute from "./ProtectedRoutes";
import LandingPage from "./pages/LandingPage";
import EditProfile from "./pages/EditProfile";
import { ThemeProvider } from "@material-ui/styles";
import {
  CssBaseline,
  AppBar,
  Typography,
  createMuiTheme,
  Box,
  Paper,
} from "@material-ui/core";
import { purple } from "@material-ui/core/colors";
import UploadVideo from "./pages/UploadVideo";
import VideoDetail from "./pages/VideoDetail";
import ChannelHome from "./pages/ChannelHome";
import ChannelEdit from "./pages/ChannelEdit";
import CreatePost from "./pages/CreatePost";
import PostDetail from "./pages/PostDetail";
import ExploreVideos from "./pages/ExploreVideos";
import SubscribedVideos from "./pages/SubscribedVideos";
import CreateHome from "./pages/CreateHome";
import BottomNavBar from "./components/shared/BottomNavBar";
import FindPosts from "./pages/FindPosts";
import EditVideo from "./pages/EditVideo";
import EditPost from "./pages/EditPost";
import SearchPage from "./pages/SearchPage";
import Maintenance from "./pages/Maintenance";
import MyProfile from "./pages/MyProfile";
import NotFound from "./pages/NotFound";
import NavBar from "./components/shared/NavBar";
import MyNotifications from "./pages/MyNotifications";
import ScrollToTop from "./components/shared/ScrollToTop";
import Terms from "./pages/TermsOfService";
import LoadingScreen from "./pages/LoadingScreen";
import EditReel from "./pages/EditReel";
import ReCAPTCHA from "react-google-recaptcha";
import NotFound30 from "./pages/NotFound30";
import ContactUs from "./pages/ContactUs";
import BlankPage from "./pages/BlankPage";

function App(props) {
  let recaptacha = useRef();
  const [userDoc, setUserDoc] = useState(null);
  const [newUser, setNewUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [user, setuser] = useState([]);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  const [isMaintenance, setisMaintenance] = useState(false);
  const [darkState, setDarkState] = useState(false);
  const palletType = darkState ? "dark" : "light";
  const mainPrimaryColor = darkState ? "#a31545" : "#a31545";
  const mainSecondaryColor = darkState ? "#fff" : "#272c34";
  const paperColor = darkState ? "#181818" : "#fff";

  const handleThemeChange = () => {
    setDarkState(!darkState);
    if (darkState) {
      localStorage.removeItem("darkmode");
    } else {
      localStorage.setItem("darkmode", true);
    }
  };
  const theme = createMuiTheme({
    typography: {
      fontFamily: `"ubuntu", sans-serif`,
    },

    palette: {
      type: palletType,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
      background: {
        paper: paperColor,
      },
      grey: {
        800: "#1d1b1b", // overrides failed
        900: "#1d1b1b", // overrides success
      },

      // gray: {
      //   main: "#212121",
      // },
      // navBg: {
      //   main: "#F4F6F9",
      // },
    },
  });
  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      setuser(userAuth);
      let docRef = userAuth && firestore.collection("users").doc(userAuth.uid);
      if (userAuth) {
        docRef
          .get()
          .then((userData) => {
            if (userData.exists) {
              setUserDoc(userData.data());
              getToken(setTokenFound);
              setLoading(false);
            } else {
              setNewUser(true);
              setLoading(false);
              setUserDoc("Anonymous");
            }
          })
          .catch(() => {
            setUserDoc(null);
            setUserDoc("Anonymous");
            setLoading(false);
          });
      } else {
        setUserDoc("Anonymous");
        setLoading(false);
      }
    });

    let darkmode = localStorage.getItem("darkmode");

    if (darkmode) {
      setDarkState(true);
    }
  }, [props]);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  // remoteConfig.defaultConfig = {
  //   "off_website": false
  // };
  function captchaHandler(value) {
    // console.log("Captcha value:", value);
  }

  if (!isMaintenance) {
    return (
      <ThemeProvider theme={theme}>
        <Paper style={{ minHeight: "100vh" }} square>
          <UserProvider>
            <Router>
              {!loading ? (
                <>
                  <ScrollToTop />
                  {/* {isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>}
        {!isTokenFound && <h1> Need notification permission ❗️ </h1>}
        {notification.title}{notification.body} */}

                  <Switch>
                    <Route exact path="/login" component={LandingPage} />
                    <Route
                      exact
                      path="/edit-profile"
                      component={() => (
                        <EditProfile userDoc={userDoc} loading={loading} />
                      )}
                    />
                     
                  </Switch>
                
                  <NavBar
                    loc="/channel/upload"
                    currentMode={darkState}
                    handleThemeChange={handleThemeChange}
                    userDoc={userDoc}
                    user={userDoc}
                  />
                  <Switch>
                  <Route path="/login" component={BlankPage} />
                    {/* <ProtectedRoute exact path="/" component={HomePage} /> */}
                    <Route
                      exact
                      path="/"
                      component={() => <HomePage userDoc={userDoc} />}
                    />

                    <Route
                      exact
                      path="/explore"
                      component={() => <ExploreVideos userDoc={userDoc} />}
                    />
                    <Route
                      exact
                      path="/following"
                      component={() => <SubscribedVideos userDoc={userDoc} />}
                    />

                    <Route
                      exact
                      // userDoc={userDoc}
                      path="/search"
                      render={(props) => (
                        <SearchPage {...props} userDoc={userDoc} />
                      )}
                      // component={SearchPage}
                    />

                    <Route
                      exact
                      path="/posts"
                      component={() => <FindPosts userDoc={userDoc} />}
                    />

                    <Route
                      exact
                      path="/notifications"
                      component={() => <MyNotifications userDoc={userDoc} />}
                    />
                    <ProtectedRoute
                      exact
                      path="/my-profile"
                      component={() => (
                        <MyProfile userDoc={userDoc} user={user} />
                      )}
                    />
                    <ProtectedRoute
                      exact
                      path="/channel/create"
                      component={() => <CreateHome userDoc={userDoc} />}
                    />
                    <ProtectedRoute
                      exact
                      userDoc={userDoc}
                      path="/channel/edit-video/:id"
                      component={EditVideo}
                    />
                    <ProtectedRoute
                      exact
                      userDoc={userDoc}
                      path="/channel/edit-post/:id"
                      component={EditPost}
                    />
                    <ProtectedRoute
                      exact
                      userDoc={userDoc}
                      path="/channel/edit-30s/:id"
                      component={EditReel}
                    />
                    <ProtectedRoute
                      exact
                      userDoc={userDoc}
                      path="/channel/upload"
                      component={UploadVideo}
                    />
                    <ProtectedRoute
                      exact
                      userDoc={userDoc}
                      path="/channel/create-post"
                      component={CreatePost}
                    />
                    <ProtectedRoute
                      exact
                      path="/channel/edit"
                      component={() => <ChannelEdit userDoc={userDoc} />}
                    />

                    <Route
                      exact
                      userDoc={userDoc}
                      path="/watch/:id"
                      render={(props) => (
                        <VideoDetail {...props} userDoc={userDoc} />
                      )}
                    />

                    <Route
                      exact
                      userDoc={userDoc}
                      path="/posts/:id"
                      render={(props) => (
                        <PostDetail {...props} userDoc={userDoc} />
                      )}
                    />

                    <Route
                      exact
                      userDoc={userDoc}
                      path="/channel/:id"
                      render={(props) => (
                        <ChannelHome {...props} userDoc={userDoc} />
                      )}
                    />
                    <Route
                      exact
                      userDoc={userDoc}
                      path="/terms"
                      render={(props) => <Terms {...props} userDoc={userDoc} />}
                    />

                    {/* <VideoDetail userDoc={userDoc} /> */}
                    <Route
                      path="/30s"
                      component={() => <NotFound30 userDoc={userDoc} />}
                    />
                    <Route
                      path="/reels"
                      component={() => <NotFound30 userDoc={userDoc} />}
                    />
                     <Route
                      path="/contact-us"
                      component={() => <ContactUs userDoc={userDoc} />}
                    />
                    <Route component={() => <NotFound userDoc={userDoc} />} />
                  </Switch>
                  {/* <ReCAPTCHA
            sitekey="6LchRbobAAAAABNv78_FAUlC4r7mW_vjl7Qo6AzI"
            onChange={captchaHandler}
          /> */}
                  <BottomNavBar />
                </>
              ) : (
                <LoadingScreen />
              )}
            </Router>
          </UserProvider>
        </Paper>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Router>
            <Maintenance />
          </Router>
        </Box>
      </ThemeProvider>
    );
  }
}

export default App;
