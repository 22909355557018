import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { FieldValue, firestore } from "../base/firebaseConfig";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  languages,
  states,
  countries,
  districts,
} from "../components/constants";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { Divider } from "@material-ui/core";
import Moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import MuiPhoneNumber from "material-ui-phone-number";
import Footer from "../components/shared/Footer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    // style: {
    //   maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //   width: 250,
    // },
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
  },
  paper: {
    padding: theme.spacing(1),
    // margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  //   maxWidth: 300,
  // },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    marginBottom: theme.spacing(3),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 12,
        fontSize: 18,
        margin: theme.spacing(2),
        width: "100%",
      },
    },
  },
}));

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function AlertDone(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function MyProfile(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let user = useContext(UserContext);
  const classes = useStyles();
  const [id, setId] = useState(null);
  const [videoDoc, setVideoDoc] = useState(null);
  const [exsist, setExist] = useState(true);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const theme = useTheme();

  const [name, setName] = useState(props.userDoc?.name || user?.displayName);
  const [phone, setphone] = useState(props.userDoc?.phone || "");
  const [gender, setGender] = useState(props.userDoc?.gender || "");
  const [state, setState] = useState(props.userDoc?.state || "KL");
  const [district, setDistrict] = useState(props.userDoc?.district || "");
  const [country, setCountry] = useState(props.userDoc?.country || "IN");
  const [language, setLanguage] = useState(props.userDoc?.language || []);
  const [open, setOpen] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [place, setPlace] = useState(props.userDoc?.place || "");

  const [errorname, setErrorName] = useState(false);
  const [errorphone, setErrorphone] = useState(false);
  const [errorgender, setErrorGender] = useState(false);
  const [errorstate, setErrorState] = useState(false);
  const [errordistrict, setErrorDistrict] = useState(false);
  const [errorcountry, setErrorCountry] = useState(false);
  const [errorlanguage, setErrorLanguage] = useState(false);
  const [edited, setEdited] = useState(false);

  const AddItem = () => {};
  const addItem = (name) => {};
  const handleName = (event) => {
    setEdited(true);
    setName(event.target?.value);
  };
  const phoneChecker = require("phone");
  const handlephone = (value) => {
    setEdited(true);
    setErrorphone(phoneChecker(value, "", true).length !== 2);
    setphone(value);
  };
  const handleGender = (event) => {
    setEdited(true);
    setGender(event.target.value);
  };
  const handleCountry = (event) => {
    setEdited(true);
    setCountry(event.target.value);
  };
  const handleState = (event) => {
    setEdited(true);
    setState(event.target.value);
  };
  const handleDistrict = (event) => {
    setEdited(true);
    setDistrict(event.target.value);
  };
  const handlePlace = (event) => {
    setEdited(true);
    setPlace(event.target.value);
  };

  const handleChange = (event) => {
    setEdited(true);
    setLanguage(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let date = new Date();
    const userData = {
      name: name || user.displayName,
      gender: gender || "",
      country: country || "IN",
      state: state || "KL",
      district: district || "Kollam",
      place: place,
      language: language || ["English", "Malayalam"],
      edited: FieldValue.serverTimestamp(),
      phone: phone || "",
    };
    !name && setErrorName(true);
    !phone && setErrorphone(true);
    !gender && setErrorGender(true);
    !country && setErrorCountry(true);
    !state && setErrorState(true);
    !district && setErrorDistrict(true);

    let isPhone = phoneChecker(phone, '', true).length === 2
    if (language.length < 3) {
      setErrorLanguage(true);
    }
    if (name && gender && country && language && isPhone && state && district) {
      
     await firestore
        .collection(`users`)
        .doc(props.userDoc?.uid)
        .set(userData, { merge: true })
        .then(() => {
          success();
          // handleClick();
        });
    } else {
      requirederror()
    }
  };

  useEffect(() => {
    setId(props);
    let docRef = firestore.collection("channels").doc(props.userDoc?.uid);
    if (true) {
      docRef
        .get()
        .then((videoData) => {
          if (videoData.exists) {
            setVideoDoc(videoData.data());
          } else {
            setExist(false);
          }
        })
        .catch(() => {
          setExist(false);
          error()
        });
    }
  }, [props.userDoc]);

  const success = () => toast.success("Account Edited");
  const error = () => toast.error("Error occured, Please try again");
  const requirederror = () => toast.error("Please fill required fields");

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Profile | Millions</title>
        <link
          rel="canonical"
          href={`https://millionsofficial.in/my-profile`}
        />
      </Helmet>
      <div className={classes.root}>
        <Grid container style={{ margin: 0 }} spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            <div style={{ padding: "10px" }}>
              <Typography variant="h5" color="initial" gutterBottom>
                My Account
              </Typography>

              <Typography variant="caption" display="block" gutterBottom>
                Only you can see this page
              </Typography>

              <Grid container spacing={5}>
                <Grid item xs={12} sm={8}>
                  <br />
                  <div style={{ width: "100%" }}>
                    {edited && (
                      <Alert severity="warning">
                        <AlertTitle>Warning</AlertTitle>
                        Please click save button to keep your edits
                        {/* <strong>check it out!</strong> */}
                      </Alert>
                    )}
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      pt={1}
                      mt={1}
                      mb={1}
                    >
                      <Box pt={1} width={"90%"}>
                        <h5>Edit Account</h5>
                      </Box>
                      <Box pt={1}>
                        {edited && (
                          <Button
                            variant="contained"
                            disabled={edited ? false : true}
                            onClick={handleSubmit}
                            color="primary"
                          >
                            Save
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </div>
                  <div>
                    <div>
                      <TextField
                        required
                        className={classes.formControl}
                        error={errorname}
                        helperText={errorname && "Required"}
                        value={name}
                        id="outlined-basic"
                        label="Display Name"
                        variant="outlined"
                        onChange={handleName}
                      />
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Gender
                        </InputLabel>
                        <Select
                          required
                          error={errorgender}
                          helperText={errorgender && "Required"}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={gender}
                          onChange={handleGender}
                          label="Age"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                          <MenuItem value={"Ohers"}>Others</MenuItem>
                        </Select>
                      </FormControl>

                      <MuiPhoneNumber
                        defaultCountry={"in"}
                        required
                        className={classes.formControl}
                        error={errorphone}
                        helperText={
                          errorphone && "Please enter a valid phone number"
                        }
                        id="outlined-basic2"
                        label="Phone Number"
                        variant="outlined"
                        onChange={handlephone}
                        value={phone}
                      />

                      <FormHelperText className={classes.formControl}>
                        For monetization related communications
                      </FormHelperText>
                    </div>
                    <div>
                      <TextField
                        required
                        error={errorcountry}
                        className={classes.formControl}
                        helperText={errorcountry && "Required"}
                        id="outlined-select-currency"
                        select
                        label="Country"
                        value={country}
                        onChange={handleCountry}
                        helperText="Please select your country"
                        variant="outlined"
                      >
                        {countries.map((option) => (
                          <MenuItem key={option.code} value={option.code}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>

                      {country === "IN" && (
                        <>
                          <TextField
                            required
                            error={errorstate}
                            className={classes.formControl}
                            helperText={errorstate && "Required"}
                            id="outlined-select-currency"
                            select
                            label="State"
                            value={state}
                            onChange={handleState}
                            helperText="Please select your district"
                            variant="outlined"
                          >
                            {states.map((option) => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>

                          <TextField
                            required
                            select
                            error={errordistrict}
                            className={classes.formControl}
                            helperText={errordistrict && "Required"}
                            id="outlined-basic3"
                            label="District"
                            // disabled={true}
                            variant="outlined"
                            onChange={handleDistrict}
                            value={district}
                          >
                            {districts
                              .find((r) => r.state == state)
                              .districts.map((option) => (
                                <MenuItem value={option} key={state}>
                                  {option}
                                </MenuItem>
                              ))}
                          </TextField>

                          <TextField
                            required
                            // error={errorstate}
                            // helperText={errorstate && "Required"}
                            className={classes.formControl}
                            id="outlined-basic3"
                            label="Place"
                            value={place}
                            variant="outlined"
                            onChange={handlePlace}
                          />
                        </>
                      )}
                    </div>
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-mutiple-chip-label">
                          Select Video Languages
                        </InputLabel>
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          required
                          variant="outlined"
                          value={language}
                          error={errorlanguage}
                          helperText={"Select atleast 3"}
                          onChange={handleChange}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  className={classes.chip}
                                />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {languages.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              //   style={getStyles(name, language, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>Select atleast 3</FormHelperText>
                      </FormControl>
                    </div>
                  </div>

                  {/* {JSON.stringify(props.user)} */}
                  <Typography variant="caption2" color="initial">
                    {/* {JSON.stringify(videoDoc)} */}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" color="">
                    Google Profile Picture
                  </Typography>
                  <Divider />

                  <Avatar
                    alt="Remy Sharp"
                    src={props.user?.photoURL}
                    style={{ marginTop: "5px" }}
                  />

                  <Typography
                    variant="subtitle1"
                    color=""
                    style={{ marginTop: "20px" }}
                  >
                    Account Created
                  </Typography>
                  <Divider />
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    gutterBottom
                  >
                    {Moment(props.userDoc?.created?.toMillis()).fromNow()}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    color=""
                    style={{ marginTop: "20px" }}
                  >
                    Email
                  </Typography>
                  <Divider />
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    gutterBottom
                  >
                    {props.userDoc?.email}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    color=""
                    style={{ marginTop: "20px" }}
                  >
                    Google Account Name
                  </Typography>
                  <Divider />
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    gutterBottom
                  >
                    {props.user?.displayName}
                  </Typography>
                  <Footer/>
                  <Snackbar
                    open={open}
                    autoHideDuration={1000}
                    onClose={handleClose}
                  >
                    <AlertDone onClose={handleClose} severity="success">
                      Account Edited
                    </AlertDone>
                  </Snackbar>
                  <ToastContainer />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
