import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { firestore } from "../../base/firebaseConfig";
import Moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import ClampLines from "react-clamp-lines";
import VideoListSkelton from "../home/VideoListSkelton";
import VideoCard from "../video/VideoCard";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function ChannelVideos(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  let [isUser, setIsUser] = useState("Loading");
  let [latest, setLatest] = useState([]);
  const [loading, setloading] = useState(true);
  let [start, setstart] = useState(0);
  const [ended, setended] = useState(false);

  let videoRef = firestore.collection("videos");
  const getLatest = async () => {
    await videoRef
      .where("channelId", "==", props.channelId)
      .where("isVisible", "==", "Public")
      .orderBy("date", "desc")
      .limit(8)
      .get()
      .then((querySnapshot) => {
        let mylatest = [];
        let start = querySnapshot.docs[querySnapshot.docs.length - 1];
        setstart(start);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          mylatest.push(doc.data());
        });
        setLatest(mylatest);
        setloading(false);
      })
      .catch((error) => {
        // console.log("Error getting documents: ", error);
      });
    // console.log(mylatest);
  };

  useEffect(() => {
    getLatest();
  }, [props]);

  const getMoreVideos = async (start, list) => {
    (await start) &&
      videoRef
        .where("channelId", "==", props.channelId)
        .where("isVisible", "==", "Public")
        .orderBy("date", "desc")
        .startAfter(start)
        .limit(4)
        .get()
        .then((querySnapshot) => {
          let mylatest = [...list];
          let end = start;
          let nextStart = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(nextStart);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
          });
          setLatest(mylatest);
          setloading(false);
        })
        .catch((error) => {
          // console.log("Error getting documents: ", error);
          alert("No More Videos");
        });

    if (!start) {
      setended(true);
    }
  };
  return (
    <div style={{ paddingTop: 20 }}>
      {true && (
        <Grid item xs={12} sm={12}>
          <Grid container spacing={3}>
            {latest?.map((item, index) => (
              <Grid key={index} item xs={12} md={3} sm={6}>
                <VideoCard item={item} />
              </Grid>
            ))}
            {!loading && latest.length == 0 && (
              <Typography variant="caption" color="initial">
                No Videos
              </Typography>
            )}
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: "100%" }}
              m={1}
              p={1}
              bgcolor="background.paper"
            >
              {latest && !loading && latest.length > 7 &&(
                <Button
                  onClick={() => getMoreVideos(start, latest)}
                  variant="outlined"
                  color="default"
                >
                  Load more
                </Button>
              )}
              <br />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: "100%" }}
              m={1}
              p={1}
              bgcolor="background.paper"
            >
              {ended && (
                <Typography variant="subtitle2" color="initial">
                  You reached at the end of the list
                </Typography>
              )}
            </Box>
            {loading && (
              <>
                <VideoListSkelton />
                <VideoListSkelton />
              </>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
}
