import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { firestore } from "../base/firebaseConfig";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import Moment from "moment";
import { useHistory } from "react-router-dom";
// import TopChannelList from "./TopChannelList";
// import NewlineText from "../utils/NewlineText";
import ClampLines from "react-clamp-lines";
import Button from "@material-ui/core/Button";
import VideoListSkelton from "../components/home/VideoListSkelton";
import VideoCard from "../components/video/VideoCard";
import {Helmet} from "react-helmet";
import Footer from "../components/shared/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function SubscribedVideos(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let user = useContext(UserContext);
  const classes = useStyles();
  const [id, setId] = useState(null);
  const [followDoc, setFollowDoc] = useState(null);
  const [latest, setLatest] = useState([]);
  const [isAnonymous, setAnonymous] = useState("");
  const [noVideos, setnoVideos] = useState(false);
  const [loading, setloading] = useState(true);
  let [start, setstart] = useState(0);
  const [ended, setended] = useState(false);

  let history = useHistory();
  const getFollowingList = async () => {
    if (props.userDoc !== "Anonymous") {
      let followRef = firestore.collection("followers");
      await followRef
        .where("follower", "==", props.userDoc?.uid || [])
        .orderBy("date", "desc")
        .get()
        .then((querySnapshot) => {
          let myfollowing = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            myfollowing.push(doc.data().channel);
          });
          setFollowDoc(myfollowing.slice(0,9));

          if (myfollowing.length == 0) {
            setloading(false);
          } else {
            getLatest(myfollowing.slice(0,9));
          }
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
          setloading(false);
        });
    } else {
      setAnonymous(true);
      setloading(false);
    }
  };
  
  const getLatest = async (list) => {
    if (list.length > 0) {
      let videoRef = firestore.collection("videos");
      await videoRef
        .where("isVisible", "==", "Public")
        .where("channelId", "in", list.slice(0,9) || ["s"])
        .orderBy("date", "desc")
        .limit(12)
        .get()
        .then((querySnapshot) => {
          let mylatest = [];
          let start = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(start);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
            setloading(false);
          });
          setLatest(mylatest);
          if (mylatest.length == 0) {
            setnoVideos(true);
            setloading(false);
          }
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
          setloading(false);
        });
      // console.log(mylatest);
    }
  };

  const getMorePosts = async (start, list, following) => {
    let videoRef = firestore.collection("videos");
    (start) &&
     
     await videoRef
       .where("isVisible", "==", "Public")
       .where("channelId", "in", following || ["s"])
       .orderBy("date", "desc")
        .startAfter(start)
        .limit(6)
        .get()
        .then((querySnapshot) => {
          let mylatest = [...list];
          let end = start;
          let nextStart = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(nextStart);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
          });
          setLatest(mylatest);
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
          alert("No More Videos");
        });

    if (!start) {
      setended(true);
    }
  };
  useEffect(() => {
    setId(props);
    props.userDoc && getFollowingList();
  }, [props.userDoc]);

  return (
    <div className="App">
        <Helmet>
        <meta charSet="utf-8" />
        <title> {`Following | Millions`}</title>
        <link
          rel="canonical"
          href={`https://millionsofficial.in/following`}
        />
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            {latest && (
              <Box overflow="hidden">
                <Typography variant="h5" color="initial" gutterBottom>
                {!isAnonymous &&
                    !noVideos &&
                    ` Channels Followed by, ${
                      props.userDoc && props.userDoc.name
                    }`}
                </Typography>
               
                <Grid xs={12} sm={3}></Grid>
                {latest && (
                  <Grid item xs={12}>
                    <div style={{ padding: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <Grid container spacing={3}>
                            {latest.map((item, index) => (
                              <Grid key={index} item xs={12} md={3} sm={6}>
                                
                                <VideoCard item={item}/>
                              </Grid>
                            ))}
                                  <Box
                                display="flex"
                                justifyContent="center"
                                style={{ width: "100%" }}
                                m={1}
                                p={1}
                                bgcolor="background.paper"
                              >
                                {latest && latest.length > 11 && (
                                  <Button
                                    onClick={() => getMorePosts(start, latest,followDoc)}
                                    variant="outlined"
                                    color="default"
                                  >
                                    Load more
                                  </Button>
                                )}

                                <br />
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="center"
                                style={{ width: "100%" }}
                                m={1}
                                p={1}
                                bgcolor="background.paper"
                              >
                                {ended && (
                                  <Typography
                                    variant="subtitle2"
                                    color="initial"
                                  >
                                    You reached at the end of the list
                                  </Typography>
                                )}
                              </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                )}
              </Box>
            )}
            {/* {latest.length == 0 && !loading && !isAnonymous && (
              <div>
                <Box
                  style={{
                    textAlign: "left",
                    // paddingTop: "170px",
                  }}
                >
                  <Typography variant="h3" color="initial">
                    List Empty
                  </Typography>
                  <br />

                  <Typography variant="body1" color="initial">
                    Follow some channels and your latest videos will appear here
                  </Typography>
                  <Box pt={3}>
                    <Button
                      variant="contained"
                      onClick={() => history.goBack()}
                      color="primary"
                      disableElevation
                    >
                      Go Back
                    </Button>
                  </Box>
                </Box>
              </div>
            )} */}

            <Box>
              {isAnonymous && (
                <div>
                  <Box
                    style={{
                      textAlign: "left",
                      paddingTop: "170px",
                      padding: "10%",
                    }}
                  >
                    <Typography variant="h3" color="initial">
                      Signin required
                    </Typography>

                    <Typography variant="body1" color="initial">
                      Please signin to view this page
                    </Typography>
                    <Box pt={3}>
                      <Link to={"/login"}>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                        >
                          Login
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </div>
              )}
              {noVideos && (
                <div>
                  <Box
                    style={{
                      textAlign: "left",
                      paddingTop: "170px",
                      padding: "10%",
                    }}
                  >
                    <Typography variant="h3" color="initial">
                      No channels subscribed
                    </Typography>

                    <Typography variant="body1" color="initial">
                      Your following videos will appear here
                    </Typography>
                    <Box pt={3}>
                      <Link to={"/"}>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                        >
                          Go Home
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </div>
              )}
              <Footer/>
              {loading && (
                <>
                  <VideoListSkelton />
                  <VideoListSkelton />
                  <VideoListSkelton />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
