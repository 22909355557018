import React, { useContext, useState, useEffect, useCallback } from "react";
import { UserContext } from "../base/UserContext";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { storage, firestore } from "../base/firebaseConfig";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import { useDropzone } from "react-dropzone";
import { CloudUpload, MovieFilter, Publish } from "@material-ui/icons";
import { Box, Typography, Button } from "@material-ui/core";
import { Prompt } from "react-router-dom";
import VideoForm from "../components/channel/VideoForm";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Footer from "../components/shared/Footer";
import Declaration from "../components/shared/Declaration";

var format = require("format-duration");
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function UploadVideo(props) {
  let user = useContext(UserContext);
  let history = useHistory();
  const matches = useMediaQuery("(min-width:850px)");
  const classes = useStyles();

  const [localBlob, setVideoSrc] = useState("");
  const [message, setMessage] = useState("");
  const [videoSrc, setFile] = useState("");
  const [progress, setProgress] = useState(0);
  const [upload, uploadStatus] = useState("");
  const [thumbnail, setThumbNail] = useState("");
  const [videoDone, setVideoDone] = useState(false);
  const [thumbDone, setThumbDone] = useState(false);
  const [status, setStatus] = useState(500);
  const [duration, setduration] = useState(0);
  const [videoId, setvideoId] = useState("");
  const [channelDoc, setchannelDoc] = useState(null);
  const [exsist, setExist] = useState(true);
  const ffmpeg = createFFmpeg({
    log: true,
  });
  ffmpeg.setProgress(({ ratio }) => {
    if (ratio >= 0 && ratio <= 1) {
      setProgress(ratio * 100);
    }
    if (ratio === 1) {
      setTimeout(() => {
        setProgress(0);
      }, 1000);
    }
  });

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleEndConcert);
    let videoRef = firestore.collection("videos").doc();

    setvideoId(videoRef.id);
    if (props.userDoc?.uid) {
      let docRef = firestore.collection("channels").doc(props.userDoc.uid);
      docRef
        .get()
        .then((videoData) => {
          if (videoData.exists) {
            setchannelDoc(videoData.data());
          } else {
            setExist(false);
          }
        })
        .catch(() => {
          setExist(false);
        });
    }

    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleEndConcert);
      handleEndConcert();
    };
  }, [props.userDoc]);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  const handleEndConcert = () => {};
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileChange(acceptedFiles);
    },
    [props]
  );

  const { getRootProps, getInputProps, fileRejections, isDragActive } =
    useDropzone({
      onDrop,
      accept: "video/*",
      maxFiles: 1,
      maxFilesize: 10,
    });

  async function getDuration(file) {
   
    try {
      const url = URL.createObjectURL(file);
      return new Promise((resolve) => {
        var video = document.getElementsByTagName("video")[0];
        video.src = url; //--> blob URL
        video.preload = "metadata";
        video.onloadedmetadata = function () {
          resolve(video.duration);
        };
      });
    } catch (err) {
      console.log(err);
    }
  }
  const onFileChange = async (input) => {
    let uid = props.userDoc.uid || "public";
    const file = input[0];
    var video = document.getElementsByTagName("video")[0];
    const myduration = await getDuration(file);
    // alert(duration / 60 );

    const size = Math.round(file.size / (1024 * 1024));
    // The size of the file.

    if (myduration > 60 && myduration < 470) {
      setMessage("Starting...");
      // setLocalFileName(file.name);
      // document.getElementById('uploader').addEventListener('change', transcode);
      setMessage("Starting Upload");

      setStatus(1);
      setMessage("Processing Done");
      setStatus(3);
      uploadStatus("Starting Upload");

      let d = new Date();
      let time = d.getTime();
      let assetId = videoId || "upload-" + time;
      // alert(assetId);
      let ref = storage.child(
        `videos/` + assetId + "-" + file.name
      );
      var vidmetadata = {
        contentType: "video/mp4",
      };

      let uploadTask = ref.put(file, vidmetadata);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setStatus(6);
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadStatus(`Uploading Video ${Math.round(progress) + "%"}`);
          switch (snapshot.state) {
            case "paused": // or 'paused'
              uploadStatus("Upload is paused");
              break;
            case "running": // or 'running'
              break;
          }
        },
        (error) => {
          uploadStatus("Upload Error");
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setVideoDone(true);
            uploadStatus("Video Upload Done");
            setFile(downloadURL);
            video.addEventListener("loadedmetadata", () => {
              setduration(video.duration);
            });
          });
        }
      );

      setMessage("Genrating Thumbnail");

      try {
        await ffmpeg.load();
        setStatus(4);
        // ffmpeg.FS("writeFile", "video.webm", data);

        ffmpeg.FS("writeFile", "video.mp4", await fetchFile(input[0]));
        await ffmpeg.run(
          "-i",
          // "video.webm",
          "video.mp4",
          "-vframes",
          "2",
          "-an",
          // "-s",
          // "640x360",
          "-ss",
          "5",
          "OutputFile.jpeg"
        );
        // -i InputFile.FLV -vframes 1 -an -s 400x222 -ss 30 OutputFile.jpg
        const thumb = ffmpeg.FS("readFile", "OutputFile.jpeg");
        if (!thumb) {
          alert("Error Generating Thumbnail");
        }
        // let generatedThumb = URL.createObjectURL(
        //   new Blob([thumb.buffer], { type: "image/jpeg" })
        // );

        // setThumbNail(generatedThumb);

        setMessage("Local Thumbanil Generated");
        setStatus(5);
        let thumbref = storage.child(
            `video-thumb/${assetId}.jpg`
        );
        var imgmetadata = {
          contentType: "image/jpeg",
        };
        let thumbuploadTask = thumbref.put(thumb, imgmetadata);
        thumbuploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            uploadStatus("Uploading " + Math.round(progress) + "%");
            switch (snapshot.state) {
              case "paused": // or 'paused'
                uploadStatus("Upload is paused");
                break;
              case "running": // or 'running'
                break;
            }
          },
          (error) => {
            uploadStatus("Upload Error");
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            thumbuploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                setMessage("");
                setStatus(7);
                setThumbDone(true);
                uploadStatus("Upload Done");
                setThumbNail(downloadURL);
              });
          }
        );
      } catch (error) {
        alert(
          "Unable to generate a thumbnail. Please upload a Thumbnail maually "
        );
        setThumbNail("https://i.ytimg.com/vi/epL51ythcJg/maxresdefault.jpg");
        setThumbDone(true);
      }

      // console.log(data);
      // setVideoSrc(
      //   URL.createObjectURL(new Blob([data.buffer], { type: "video/webm" }))
      // );
    } else {
      const err = `ERROR!!!!! \n \nCurrent video duration is ${(
        myduration / 60
      ).toFixed(2)} minutes. Video must be between 1:00 and 7:00 minutes`;
      setMessage(err);
      alert(err);
    }
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });

  function nameLengthValidator(file) {
    if (file.size > 10) {
      return {
        code: "name-too-large",
        message: `Name is larger than ${10} characters`,
      };
    }

    return null;
  }
  return (
    <div className="App">
      <Prompt
        when={status > 1}
        message={() => "Are you sure you want to leave this page?"}
      />
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title> {`Upload | Millions`}</title>
          <link
            rel="canonical"
            href={`https://millionsofficial.in/channel/upload`}
          />
        </Helmet>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            {channelDoc?.accountStatus !== "blocked" && (
              <div style={{ padding: "10px" }}>
                <Typography variant="h5" color="initial" gutterBottom>
                  Create a New Video
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {fileRejectionItems}
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    {props.userDoc && videoId ? (
                      <div>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />

                          <div>
                            <Grid
                              className="upload-zone"
                              container
                              spacing={0}
                              align="center"
                              justify="center"
                              direction="column"
                              style={{ color: "#000" }}
                            >
                              <Grid item>
                                <CloudUpload color="primary" fontSize="large" />
                                <span>
                                  {!isDragActive
                                    ? "Drag and drop of click to select"
                                    : "Drop Here"}
                                </span>
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                >
                                  Your videos will be public after you publish
                                  them
                                  {fileRejectionItems}
                                </Typography>
                              </Grid>
                            </Grid>
                            <div
                              className="upload-status"
                              style={{ color: "#000" }}
                            >
                              <div style={{ width: "100%" }}>
                                <Box display="flex" flexDirection="row" p={1}>
                                  <Box p={1}>
                                    <MovieFilter style={{ color: "#000" }} />
                                  </Box>
                                  <Box p={1.2}>
                                    {message + " "}
                                    {progress > 0 && Math.round(progress) + "%"}
                                  </Box>
                                  <Box p={1}>
                                    <Publish style={{ color: "#000" }} />
                                  </Box>
                                  <Box p={1.2}>{upload}</Box>
                                </Box>
                              </div>
                            </div>
                          </div>

                          <Declaration
                            scope={"uploading a video"}
                            margin={10}
                          />

                          {/* <img src={thumbnail} width="100%" /> */}
                        </div>
                      </div>
                    ) : (
                      "Loading"
                    )}
                    <br />
                    <div>
                      {props.userDoc ? (
                        <VideoForm
                          id={videoId}
                          userDoc={props.userDoc}
                          videoDone={videoDone}
                          videoSrc={videoSrc}
                          thumbDone={thumbDone}
                          thumbnail={thumbnail}
                          setStatus={setStatus}
                          duration={duration}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <video src={videoSrc} width="95%" controls></video>
                    <Footer />
                  </Grid>
                </Grid>
              </div>
            )}
            {channelDoc?.accountStatus === "blocked" && (
              <div>
                <Box
                  style={{
                    textAlign: "left",
                    paddingTop: "170px",
                    padding: "10%",
                  }}
                >
                  <Typography variant="h3" color="initial">
                    Channel Blocked
                  </Typography>
                  <br />
                  <Typography variant="body1" color="initial">
                    This channel is blocked due to the violation of guidelines.
                    Kindly reached us if you think this is a mistake.
                  </Typography>
                  <Box pt={3}>
                    <Button
                      variant="contained"
                      onClick={() => history.goBack()}
                      color="primary"
                      disableElevation
                    >
                      Go Back
                    </Button>
                  </Box>
                </Box>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
