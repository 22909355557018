import React from 'react'
import Typography from '@material-ui/core/Typography'
const greetingTime = require("greeting-time");

export default function Greeting(props) {
    let greeting = greetingTime(new Date())
    return (
       <Typography variant="h5" color="initial" gutterBottom>
           {`${greeting} ${props.name || "Anonymous"}`}
       </Typography>
    )
}
