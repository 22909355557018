import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import BgImage from "../components/home/BgImage";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { fire, auth } from "../base/firebaseConfig";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import SaveIcon from "@material-ui/icons/Save";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppLogo from "../components/shared/svgs/millions-text.svg";
import Footer from "../components/shared/Footer";
import Declaration from "../components/shared/Declaration";
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
let qs = require("qs");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(0),
  },
}));

export default function LandingPage(props) {
  const [signStarted, setStarted] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  // Listen to the Firebase Auth state and set the local state

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    signInOptions: [
      {
        provider: fire.auth.GoogleAuthProvider.PROVIDER_ID,
        // scopes: [
        //   'https://www.googleapis.com/auth/contacts.readonly'
        // ],
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: 'select_account'
        }
      },
      // {
      //   provider: fire.auth.PhoneAuthProvider.PROVIDER_ID,
      // //   recaptchaParameters: {
      // //     type: 'image', // 'audio'
      // //     size: 'normal', // 'invisible' or 'compact'
      // //     badge: 'bottomleft' //' bottomright' or 'inline' applies to invisible.
      // //   },
      //   defaultCountry: 'IN', // Set default country to the United Kingdom (+44).
      //   // For prefilling the national number, set defaultNationNumber.
      //   // This will only be observed if only phone Auth provider is used since
      //   // for multiple providers, the NASCAR screen will always render first
      //   // with a 'sign in with phone number' button.
      // //   defaultNationalNumber: '1234567890',
      //   // You can also pass the full phone number string instead of the
      //   // 'defaultCountry' and 'defaultNationalNumber'. However, in this case,
      //   // the first country ID that matches the country code will be used to
      //   // populate the country selector. So for countries that share the same
      //   // country code, the selected country may not be the expected one.
      //   // In that case, pass the 'defaultCountry' instead to ensure the exact
      //   // country is selected. The 'defaultCountry' and 'defaultNationaNumber'
      //   // will always have higher priority than 'loginHint' which will be ignored
      //   // in their favor. In this case, the default country will be 'GB' even
      //   // though 'loginHint' specified the country code as '+1'.
      // //   loginHint: '+11234567890',
      //   // You can provide a 'whitelistedCountries' or 'blacklistedCountries' for
      //   // countries to select. It takes an array of either ISO (alpha-2) or
      //   // E164 (prefix with '+') formatted country codes. If 'defaultCountry' is
      //   // not whitelisted or is blacklisted, the default country will be set to
      //   // the first country available (alphabetical order). Notice that
      //   // 'whitelistedCountries' and 'blacklistedCountries' cannot be specified
      //   // at the same time.
      // //   whitelistedCountries: ['US', '+44']
      // },
    ],
    signInSuccessUrl: "/edit-profile",
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => {
        let location = qs.parse(props.location.search, {
          ignoreQueryPrefix: true,
        }).redirect;
        window.location.href = "/edit-profile?redirect=" + location;
      },
    },
  };

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={0} sm={5}>
         {matches && <BgImage />}
        </Grid>

        <Grid item xs={12} sm={7}>
          <>
            {!signStarted && (
              <div className="landing-start">
                <img src={AppLogo} width="20%" />
                <div className="landing-hero-text">
                  <h1 className="hero">
                    Watch, Stream, Earn, Anywhere, Anytime
                  </h1>
                  <Button
                    onClick={() => setStarted(true)}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    disableElevation
                  >
                    SignIn
                  </Button>
                  <Footer scope="home"/>
                </div>
              </div>
            )}
            <div className="sign-in" style={{minHeight:"80vh"}}>
              {signStarted && (
                <>
                  <img src={AppLogo} width="20%" />
                <Typography variant="h5" color="initial" gutterBottom >
                Log in to your account
                </Typography>
                <Typography variant="subtitle2" color="initial" gutterBottom>
                 Create an account to join the community
                </Typography>
                 <Box mt={10}>
                 <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                 </Box>
                  <Declaration/>
                </>
              )}
            </div>
           
          </>
        </Grid>
      </Grid>
    </div>
  );
}
