import { Box, Typography } from "@material-ui/core";
import React,{useEffect,useState} from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import OneComment from "./OneComment";


export default function Comments(props) {
    

  return (
    <div style={{paddingTop:20}}>
     
      {props.comments&& props.comments.map((comment, index) => (<OneComment
        key={comment.uniqueId}
        userDoc={props.userDoc}
        videoDoc={props.videoDoc}
        id={props.id}
        source={props.source}
        comment={comment}
        isReplyEnabled={true}
      />))}

    </div>
  );
}
