import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PlayStore from "./svgs/PlayStore";
import AppStore from "./svgs/AppStore";
import { Link } from "react-router-dom";

export default function Footer(props) {
  let bull = " • ";
  let d = new Date();
  let year = d.getFullYear();
  return (
    <Box mt={3} mb={5} style={{ color: "gray" }}>
     { props.scope !== "home" &&<Box>
      <Link style={{ color: "gray" }} to="/contact-us">
        {" "}
        <Typography variant="caption" color="initial">
          Contact us
        </Typography>{" "}
        {bull}
      </Link>
      <a style={{ color: "gray" }} href="https://docs.millionsofficial.in/docs/intro" target="_blank">
        {" "}
        <Typography variant="caption" color="initial">
          Help
        </Typography>{" "}
        {bull}
      </a>
      <a style={{ color: "gray" }} href="https://docs.millionsofficial.in/docs/bugs/report-bugs" target="_blank">
        {" "}
        <Typography variant="caption" color="initial">
          Bugs
        </Typography>{" "}
        {bull}
      </a>
      {/* <a style={{ color: "gray" }} href="#" target="_blank">
        {" "}
        <Typography variant="caption" color="initial">
          Feedback
        </Typography>{" "}
        {bull}
      </a> */}
      <a style={{ color: "gray" }} href="https://docs.millionsofficial.in/docs/privacy/privacy-policy" target="_blank">
        {" "}
        <Typography variant="caption" color="initial">
          Privacy
        </Typography>{" "}
        {bull}
      </a>
      <a style={{ color: "gray" }} href="https://docs.millionsofficial.in/docs/privacy/terms" target="_blank">
        {" "}
        <Typography variant="caption" color="initial">
          Terms
        </Typography>{" "}
        {bull}
      </a>
      <a style={{ color: "gray" }} href="https://docs.millionsofficial.in/docs/bugs/support" target="_blank">
        {" "}
        <Typography variant="caption" color="initial">
          Support
        </Typography>{" "}
      </a>

      <br />
      <Typography style={{ color: "gray" }} variant="caption" color="initial">
        {`  © ${year} MILLIONS MEDIA`}
      </Typography>
      </Box>}
     <Box pt={2}>
     <a style={{ color: "gray", padding: 5 }} href="https://play.google.com/store/apps/details?id=com.millionsmedia.millions" target="_blank">
        <PlayStore />
      </a>
      {/* <a style={{ color: "gray", padding: 5 }} href="#" target="_blank">
        <AppStore />
      </a> */}
     </Box>
    
    </Box>
  );
}
