import React, { Component } from "react";
import {
  Player,
  ControlBar,
  BigPlayButton,
  ReplayControl,
  ForwardControl,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from "video-react";
import Button from "@material-ui/core/Button";
import "video-react/dist/video-react.css";
import VideoAnalytics from "./VideoAnalytics";
import HLSSource from "./HLSSource";

require("videojs-hls-quality-selector");

const sources = {
  sintelTrailer: "http://media.w3.org/2010/05/sintel/trailer.mp4",
  bunnyTrailer: "http://media.w3.org/2010/05/bunny/trailer.mp4",
  bunnyMovie: "http://media.w3.org/2010/05/bunny/movie.mp4",
  test: "http://media.w3.org/2010/05/video/movie_300.webm",
};

export default class PlayerControlExample extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      source: props.src,
    };

    this.newShortcuts = [
      // Press number 1 to jump to the postion of 10%
      {
        keyCode: 49, // Number 1
        // handle is the function to control the player
        // player: the player's state
        // actions: the player's actions
        handle: (player, actions) => {
          const duration = player.duration;
          // jump to the postion of 10%
          actions.seek(duration * 0.1);
        },
      },
      {
        keyCode: 38, // Up arrow
        handle: () => {}, // override it's default handle
      },
      // Ctrl/Cmd + Right arrow to go forward 30 seconds
      {
        keyCode: 39, // Right arrow
        ctrl: true, // Ctrl/Cmd
        handle: (player, actions) => {
          if (!player.hasStarted) {
            return;
          }

          // this operation param is option
          // helps to display a bezel
          const operation = {
            action: "forward-30",
            source: "shortcut",
          };
          actions.forward(30, operation); // Go forward 30 seconds
        },
      },
    ];

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.load = this.load.bind(this);
    this.changeCurrentTime = this.changeCurrentTime.bind(this);
    this.seek = this.seek.bind(this);
    this.changePlaybackRateRate = this.changePlaybackRateRate.bind(this);
    this.changeVolume = this.changeVolume.bind(this);
    this.setMuted = this.setMuted.bind(this);
    this.fixVolume = this.fixVolume.bind(this);
  }

  componentDidMount() {
    // subscribe state change
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    //   this.player.hlsQualitySelector({
    //     displayCurrentQuality: true,
    // });
    if (this.props.videoDoc) {
      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: this.props.videoDoc?.title,
          artist: this.props.videoDoc?.channelName,
          // album: 'Originals',
          artwork: [
            {
              src: this.props.videoDoc?.thumbnail,
              sizes: "96x96",
              type: "image/png",
            },
            {
              src: this.props.videoDoc?.thumbnail,
              sizes: "128x128",
              type: "image/png",
            },
            {
              src: this.props.videoDoc?.thumbnail,
              sizes: "192x192",
              type: "image/png",
            },
            {
              src: this.props.videoDoc?.thumbnail,
              sizes: "256x256",
              type: "image/png",
            },
            {
              src: this.props.videoDoc?.thumbnail,
              sizes: "384x384",
              type: "image/png",
            },
            {
              src: this.props.videoDoc?.thumbnail,
              sizes: "512x512",
              type: "image/png",
            },
          ],
        });

        navigator.mediaSession.setActionHandler(
          "seekbackward",
          this.changeCurrentTime(-5)
        );
        navigator.mediaSession.setActionHandler(
          "seekforward",
          this.changeCurrentTime(10)
        );
        // navigator.mediaSession.setActionHandler('previoustrack', this._handlePreviouss)
        // navigator.mediaSession.setActionHandler('nexttrack', this._handleNext)
      }
    }
  }

  setMuted(muted) {
    return () => {
      this.player.muted = muted;
    };
  }

  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state,
    });

    localStorage.setItem("muted", state.muted);
  }

  play() {
    this.player.play();
  }
  pause() {
    this.player.pause();
  }

  load() {
    this.player.load();
  }

  changeCurrentTime(seconds) {
    return () => {
      const { player } = this.player.getState();
      this.player.seek(player.currentTime + seconds);
    };
  }

  seek(seconds) {
    return () => {
      this.player.seek(seconds);
    };
  }

  changePlaybackRateRate(steps) {
    return () => {
      const { player } = this.player.getState();
      this.player.playbackRate = player.playbackRate + steps;
    };
  }

  changeVolume(steps) {
    return () => {
      const { player } = this.player.getState();
      this.player.volume = player.volume + steps;
    };
  }

  changeSource(url) {
    return () => {
      this.setState({
        source: url,
      });
      this.player.load();
    };
  }

  fixVolume() {
    localStorage.setItem("volume", this.player.volume);
  }
  render() {
    return (
      <div>
        <Player
          poster={this.props.poster}
          ref={(player) => {
            this.player = player;
          }}
          aspectRatio={"16:9"}
          shortcuts={this.newShortcuts}
          muted={JSON.parse(localStorage.getItem("muted")) || false}
          autoPlay={true}
          startTime={0}
        >
          <BigPlayButton position="center" />
          {/* <source src={this.state.source} /> */}
          <HLSSource
            isVideoChild
            src="https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8"
          />
          <ControlBar autoHide={true}>
            <ReplayControl seconds={5} order={2.1} />
            <ForwardControl seconds={10} order={3.1} />
            <VolumeMenuButton vertical />
            <PlaybackRateMenuButton
              rates={[3, 2, 1.5, 1, 0.5, 0.25]}
              order={7}
            />
          </ControlBar>
        </Player>
        {this.state.player && (
          <VideoAnalytics
            player={this.state.player}
            userDoc={this.props.userDoc}
            id={this.props.id}
            channelDoc={this.props.channelDoc}
          />
        )}
        {/* <pre>{console.log(JSON.stringify(this.state.player, null, 2))}</pre> */}
      </div>
    );
  }
}
