import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./player.css";
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";
import 'videojs-hotkeys'
import VideoAnalytics from "./VideoAnalytics";
import("@silaas/videojs-preroll/dist/videojs-preroll.css");
require("videojs-hls-quality-selector");
require("videojs-settings-menu");
require("videojs-seek-buttons");

require("@silaas/videojs-preroll");
export default function VideoJSPlayer(props) {
  const { videoSrc } = props;
  const playerRef = useRef();
  const [currentTime, setcurrentTime] = useState(0);
  const [duration, setduration] = useState(10000);
  const [hasStarted, sethasStarted] = useState(false);
  const [paused, setpaused] = useState(false);
  const [ended, setended] = useState(false);

  const options = {
    muted: true,
    autoplay: true,
    aspectRatio: "16:9",
    language: "en",
    preload: "auto",
    // textTrackSettings: true,
    // fluid: true,
    html5: {
      hls: {
        overrideNative: true,
        limitRenditionByPlayerDimensions: true,
        useDevicePixelRatio: true,
        // bandwidth: 16777216,
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      useBandwidthFromLocalStorage: true,
    },
    playbackRates: [0.5, 1, 1.5, 2],
    controlBar: {
      allowfullscreen: true,
      SubtitlesButton: true,
    },
  };
  useEffect(() => {
    const player = videojs(playerRef.current, options, () => {
      player.src(props.src);
      player.poster(props.poster);
      player.hlsQualitySelector({
        displayCurrentQuality: false,
      });
      player.seekButtons({
        forward: 30,
        back: 10,
      });
      player.ready(() => {
        player.muted(JSON.parse(localStorage.getItem("muted")) || false);
        player.hotkeys({
          volumeStep: 0.1,
          seekStep: 5,
          enableModifiersForNumbers: false
        });
      });
      player.on("ended", () => {
        setended(true);
      });
      player.on("timeupdate", () => {
        sethasStarted(player.hasStarted());
        setcurrentTime(player.currentTime());
        setduration(player.duration());
        setpaused(player.paused());
        player.muted()
          ? localStorage.setItem("muted", JSON.stringify(player.muted()))
          : localStorage.removeItem("muted");
      });
    });
    if (props.videoDoc) {
      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: props.videoDoc?.title,
          artist: props.videoDoc?.channelName,
          // album: 'Originals',
          artwork: [
            {
              src: props.videoDoc?.thumbnail,
              sizes: "96x96",
              type: "image/png",
            },
            {
              src: props.videoDoc?.thumbnail,
              sizes: "128x128",
              type: "image/png",
            },
            {
              src: props.videoDoc?.thumbnail,
              sizes: "192x192",
              type: "image/png",
            },
            {
              src: props.videoDoc?.thumbnail,
              sizes: "256x256",
              type: "image/png",
            },
            {
              src: props.videoDoc?.thumbnail,
              sizes: "384x384",
              type: "image/png",
            },
            {
              src: props.videoDoc?.thumbnail,
              sizes: "512x512",
              type: "image/png",
            },
          ],
        });

        // navigator.mediaSession.setActionHandler(
        //   "seekbackward",
        //   changeCurrentTime(-5)
        // );
        // navigator.mediaSession.setActionHandler(
        //   "seekforward",
        //   changeCurrentTime(10)
        // );
        // navigator.mediaSession.setActionHandler('previoustrack', _handlePreviouss)
        // navigator.mediaSession.setActionHandler('nexttrack', _handleNext)
      }
    }
    return () => {
      player.dispose();
    };
  }, []);

  return (
    <>
      <div data-vjs-player>
        <video
          id="player"
          crossorigin="anonymous"
          class="video-js vjs-lime"
          controls
          ref={playerRef}
          className="video-js vjs-16-9"
        />
        {/* <track
          kind="captions"
          src="https://video-react.js.org/assets/elephantsdream/captions.en.vtt"
          type="text/plain"
          srclang="en"
          label="English"
        ></track> */}
      </div>
      <VideoAnalytics
        player={{
          currentTime: currentTime,
          duration: duration,
          hasStarted: hasStarted,
          paused: paused,
          ended: ended,
        }}
        userDoc={props.userDoc}
        id={props.id}
        channelDoc={props.channelDoc}
      />
      {/* {duration}
      <br/>
      {currentTime}
      <br/>
      {hasStarted?"Started":"Not started"}
      <br/>
      {paused ?"Paused":"Playing"}
      <br/>
      {ended ?"Ended":"Not Ended"} */}
    </>
  );
}
