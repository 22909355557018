import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { firestore } from "../../base/firebaseConfig";
import Moment from "moment";
import TopChannelList from "./TopChannelList";
import { makeStyles } from "@material-ui/core/styles";
import NewlineText from "../utils/NewlineText";
import ClampLines from "react-clamp-lines";
import Skeleton from "@material-ui/lab/Skeleton";
import VideoListSkelton from "./VideoListSkelton";
import VideoCard from "../video/VideoCard";
import { languages } from "../../components/constants";
import Greeting from "./Greeting";
import Button from "@material-ui/core/Button";
import { useInViewport } from "react-in-viewport";
import useOnScreen from "../shared/UseOnScreen";
import PostAds from "../shared/PostAds";
import Footer from "../shared/Footer";
import { Alert, AlertTitle } from "@material-ui/lab";
import LoginPrompt from "../shared/LoginPrompt";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function VideoList(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  let [isUser, setIsUser] = useState("Loading");
  let [latest, setLatest] = useState([]);
  let [loading, setloading] = useState(true);
  let [start, setstart] = useState(0);
  const [ended, setended] = useState(false);

  let videoRef = firestore.collection("videos");
  const getLatest = async () => {
    await videoRef
      .where("isVisible", "==", "Public")
      .where(
        "language",
        "in",
        props.userDoc !== "Anonymous"
          ? props.userDoc.language || languages.slice(0, 9)
          : languages.slice(0, 9)
      )
      .orderBy("videoScore", "desc")
      .limit(20)
      .get()
      .then((querySnapshot) => {
        let mylatest = [];
        let start = querySnapshot.docs[querySnapshot.docs.length - 1];
        setstart(start);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          mylatest.push(doc.data());
        });
        setLatest(mylatest);
        setloading(false);
      })
      .catch((error) => {
        // console.log("Error getting documents: ", error);
      });
  };

  const getMoreVideos = async (start, list) => {
    (await start) &&
      videoRef
        .where("isVisible", "==", "Public")
        .where(
          "language",
          "in",
          props.userDoc !== "Anonymous"
            ? props.userDoc.language
            : languages.slice(0, 9)
        )
        .orderBy("videoScore", "desc")
        .startAfter(start)
        .limit(12)
        .get()
        .then((querySnapshot) => {
          let mylatest = [...list];
          let end = start;
          let nextStart = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(nextStart);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
          });
          setLatest(mylatest);
          setloading(false);
        })
        .catch((error) => {
          // console.log("Error getting documents: ", error);
          alert("No More Videos");
        });

    if (!start) {
      setended(true);
    }
  };

  useEffect(() => {
    getLatest();
    setloading(true);
  }, [props.userDoc]);

  return (
    <div>
      {latest && (
        <Box style={{ marginBottom: 50 }}>
          <Greeting name={props.userDoc?.name} />

          <Grid xs={12} sm={3}></Grid>
          {true && (
            <Grid item xs={12}>
              <div style={{ padding: "10px" }}>
                {!props.userDoc?.uid && <LoginPrompt
                  redirect="/"
                  severity="info"
                  title={"Never miss an update!"}
                  text=" Create an account or login to join our community of creators and users"
                />}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <Grid container spacing={3}>
                      {latest?.map((item, index) => (
                        <Grid key={index} item xs={12} md={3} sm={6}>
                          <VideoCard item={item} />
                        </Grid>
                      ))}
                      <Box
                        display="flex"
                        justifyContent="center"
                        style={{ width: "100%" }}
                        m={1}
                        p={1}
                        bgcolor="background.paper"
                      >
                        {latest && !loading && (
                          <Button
                            onClick={() => getMoreVideos(start, latest)}
                            variant="outlined"
                            color="default"
                          >
                            Load more
                          </Button>
                        )}

                        <br />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="center"
                        style={{ width: "100%" }}
                        m={1}
                        p={1}
                        bgcolor="background.paper"
                      >
                        {ended && (
                          <Typography variant="subtitle2" color="initial">
                            You reached at the end of the list
                          </Typography>
                        )}
                      </Box>

                      {loading && (
                        <>
                          <VideoListSkelton />
                          <VideoListSkelton />
                          <VideoListSkelton />
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <PostAds userDoc={props.userDoc} />
                    <TopChannelList />
                    <Footer />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}
        </Box>
      )}
    </div>
  );
}
