import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../base/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideBar from "../components/shared/SideBar";
import NavBar from "../components/shared/NavBar";
import { firestore } from "../base/firebaseConfig";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Moment from "moment";
import ClampLines from "react-clamp-lines";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ReactLinkify from "react-linkify";
import { Box } from "@material-ui/core";
import { Helmet } from "react-helmet";
import TopChannelList from "../components/home/TopChannelList";
import VideoActions from "../components/video/VideoActions";
import LazyLoad from "react-lazy-load";
import Footer from "../components/shared/Footer";
import PostCard from "../components/posts/PostCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // margin: 35,
  },
  bg: {
    padding: theme.spacing(1),
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  paper: {
    padding: theme.spacing(1),
    margin: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function FindPosts(props) {
  const matches = useMediaQuery("(min-width:850px)");
  let user = useContext(UserContext);
  const classes = useStyles();
  const [id, setId] = useState(null);
  const [videoDoc, setVideoDoc] = useState(null);
  const [exsist, setExist] = useState(true);
  let [isUser, setIsUser] = useState("Loading");
  let [latest, setLatest] = useState([]);
  let [start, setstart] = useState(0);
  const [ended, setended] = useState(false);

  //TODO loading skelton
  let postsRef = firestore.collection("posts");
  const getLatest = async () => {
    await postsRef
      .where("country", "==", props.userDoc?.country || "IN")
      .where("isVisible", "==", "Public")
      .orderBy("videoScore", "desc")
      .limit(4)
      .get()

      .then((querySnapshot) => {
        let mylatest = [];
        let start = querySnapshot.docs[querySnapshot.docs.length - 1];
        setstart(start);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          mylatest.push(doc.data());
        });
        setLatest(mylatest);
      })
      .catch((error) => {
      //console.log("Error getting documents: ", error);
      });
    // console.log(mylatest);
  };
  const getMorePosts = async (start, list) => {
    (await start) &&
      postsRef
        .where("country", "==", props.userDoc?.country || "IN")
        .where("isVisible", "==", "Public")
        .orderBy("videoScore", "desc")
        .startAfter(start)
        .limit(4)
        .get()
        .then((querySnapshot) => {
          let mylatest = [...list];
          let end = start;
          let nextStart = querySnapshot.docs[querySnapshot.docs.length - 1];
          setstart(nextStart);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());

            mylatest.push(doc.data());
          });
          setLatest(mylatest);
        })
        .catch((error) => {
        //console.log("Error getting documents: ", error);
          alert("No More Videos");
        });

    if (!start) {
      setended(true);
    }
  };
  useEffect(() => {
    getLatest();
  }, [props.userDoc]);
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Posts | Millions</title>
        <link rel="canonical" href={`https://millionsofficial.in/posts`} />
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <SideBar matches={matches} />
          </Grid>
          <Grid
            item
            xs={matches ? 11 : 12}
            style={{ marginTop: "100px", padding: "20px" }}
          >
            <div style={{ padding: "10px" }}>
              <Typography variant="h5" color="initial" gutterBottom gutterBottom>
                Explore Posts
              </Typography>
            
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  {latest && (
                    <Grid item xs={12}>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={9}>
                            <Grid container spacing={3}>
                              {latest.map((item, index) => (
                                <Grid key={index} item xs={12} sm={12} md={6}>
                                   <PostCard item={item} userDoc={props.userDoc} />
                                </Grid>
                              ))}
                              <Box
                                display="flex"
                                justifyContent="center"
                                style={{ width: "100%" }}
                                m={1}
                                p={1}
                                bgcolor="background.paper"
                              >
                                {latest && latest.length > 3 &&(
                                  <Button
                                    onClick={() => getMorePosts(start, latest)}
                                    variant="outlined"
                                    color="default"
                                  >
                                    Load more
                                  </Button>
                                )}

                                <br />
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="center"
                                style={{ width: "100%" }}
                                m={1}
                                p={1}
                                bgcolor="background.paper"
                              >
                                {ended && (
                                  <Typography
                                    variant="subtitle2"
                                    color="initial"
                                  >
                                    You reached at the end of the list
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <TopChannelList />
                          </Grid>
                        </Grid>
                        <Footer/>
                      </div>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}></Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
}
